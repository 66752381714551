// Import React, Supporting Libraries
import React from 'react';


// Import Css Module
import classes from './TodoFilters.module.css';


// Functional Component
const todoFilters = (props) => {


    function displayPriorityFilter()
    {
        let returnOutput = null;

        if (props.showPriorityFilter) {
            if (props.filters.filterViewAll === false) {
                returnOutput = (
                    <React.Fragment>
                        <h6 className="mt-4">Priority Filter</h6>
                        <div className="mail-list mt-1">
                            { getActivePriorityFilter() }
                        </div>
                    </React.Fragment>
                );
            }
        }

        return returnOutput;
    }


    function displayDueDateFilter()
    {
        let returnOutput = null;

        if (props.showDateFilter) {
            if (props.filters.filterViewAll === false) {
                returnOutput = (
                    <React.Fragment>
                        <h6 className="mt-4">Due Date Filter</h6>
                        <div className="mail-list mt-1">
                            { getActiveDueDateFilter() }
                        </div>
                    </React.Fragment>
                );
            }
        }

        return returnOutput;
    }


    function getActiveDueDateFilter()
    {
        let returnOutput = [];

        const filters = ['today::Today', 'upcoming::Upcoming', 'noDueDate::No Due Date'];
        for (let i = 0; i < filters.length; i++) {
            let filterContentArray = filters[i].split('::');

            let spanEle = null;
            if (props.filters.filterDate === filterContentArray[0]) {
                const classString = "mdi mdi-arrow-right-drop-circle float-right text-secondary";
                spanEle = (<span className={classString}></span>);
            }

            returnOutput.push(<p className={classes.filterListItem} onClick={dueDateFilterClickHandler.bind(this, filterContentArray[0])}>{spanEle}{ filterContentArray[1] }</p>);
        }

        return returnOutput;
    }


    function getActivePriorityFilter()
    {
        let returnOutput = [];

        const filters = ['Highest::text-danger', 'High::text-warning', 'Medium::text-primary', 'Low::text-secondary', 'Lowest::text-success'];
        for (let i = 0; i < filters.length; i++) {
            let filterContentArray = filters[i].split('::');

            let spanEle = null;
            if (props.filters.filterPriority === (i+1)) {
                const classString = "mdi mdi-arrow-right-drop-circle float-right " + filterContentArray[1];
                spanEle = (<span className={classString}></span>);
            }

            returnOutput.push(<p className={classes.filterListItem} onClick={filterPriorityClickHandler.bind(this, (i+1))} key={i}>{spanEle}{ filterContentArray[0] }</p>);
        }

        return returnOutput;
    }


    function filterPriorityClickHandler(inValue, event)
    {
        if (props.todoListFiltersStateName && props.todoListPaginationStateName) {
            props.filterPriorityClickHandler(props.todoListFiltersStateName, props.todoListPaginationStateName, inValue, event);
        } else {
            props.filterPriorityClickHandler(inValue, event);
        }
    }


    function dueDateFilterClickHandler(inValue, event)
    {
        if (props.todoListFiltersStateName && props.todoListPaginationStateName) {
            props.dueDateFilterClickHandler(props.todoListFiltersStateName, props.todoListPaginationStateName, inValue, event);
        } else {
            props.dueDateFilterClickHandler(inValue, event);
        }
    }


    function filterViewAllChangeHandler(event)
    {
        if (props.todoListFiltersStateName) {
            props.filterViewAllChangeHandler(props.todoListFiltersStateName, event);
        } else {
            props.filterViewAllChangeHandler(event);
        }
    }


    function showCompletedTodosChangeHandler(event)
    {
        if (props.todoListFiltersStateName) {
            props.showCompletedTodosChangeHandler(props.todoListFiltersStateName, event);
        } else {
            props.showCompletedTodosChangeHandler(event);
        }
    }


    return (
        <div>
            <h5>Filters</h5>
            <hr />
            {/*<button type="button" className="btn btn-danger btn-block waves-effect waves-light" data-toggle="modal" data-target="#composemodal">*/}
            {/*    Compose*/}
            {/*</button>*/}

            <br />
            {
                props.showCompletedTodosCheckbox ?
                    <div className="custom-control custom-switch mb-3" dir="ltr">
                        <input type="checkbox" className="custom-control-input" id="showCompletedTodos" checked={props.filters.showCompletedTodos} onChange={showCompletedTodosChangeHandler} />
                        <label className="custom-control-label" htmlFor="showCompletedTodos">Show Completed Todos</label>
                    </div>
                : null
            }

            {
                props.showViewAllCheckbox ?
                    <div className="custom-control custom-switch mb-3" dir="ltr">
                        <input type="checkbox" className="custom-control-input" id="noFilter" checked={props.filters.filterViewAll} onChange={filterViewAllChangeHandler} />
                        <label className="custom-control-label" htmlFor="noFilter">No Filter</label>
                    </div>
                : null
            }

            {
                displayDueDateFilter()
            }

            {
                displayPriorityFilter()
            }
        </div>
    );
};


// Export Components
export default todoFilters;