// Import React, Supporting Libraries
import React from 'react';
import { connect } from 'react-redux';


// Other Components
import ApiAxios from '../../../vendors/axios/api';
import * as utils from '../../../utils/utils';
import * as actionTypes from '../../../vendors/redux/state/actions';
import FormGroupInput from '../../../components/form/FormGroupInput/FormGroupInput';
import SubmitButton from '../../../components/form/SubmitButton/SubmitButton';


// Class Component
class CreateProject extends React.Component
{

    constructor(props)
    {
        super(props);

        this.state = {

            createProjectForm: {
                fields: {
                    title: {
                        elementType: 'textarea',
                        elementConfig: {
                            type: 'text',
                            placeholder: 'New project title',
                            required: true,
                            rows: 3,
                            minLength: 2,
                            maxLength: 300,
                        },
                        value: '',
                        label: 'Title',
                        customStyle: {
                            resize: 'none',
                        },
                        validation: {
                            required: true,
                            minLength: 2,
                            maxLength: 300,
                        },
                        invalidMessage: '',
                    },
                    description: {
                        elementType: 'textarea',
                        elementConfig: {
                            type: 'text',
                            placeholder: 'New project description',
                            required: false,
                            rows: 13,
                            minLength: 2,
                            maxLength: 1000,
                        },
                        value: '',
                        label: 'Description',
                        customStyle: {
                            resize: 'none',
                        },
                        validation: {
                            required: false,
                            minLength: 2,
                            maxLength: 1000,
                        },
                        invalidMessage: '',
                    },

                },

                formHasError: false,
                formSubmitting: false,

                bootstrapAlertContent: null,
            },
        };


        // Regular Functions
        this.validateFormHasErrorBeforeSubmit       =  this.validateFormHasErrorBeforeSubmit.bind(this);

        // Event Handlers
        this.inputValueChangeHandler                =  this.inputValueChangeHandler.bind(this);
        this.createProjectFormSubmitHandler         =  this.createProjectFormSubmitHandler.bind(this);
        this.bootstrapAlertCloseButtonClickHandler  =  this.bootstrapAlertCloseButtonClickHandler.bind(this);
    }


    componentDidMount()
    {
        const authToken = utils.validateIsAuthenticated(this.props);
        if (authToken != false) {
            ApiAxios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
        } else {
            utils.redirectToLogin(this.props);
        }
    }


    inputValueChangeHandler(formEleName, event)
    {
        this.setState({
            createProjectForm: {
                ...utils.inputValueChangedHelper(formEleName, event.target.value, this.state.createProjectForm)
            }
        });
    }


    validateFormHasErrorBeforeSubmit()
    {
        let formHasError = false;
        for (let eleName in this.state.createProjectForm.fields) {
            const element = this.state.createProjectForm.fields[eleName];

            const validationResponse = utils.inputValueChangedHelper(eleName, element.value, this.state.createProjectForm);
            this.setState({
                createProjectForm: {
                    ...validationResponse
                }
            });
            if (validationResponse.formHasError) {
                formHasError = true;
            }
        }

        return formHasError;
    }


    createProjectFormSubmitHandler(event)
    {
        event.preventDefault();

        this.setState({
            createProjectForm: {
                ...this.state.createProjectForm,
                formSubmitting: true,
                bootstrapAlertContent: null,
            }
        });

        let postFormData = {
            title: this.state.createProjectForm.fields.title.value,
        };
        if (this.state.createProjectForm.fields.description.value) {
            postFormData = {
                ...postFormData,
                description: this.state.createProjectForm.fields.description.value,
            };
        }

        ApiAxios.post('/secure/project/create', postFormData)
            .then((response) => {

                this.props.newProjectGotCreated();

                const updatedFormData = this.state.createProjectForm;
                updatedFormData.fields.title.value = '';
                updatedFormData.fields.description.value = '';
                this.setState({
                    createProjectForm: {
                        ...updatedFormData
                    },
                });

                var newModal = document.getElementById('createNewProjectModal');
                var closeArr = newModal.getElementsByClassName('close');
                closeArr[0].click();

            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        createProjectForm: {
                            ...this.state.createProjectForm,
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                        },
                    });
                } else {
                    this.setState({
                        createProjectForm: {
                            ...this.state.createProjectForm,
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                        },
                    });
                }
            })
            .then(() => {
                this.setState({
                    createProjectForm: {
                        ...this.state.createProjectForm,
                        formSubmitting: false,
                    },
                });
            });
    }


    bootstrapAlertCloseButtonClickHandler(event)
    {
        this.setState({
            createProjectForm: {
                ...this.state.createProjectForm,
                bootstrapAlertContent: null,
            },
            bootstrapAlertContent: null,
        });
    }


    render()
    {
        return (
            // Modal
            <div className="modal fade" data-show="true" id="createNewProjectModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <form onSubmit={this.createProjectFormSubmitHandler}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Create New Project</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                { this.state.createProjectForm.bootstrapAlertContent }

                                <div className="row">
                                    <div className="col-sm-12">
                                        <FormGroupInput
                                            name='title'
                                            elementType={this.state.createProjectForm.fields.title.elementType}
                                            customStyle={this.state.createProjectForm.fields.title.customStyle}
                                            value={this.state.createProjectForm.fields.title.value}
                                            label={this.state.createProjectForm.fields.title.label}
                                            invalidMessage={this.state.createProjectForm.fields.title.invalidMessage}
                                            elementConfig={this.state.createProjectForm.fields.title.elementConfig}
                                            changeHandler={this.inputValueChangeHandler.bind(this, 'title')}
                                        />
                                    </div>
                                    <div className="col-sm-12">
                                        <FormGroupInput
                                            name='description'
                                            elementType={this.state.createProjectForm.fields.description.elementType}
                                            customStyle={this.state.createProjectForm.fields.description.customStyle}
                                            value={this.state.createProjectForm.fields.description.value}
                                            label={this.state.createProjectForm.fields.description.label}
                                            invalidMessage={this.state.createProjectForm.fields.description.invalidMessage}
                                            elementConfig={this.state.createProjectForm.fields.description.elementConfig}
                                            changeHandler={this.inputValueChangeHandler.bind(this, 'description')}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="row justify-content-end" style={{width:'100%'}}>
                                    <div className="col-sm-2" style={{marginBottom:'1rem'}}>
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                    </div>
                                    <div className="col-sm-4">
                                        <SubmitButton
                                            label="Create Project"
                                            formHasError={this.state.formHasError}
                                            isSubmitting={this.state.formSubmitting} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}


// Redux State Management
const mapStateToProps = (curState) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        newProjectGotCreated: () => {
            return dispatch({type: actionTypes.NEW_PROJECT_GOT_CREATED})
        },
        addInterPageMessage: (message, messageType) => {
            return dispatch({type: actionTypes.INTER_PAGE_MESSAGE_ADDED, message: message, messageType: messageType});
        },
    };
};


// Export Component
export default connect(mapStateToProps, mapDispatchToProps)(CreateProject);