// Import React, Supporting Libraries
import React from 'react';
import { connect } from 'react-redux';


// Other Components
import ApiAxios from '../../../vendors/axios/api';
import * as actionTypes from '../../../vendors/redux/state/actions';


// Class Component
class Logout extends React.Component
{

    componentDidMount()
    {
        this.props.getAuthToken();
        if (this.props.token.access_token) {
            ApiAxios.defaults.headers.common['Authorization'] = 'Bearer ' + this.props.token.access_token;
        } else {
            const accessToken = localStorage.getItem('access_token');
            ApiAxios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
        }
        ApiAxios.post('/secure/my-profile/logout');

        this.props.clearAuthToken();

        this.props.addInterPageMessage('You have successfully logged out', 'success');

        this.props.history.push({
            pathname: '/',
            state: {},
        });
    }


    render()
    {
        return null;
    }
}


// Redux State Management
const mapStateToProps = (curState) => {
    return {
        token: curState.token,
        isAuthenticated: curState.isAuthenticated,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        getAuthToken: () => {
            return dispatch({type: actionTypes.AUTH_CHECK})
        },
        clearAuthToken: () => {
            return dispatch({type: actionTypes.LOGOUT})
        },
        addInterPageMessage: (message, messageType) => {
            return dispatch({type: actionTypes.INTER_PAGE_MESSAGE_ADDED, message: message, messageType: messageType});
        },
    };
};


// Export Component
export default connect(mapStateToProps, mapDispatchToProps)(Logout);