// Import React, Supporting Libraries
import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';


// Other Components
import CommonPageLayout from '../../../components/auth_only/CommonPageLayout/CommonPageLayout';
import ApiAxios from '../../../vendors/axios/api';
import * as utils from '../../../utils/utils';
import TodoFilters from '../../../components/auth_only/TodoFilters/TodoFilters';
import TodoList from "../../../components/auth_only/TodoList/TodoList";
import * as actionTypes from "../../../vendors/redux/state/actions";
import {Link} from "react-router-dom";
import AddTodo from "../../../containers/auth_only/AddTodo/AddTodo";
import MigrateProjectComponent from "../../../containers/auth_only/MigrateProject/MigrateProject";
import QuickEditTodoComponent from "../../../containers/auth_only/QuickEditTodo/QuickEditTodo";


// Class Component
class ActiveTodoList extends React.Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            activeTodoListBootstrapAlertContent: null,

            activeTodoList: {},

            activeTodoListPagination: {
                resultsPerPage: 12,
                totalCount: 0,
                noOfPages: 1,
                currentPage: 1,
            },

            activeTodoListFilters: {
                showCompletedTodos: false,
                filterDate: 'today', // upcoming,noDueDate
                filterPriority: 3, // 1,2,3,4,5
                filterViewAll: true,

                localSearchString: '',
                localSearchErrorString: '',
            },



            overdueTodoListBootstrapAlertContent: null,

            overdueTodoList: {},

            overdueTodoListFilters: {
                localSearchString: '',
                localSearchErrorString: '',
            },

            overdueTodoListPagination: {
                resultsPerPage: 192,
                totalCount: 0,
                noOfPages: 1,
                currentPage: 1,
            },

            projectDetails: {},
        };

        // Event Handlers
        this.populateEditModal                      =  this.populateEditModal.bind(this);
        this.showCompletedTodosChangeHandler        =  this.showCompletedTodosChangeHandler.bind(this);
        this.filterViewAllChangeHandler             =  this.filterViewAllChangeHandler.bind(this);
        this.dueDateFilterClickHandler              =  this.dueDateFilterClickHandler.bind(this);
        this.filterPriorityClickHandler             =  this.filterPriorityClickHandler.bind(this);
        this.paginatorClickHandler                  =  this.paginatorClickHandler.bind(this);
        this.resultsPerPageChangeHandler            =  this.resultsPerPageChangeHandler.bind(this);
        this.isCompletedCheckboxChangeHandler       =  this.isCompletedCheckboxChangeHandler.bind(this);
        this.bootstrapAlertCloseButtonClickHandler  =  this.bootstrapAlertCloseButtonClickHandler.bind(this);
        this.localSearchStringChangeHandler         =  this.localSearchStringChangeHandler.bind(this);
        this.localSearchFormSubmitHandler           =  this.localSearchFormSubmitHandler.bind(this);
        this.todoMigratedEventHandler               =  this.todoMigratedEventHandler.bind(this);
        this.todoQuickUpdatedEventHandler           =  this.todoQuickUpdatedEventHandler.bind(this);
    }


    componentDidMount()
    {
        const authToken = utils.validateIsAuthenticated(this.props);
        if (authToken != false) {
            ApiAxios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
        } else {
            utils.redirectToLogin(this.props);
        }

        this.getProjectDetails(this.props.match.params.projectId);

        this.getActiveTodoList();

        this.getOverdueTodoList();
    }


    componentDidUpdate(prevProps, prevState, snapshot)
    {
        // prevProps.newTodoGotAdded !== this.props.newTodoGotAdded
        if (this.props.newTodoGotAdded === true) {
            this.getActiveTodoList();

            this.getOverdueTodoList();

            this.props.processNewTodo();
        }

        if (this.props.projectGotUpdated === true) {
            this.getProjectDetails(this.props.match.params.projectId);

            this.props.processProjectChanges();
        }
    }


    populateEditModal()
    {
        this.props.populateUpdateProjectModal(this.props.match.params.projectId, this.state.projectDetails.p__title, this.state.projectDetails.p__description);
    }


    getProjectDetails(projectId)
    {
        let getParams = {
            project: projectId,
        };

        ApiAxios.get('/secure/project/details', {
            params: getParams
        })
            .then((response) => {
                this.setState({
                    projectDetails: response.data.data,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        activeTodoListBootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                    });
                } else {
                    this.setState({
                        activeTodoListBootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                    });
                }
            })
            .then(() => {

            });
    }


    getActiveTodoList(todoListFilters = null, todoListPagination = null)
    {
        if (!todoListFilters) {
            todoListFilters = this.state.activeTodoListFilters;
        }

        if (!todoListPagination) {
            todoListPagination = this.state.activeTodoListPagination;
        }

        this.setState({
            activeTodoListBootstrapAlertContent: null,
        });

        let getParams = {
            filterDate: todoListFilters.filterDate,
            filterPriority: todoListFilters.filterPriority,
        };
        if (todoListFilters.filterViewAll) {
            getParams = {};
        }

        if (todoListFilters.showCompletedTodos) {
            getParams = {
                ...getParams,
                filterIncludeCompleted: 1,
            };
        }

        getParams = {
            ...getParams,
            pageNo: todoListPagination.currentPage,
            resultPerPage: todoListPagination.resultsPerPage,
            project: this.props.match.params.projectId,
        }

        if (todoListFilters.localSearchString != '') {
            getParams = {
                ...getParams,
                titleSearch: todoListFilters.localSearchString,
            }
        }

        ApiAxios.get('/secure/project/todo/list', {
            params: getParams
        })
            .then((response) => {
                this.setState({
                    activeTodoList: response.data.data,
                    activeTodoListPagination: {
                        ...this.state.activeTodoListPagination,
                        totalCount: response.data.pagination.totalCount ? response.data.pagination.totalCount : 0,
                        noOfPages: response.data.pagination.noOfPages ? response.data.pagination.noOfPages : 1,
                    },
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        activeTodoListBootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                    });
                } else {
                    this.setState({
                        activeTodoListBootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                    });
                }
            })
            .then(() => {

            });
    }


    getOverdueTodoList(todoListFilters = null, todoListPagination = null)
    {
        if (!todoListFilters) {
            todoListFilters = this.state.overdueTodoListFilters;
        }

        if (!todoListPagination) {
            todoListPagination = this.state.overdueTodoListPagination;
        }

        let getParams = {
            pageNo: todoListPagination.currentPage,
            resultPerPage: todoListPagination.resultsPerPage,
            project: this.props.match.params.projectId,
        };

        if (todoListFilters.localSearchString != '') {
            getParams = {
                ...getParams,
                titleSearch: todoListFilters.localSearchString,
            }
        }

        ApiAxios.get('/secure/project/todo/overdue-list', {
            params: getParams
        })
            .then((response) => {
                this.setState({
                    overdueTodoList: response.data.data,
                    overdueTodoListPagination: {
                        ...this.state.overdueTodoListPagination,
                        totalCount: response.data.pagination.totalCount ? response.data.pagination.totalCount : 0,
                        noOfPages: response.data.pagination.noOfPages ? response.data.pagination.noOfPages : 1,
                    },
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        overdueTodoListBootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                    });
                } else {
                    this.setState({
                        overdueTodoListBootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                    });
                }
            })
            .then(() => {

            });
    }


    bootstrapAlertCloseButtonClickHandler(bootstrapAlertContentStateName, event)
    {
        this.setState({
            [bootstrapAlertContentStateName]: null,
        });
    }


    showCompletedTodosChangeHandler(todoListFiltersStateName, event)
    {
        const todoListFilters = this.state[todoListFiltersStateName];
        todoListFilters.showCompletedTodos = event.target.checked;

        this.setState({
            [todoListFiltersStateName]: todoListFilters,
        });

        this.getActiveTodoList(todoListFilters);
    }


    filterViewAllChangeHandler(todoListFiltersStateName, event)
    {
        const todoListFilters = this.state[todoListFiltersStateName];
        todoListFilters['filterViewAll'] = event.target.checked;

        this.setState({
            [todoListFiltersStateName]: {
                ...todoListFilters,
            },
        });

        this.getActiveTodoList(todoListFilters);
    }


    dueDateFilterClickHandler(todoListFiltersStateName, todoListPaginationStateName, inValue)
    {
        const todoListFilters = this.state[todoListFiltersStateName];
        todoListFilters['filterDate'] = inValue;

        const todoListPagination = this.state[todoListPaginationStateName];
        todoListPagination.currentPage = 1;

        this.setState({
            [todoListFiltersStateName]: {
                ...todoListFilters,
            },
        });

        this.getActiveTodoList(todoListFilters, todoListPagination);
    }


    filterPriorityClickHandler(todoListFiltersStateName, todoListPaginationStateName, inValue)
    {
        const todoListFilters = this.state[todoListFiltersStateName];
        todoListFilters['filterPriority'] = inValue;

        const todoListPagination = this.state[todoListPaginationStateName];
        todoListPagination.currentPage = 1;

        this.setState({
            [todoListFiltersStateName]: {
                ...todoListFilters,
            },
        });

        this.getActiveTodoList(todoListFilters, todoListPagination);
    }


    paginatorClickHandler(todoListPaginationStateName, isNextClick = false)
    {
        const todoListPagination = this.state[todoListPaginationStateName];

        if (isNextClick) {
            if (todoListPagination.currentPage === todoListPagination.noOfPages) {
                return false;
            }
            todoListPagination.currentPage++;
        } else {
            if (todoListPagination.currentPage === 1) {
                return false;
            }
            todoListPagination.currentPage--;
        }

        this.setState({
            [todoListPaginationStateName]: {
                ...todoListPagination,
            },
        });

        if (todoListPaginationStateName === 'activeTodoListPagination') {
            this.getActiveTodoList(null, todoListPagination);
        } else {
            this.getOverdueTodoList(null, todoListPagination);
        }
    }


    resultsPerPageChangeHandler(todoListPaginationStateName, newValue)
    {
        const todoListPagination = this.state[todoListPaginationStateName];
        todoListPagination.resultsPerPage = newValue;
        todoListPagination.currentPage = 1;

        this.setState({
            [todoListPaginationStateName]: todoListPagination,
        });

        if (todoListPaginationStateName === 'activeTodoListPagination') {
            this.getActiveTodoList(null, todoListPagination);
        } else {
            this.getOverdueTodoList(null, todoListPagination);
        }

    }


    isCompletedCheckboxChangeHandler(todoListStateName, todoId, event)
    {
        const postData = {
            todo: todoId,
            completed: event.target.checked ? 1 : 0,
            project: this.props.match.params.projectId,
        };

        this.setState({
            bootstrapAlertContent: null,
        });

        ApiAxios.post('/secure/project/todo/update/completed', postData)
            .then((response) => {
                if (todoListStateName === 'activeTodoList') {
                    this.getActiveTodoList();
                } else if (todoListStateName === 'overdueTodoList') {
                    this.getOverdueTodoList();
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                    });
                } else {
                    this.setState({
                        bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                    });
                }
            })
            .then(() => {

            });
    }


    localSearchStringChangeHandler(todoListFiltersStateName, event)
    {
        const searchString = event.target.value;

        let errorMessage = '';
        if (searchString != '' && searchString.length < 3) {
            errorMessage = 'Please ensure search string is more than 2 chars in length';
        }
        if (searchString.length > 15) {
            errorMessage = 'Please ensure search string is less than 16 chars in length';
        }

        const updatedFilter = this.state[todoListFiltersStateName];
        updatedFilter['localSearchString'] = searchString;
        updatedFilter['localSearchErrorString'] = errorMessage;

        if (searchString === '') {
            updatedFilter['localSearchString'] = '';
            updatedFilter['localSearchErrorString'] = '';
        }

        this.setState({
            [todoListFiltersStateName]: {
                ...updatedFilter,
            },
        });

        if (searchString === '') {
            if (todoListFiltersStateName === 'activeTodoListFilters') {
                this.getActiveTodoList(updatedFilter, null);
            } else {
                this.getOverdueTodoList(updatedFilter, null);
            }
        }
    }


    localSearchFormSubmitHandler(todoListFiltersStateName, event)
    {
        event.preventDefault();

        const searchString = this.state[todoListFiltersStateName].localSearchString;

        let errorMessage = '';
        if (searchString != '' && searchString.length < 3) {
            errorMessage = 'Please ensure search string is more than 2 chars in length';
        }
        if (searchString.length > 15) {
            errorMessage = 'Please ensure search string is less than 16 chars in length';
        }

        const updatedFilter = this.state[todoListFiltersStateName];
        updatedFilter['localSearchString'] = searchString;
        updatedFilter['localSearchErrorString'] = errorMessage;

        this.setState({
            [todoListFiltersStateName]: {
                ...updatedFilter,
            },
        });

        if (errorMessage != '') {
            if (todoListFiltersStateName === 'activeTodoListFilters') {
                this.setState({
                    activeTodoListBootstrapAlertContent: errorMessage,
                });
            } else {
                this.setState({
                    overdueTodoListBootstrapAlertContent: errorMessage,
                });
            }

            return false;
        }

        if (todoListFiltersStateName === 'activeTodoListFilters') {
            this.getActiveTodoList(updatedFilter, null);
        } else {
            this.getOverdueTodoList(updatedFilter, null);
        }
    }


    todoMigratedEventHandler(todoId, event)
    {
        // if (todoListStateName === 'activeTodoList') {
        //
        // } else if (todoListStateName === 'overdueTodoList') {
        //
        // }

        this.getActiveTodoList();
        this.getOverdueTodoList();
    }


    todoQuickUpdatedEventHandler(todoId, event)
    {
        this.getActiveTodoList();
        this.getOverdueTodoList();
    }


    render()
    {

        const breadcrumbLinks = (
            <React.Fragment>
                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                <li className="breadcrumb-item active">Active Todos</li>
            </React.Fragment>
        );

        return (
            <CommonPageLayout breadcrumbLinks={breadcrumbLinks} pageTitle="Active Todos">

                <MigrateProjectComponent projectId={this.props.match.params.projectId} comingFrom="activeTodoList" todoMigratedEventHandler={this.todoMigratedEventHandler} />
                <QuickEditTodoComponent projectId={this.props.match.params.projectId} comingFrom="activeTodoList" todoQuickUpdatedEventHandler={this.todoQuickUpdatedEventHandler} />

                <div className="row">
                    <div className="col-12">
                        <div className="email-leftbar card">
                            <div style={{marginBottom:'3rem'}}>
                                <h5>Project</h5>
                                <hr />
                                {utils.getProjectDisplpayTitle(this.state.projectDetails.p__title)}
                            </div>

                            <div style={{marginBottom:'3rem', color:'white'}}>
                                <h5>Quick Actions</h5>
                                <hr />
                                <a className="btn btn-primary btn-block waves-effect waves-light" onClick={this.populateEditModal} data-toggle="modal" data-target="#updateProjectModal">View / Manage Project Details</a>
                                <a className="btn btn-warning btn-block waves-effect waves-light" data-toggle="modal" data-target="#addNewTodoModal">Add New Todo</a>
                                <NavLink to={"/project/"+this.props.match.params.projectId+"/past-completed-todos"} className="btn btn-danger btn-block waves-effect waves-light">View Past Completed Todo List</NavLink>
                            </div>

                            <TodoFilters
                                showCompletedTodosCheckbox
                                showViewAllCheckbox
                                showDateFilter
                                showPriorityFilter
                                filters={this.state.activeTodoListFilters}
                                showCompletedTodosChangeHandler={this.showCompletedTodosChangeHandler}
                                filterViewAllChangeHandler={this.filterViewAllChangeHandler}
                                dueDateFilterClickHandler={this.dueDateFilterClickHandler}
                                filterPriorityClickHandler={this.filterPriorityClickHandler}
                                todoListFiltersStateName="activeTodoListFilters"
                                todoListPaginationStateName="activeTodoListPagination" />

                            <div style={{marginTop:'3rem', marginBottom:'3rem'}}>
                                <h5>Description</h5>
                                <hr />
                                {utils.getProjectDisplpayTitle(this.state.projectDetails.p__description, 200)}
                            </div>
                        </div>


                        <div className="email-rightbar mb-3">
                            <TodoList
                                listTitle="Overdue Todos"
                                showLocalSearch
                                todoListStateName="overdueTodoList"
                                projectId={this.props.match.params.projectId}
                                filters={this.state.overdueTodoListFilters}
                                todoListPagination={this.state.overdueTodoListPagination}
                                todoList={this.state.overdueTodoList}
                                bootstrapAlertContent={this.state.overdueTodoListBootstrapAlertContent}
                                paginatorClickHandler={this.paginatorClickHandler}
                                resultsPerPageChangeHandler={this.resultsPerPageChangeHandler}
                                todoListPaginationStateName="overdueTodoListPagination"
                                todoListFilterStateName="overdueTodoListFilters"
                                localSearchStringChangeHandler={this.localSearchStringChangeHandler}
                                localSearchFormSubmitHandler={this.localSearchFormSubmitHandler}
                                isCompletedCheckboxChangeHandler={this.isCompletedCheckboxChangeHandler} />

                            <TodoList
                                listTitle="Active Todos"
                                showLocalSearch
                                todoListStateName="activeTodoList"
                                projectId={this.props.match.params.projectId}
                                filters={this.state.activeTodoListFilters}
                                todoListPagination={this.state.activeTodoListPagination}
                                todoList={this.state.activeTodoList}
                                bootstrapAlertContent={this.state.activeTodoListBootstrapAlertContent}
                                paginatorClickHandler={this.paginatorClickHandler}
                                resultsPerPageChangeHandler={this.resultsPerPageChangeHandler}
                                todoListPaginationStateName="activeTodoListPagination"
                                todoListFilterStateName="activeTodoListFilters"
                                localSearchStringChangeHandler={this.localSearchStringChangeHandler}
                                localSearchFormSubmitHandler={this.localSearchFormSubmitHandler}
                                isCompletedCheckboxChangeHandler={this.isCompletedCheckboxChangeHandler} />
                        </div>

                    </div>
                </div>

                <AddTodo projectId={this.props.match.params.projectId} />
            </CommonPageLayout>
        );
    }
}


// Redux State Management
const mapStateToProps = (curState) => {
    return {
        newTodoGotAdded: curState.newTodoGotAdded,
        projectGotUpdated: curState.projectGotUpdated,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addInterPageMessage: (message, messageType) => {
            return dispatch({type: actionTypes.INTER_PAGE_MESSAGE_ADDED, message: message, messageType: messageType});
        },
        processNewTodo: () => {
            return dispatch({type: actionTypes.NEW_TODO_GOT_PROCESSED})
        },
        populateUpdateProjectModal: (id, title, description) => {
            return dispatch({type: actionTypes.QUICK_EDIT_PROJECT, id: id, title: title, description: description})
        },
        processProjectChanges: () => {
            return dispatch({type: actionTypes.NEW_PROJECT_GOT_PROCESSED})
        },
    };
};


// Export Components
export default connect(mapStateToProps, mapDispatchToProps)(ActiveTodoList);