import React from 'react';
import classes from './FormGroupInput.module.css';

const formGroupInput = (props) => {

    let inputElement = null;

    let customStyle = {};
    if (props.customStyle) {
        customStyle = props.customStyle;
    }

    switch (props.elementType) {        
        case 'input':
            inputElement = <input 
                                className="form-control"
                                style={customStyle}
                                {...props.elementConfig}
                                value={props.value}
                                // required={props.validation.required ? true : false}
                                onChange={props.changeHandler} />
            break;
        case 'textarea':
            inputElement = <textarea 
                                className="form-control"
                                style={customStyle}
                                {...props.elementConfig}
                                value={props.value}
                                // required={props.validation.required ? true : false}
                                onChange={props.changeHandler} />
            break;
        case 'select':
            inputElement = <select onChange={props.changeHandler} value={props.value} className="form-control" style={customStyle}>
                {
                    props.elementConfig.options.map((optionEle) => {
                        return (
                            <option 
                                key={optionEle['value']} 
                                value={optionEle['value']}
                                // selected={ optionEle['value'] == props.value ? true : false }
                                >{optionEle['displayValue']}</option>
                        );
                    })
                }
            </select>;
            break;
        default:
            break;
    }

    let errorEle = null;
    if (props.invalidMessage !== '') {
        errorEle = <div className={classes.InvalidFormField}>{props.invalidMessage}</div>
    }

    return (
        <div className="form-group">
            <label htmlFor={props.name}>{ props.elementConfig.required ? '* ' : '' }{props.label}</label>
            { inputElement }
            { errorEle }
        </div>
        // <div className="form-group">
        //     <label htmlFor={props.eleName}>{ props.eleRequired ? '* ' : '' }{props.lblValue}</label>
        //     <input 
        //         type={props.eleType} className="form-control" 
        //         name={props.eleName} id={props.eleName} required={props.eleRequired} 
        //         maxLength={props.eleMaxLength} minLength={props.eleMinLength} />
        //     <div class="invalid-feedback" id={'invalid-feedback__'+props.eleName}></div>
        // </div>
    );
}

export default formGroupInput;