// Import React, Supporting Libraries Libraries
import React from 'react';
import { NavLink } from 'react-router-dom';
import Axios from '../../../Axios/Axios';
import { Link } from 'react-router-dom';
// import { useToasts, withToastManager } from 'react-toast-notifications'
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as actionTypes from '../../../redux/state/actions';
import * as utils from '../../../utils/utils';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate, } from 'react-day-picker/moment';


// Import Css Module
import classes from './TodoDetails.module.css';


// Import Other Components
import FormGroupInput from '../../../components/Form/FormGroupInput/FormGroupInput';
import SubmitButton from '../../../components/Form/SubmitButton/SubmitButton';
import MainNavHeader from '../../../components/Navigation/MainNavHeader/MainNavHeader';


// Class Component
class TodoDetails extends React.Component
{

    constructor(props)
    {
        super(props);

        const today = new Date();
        const dateString = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();


        this.state = {
            attachmentList: {},

            // Edit Todo Details Form Data config settings
            editTodoFormData: {
                title: {
                    elementType: 'textarea',
                    elementConfig: {
                        placeholder: 'Updated todo content',
                        required: true,
                        rows: 20,
                    },
                    customStyle: {
                        resize: 'none',
                    },
                    value: '',
                    label: 'Todo',
                    validation: {
                        required: true,
                        minLength: 2,
                        maxLength: 5000,
                    },
                    invalidMessage: '',
                },
                priority: {
                    elementType: 'select',
                    elementConfig: {
                        required: true,
                        options: [
                            {value: '1', displayValue:'Highest'},
                            {value: '2', displayValue:'High'},
                            {value: '3', displayValue:'Medium'},
                            {value: '4', displayValue:'Low'},
                            {value: '5', displayValue:'Lowest'},
                        ]
                    },
                    value: '',
                    label: 'Priority',
                    validation: {
                        required: true,
                        validValues: ['5', '4', '3', '2', '1'],
                    },
                    invalidMessage: '',
                },
                dueDate: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        placeholder: 'Due date',
                        required: true,
                    },
                    value: dateString,
                    label: 'Due Date',
                    validation: {
                        required: true,
                        minLength: 2,
                        maxLength: 10,
                    },
                    invalidMessage: '',
                },
            },
            editTodoIsCompleted: false,
            editTodoFormHasError: false,
            editTodoFormSubmitting: false,

            dayPickerSelectedDay: undefined,


            // New Attachment Form Data config settings
            newAttachmentFile: null,
            newAttachmentHasError: false,
            newAttachmentSubmitting: false,
        };

        this.handleDayPickerDayChange = this.handleDayPickerDayChange.bind(this);
    }


    componentDidMount() {
        const authToken = utils.validateIsAuthenticated();
        if (authToken != false) {
            Axios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
        } else {
            this.props.history.replace('/login');
        }
        
        this.getTodoDetails();
    }

    
    getTodoDetails()
    {
        const todoDetailsURL = '/secure/todo/details';
        const getParams = {
            todo: this.props.match.params.id,
        };

        Axios.get(todoDetailsURL, {
            params: getParams
        })
            .then((response) => {

                const updatedEditTodoFormData = {
                    ...this.state.editTodoFormData
                };

                const editTodoFormTitleData = {
                    ...updatedEditTodoFormData.title
                }
                const editTodoFormPriorityData = {
                    ...updatedEditTodoFormData.priority
                }
                const editTodoFormDueDateData = {
                    ...updatedEditTodoFormData.dueDate
                }

                const dueDateArray = response.data.data.t__due_date.split(" ");

                editTodoFormTitleData.value = response.data.data.t__title;
                editTodoFormPriorityData.value = response.data.data.t__priority;
                editTodoFormDueDateData.value = dueDateArray[0];

                updatedEditTodoFormData.title = editTodoFormTitleData;
                updatedEditTodoFormData.priority = editTodoFormPriorityData;
                updatedEditTodoFormData.dueDate = editTodoFormDueDateData;

                const isCompleted = response.data.data.t__completed_at === null ? false : true;

                this.setState({
                    editTodoFormData: updatedEditTodoFormData,
                    editTodoIsCompleted: isCompleted,
                    attachmentList: response.data.data.attachmentList,

                    dayPickerSelectedDay: new Date(parseDate(dueDateArray[0], 'YYYY-MM-DD')),
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    toast(error.response.data.message, {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });                    
                } else {
                    toast('Your request could not be processed right now, please try again later', {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });
                }
                // else if (error.request) {
                //     console.log('[Resgister POST Error Request Caught]', error.request);
                // }
                // console.log('[Resgister POST Error Message Caught]', error.message);
            })
            .then(() => {
                
            });
    }


    handleDayPickerDayChange(selectedDay, modifiers, dayPickerInput) {
        const updatedFormData = this.state.editTodoFormData;
        const updatedFormEle = updatedFormData.dueDate;
        
        updatedFormEle['value'] = formatDate(selectedDay, 'YYYY-MM-DD'); // event.target.value;
        updatedFormEle['invalidMessage'] = this.validateInputValue(updatedFormEle['value'], updatedFormEle['validation']);
        
        let formHasError = false;
        if (updatedFormEle['invalidMessage'] !== '') {
            formHasError = true;
        }

        updatedFormData.dueDate = updatedFormEle;
        
        this.setState({
            editTodoFormData: updatedFormData,
            editTodoFormHasError: formHasError,
            dayPickerSelectedDay: selectedDay,
        });
    }


    handleInputValueChange = (formEleName, parentElementName, event) => {
        const updatedFormData = this.state[parentElementName];
        const updatedFormEle = updatedFormData[formEleName];
        
        updatedFormEle['value'] = event.target.value;
        updatedFormEle['invalidMessage'] = this.validateInputValue(event.target.value, updatedFormEle['validation']);
        
        let formHasError = false;
        if (updatedFormEle['invalidMessage'] !== '') {
            formHasError = true;
        }

        updatedFormData[formEleName] = updatedFormEle;

        if (parentElementName === 'editTodoFormData') {
            this.setState({
                editTodoFormData: updatedFormData,
                editTodoFormHasError: formHasError,
            });
        }
    }


    validateInputValue(inputValue, validationRules) {
        let validationErrorMessage = '';

        if (validationRules['required'] === true) {
            if (inputValue === '') {
                validationErrorMessage = 'This field cannot be empty';
            }
        }

        if (validationErrorMessage === '' && validationRules['validValues']) {
            validationErrorMessage = 'Please set a valid value from predefined list';
            for (let value in validationRules['validValues']) {
                if (value === inputValue) {
                    validationErrorMessage = '';
                }
            }
        }

        if (validationErrorMessage === '' && validationRules['minLength'])
        {
            if (inputValue.length < validationRules['minLength']) {
                validationErrorMessage = 'Please set a value with character length at least: '+validationRules['minLength']+' characters';
            }
        }

        if (validationErrorMessage === '' && validationRules['maxLength'])
        {
            if (inputValue.length > validationRules['maxLength']) {
                validationErrorMessage = 'Please set a value with character length not more than: '+validationRules['maxLength']+' characters';
            }
        }

        if (validationErrorMessage === '' && validationRules['sizeLength'])
        {
            if (inputValue.length !== validationRules['sizeLength']) {
                validationErrorMessage = 'Please set a value with character length exactly: '+validationRules['sizeLength']+' characters';
            }
        }

        return validationErrorMessage;
    }


    showValidationErrorAfterFormSubmit(errorResponsePayload) {

        const updatedFormData = this.state.editTodoFormData;

        for (let fieldName in this.state.formData) {
            if (errorResponsePayload.data[fieldName] && errorResponsePayload.data[fieldName][0]) {
                const updatedFormEle = updatedFormData[fieldName];
                updatedFormEle['invalidMessage'] = errorResponsePayload.data[fieldName][0];
                updatedFormData[fieldName] = updatedFormEle;
            }
        }

        this.setState({
            editTodoFormData: updatedFormData,
            editTodoFormHasError: true,
        });
    }


    // Earlier this was getting called onBlur event, now this is no longer getting used
    handleEditTodoUpdateFormSubmit = (formEleName, event) => {
        this.setState({
            editTodoFormSubmitting: true,
        });

        let postFormData = {};
        let formSubmitUrl = '/secure/todo/update/';

        if (formEleName === 'title') {
            formSubmitUrl += 'title';
            postFormData = {
                todo: this.props.match.params.id,
                title: this.state.editTodoFormData.title.value,
            };
        } else if (formEleName === 'priority') {
            formSubmitUrl += 'priority';
            postFormData = {
                todo: this.props.match.params.id,
                priority: this.state.editTodoFormData.priority.value,
            };
        } else if (formEleName === 'dueDate') {
            formSubmitUrl += 'due-date';
            postFormData = {
                todo: this.props.match.params.id,
                dueDate: this.state.editTodoFormData.dueDate.value,
            };
        }

        Axios.post(formSubmitUrl, postFormData)
            .then((response) => {
                toast(response.data.message, {
                    autoClose: 8000,
                    hideProgressBar: true,
                    type: toast.TYPE.SUCCESS,
                    position: toast.POSITION.BOTTOM_RIGHT,
                    pauseOnHover: true,
                    pauseOnFocusLoss: true,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    toast(error.response.data.message, {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });

                    this.showValidationErrorAfterFormSubmit(error.response.data);
                    
                } else {
                    toast('Your request could not be processed right now, please try again later', {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });
                }
                // else if (error.request) {
                //     console.log('[Resgister POST Error Request Caught]', error.request);
                // }
                // console.log('[Resgister POST Error Message Caught]', error.message);
            })
            .then(() => {
                this.setState({
                    editTodoFormSubmitting: false,
                });
            });
    }


    handleCompletedCheckboxChange(event)
    {
        this.setState({
            editTodoIsCompleted: event.target.checked ? true : false,
        });

        /*
        const postData = {
            todo: this.props.match.params.id,
            completed: event.target.checked ? 1 : 0,
        };
        Axios.post('/secure/todo/update/completed', postData)
            .then((response) => {
                toast(response.data.message, {
                    autoClose: 8000,
                    hideProgressBar: true,
                    type: toast.TYPE.SUCCESS,
                    position: toast.POSITION.BOTTOM_RIGHT,
                    pauseOnHover: true,
                    pauseOnFocusLoss: true,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    toast(error.response.data.message, {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });                    
                } else {
                    toast('Your request could not be processed right now, please try again later', {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });
                }
            })
            .then(() => {
                // this.setState({
                //     formSubmitting: false,
                // });
            });
        */
    }


    handleEditTodoFormSubmit = (event) => 
    {
        event.preventDefault();

        this.setState({
            editTodoFormSubmitting: true,
        });

        const postFormData = {
            todo: this.props.match.params.id,
            title: this.state.editTodoFormData.title.value,
            priority: parseInt(this.state.editTodoFormData.priority.value),
            dueDate: this.state.editTodoFormData.dueDate.value,
            completed: this.state.editTodoIsCompleted ? 1 : 0,
        };

        Axios.post('/secure/todo/edit', postFormData)
            .then((response) => {
                toast(response.data.message, {
                    autoClose: 8000,
                    hideProgressBar: true,
                    type: toast.TYPE.SUCCESS,
                    position: toast.POSITION.BOTTOM_RIGHT,
                    pauseOnHover: true,
                    pauseOnFocusLoss: true,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    toast(error.response.data.message, {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });

                    // this.showValidationErrorAfterFormSubmit(error.response.data);
                    
                } else {
                    toast('Your request could not be processed right now, please try again later', {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });
                }
                // else if (error.request) {
                //     console.log('[Resgister POST Error Request Caught]', error.request);
                // }
                // console.log('[Resgister POST Error Message Caught]', error.message);
            })
            .then(() => {
                this.setState({
                    editTodoFormSubmitting: false,
                });
            });
    }


    handleNewAttachmentFileChange = (event) => {
        this.setState({
            newAttachmentFile: event.target.files[0],
        });
    }


    handleNewAttachmentFileSubmit = (event) => {
        event.preventDefault();

        this.setState({
            newAttachmentSubmitting: true,
        });

        // Create an object of formData 
        const formData = new FormData();

        // Update the formData object 
        formData.append ( 
            "todo", 
            this.props.match.params.id
        );
        formData.append ( 
            "fileAttachment", 
            this.state.newAttachmentFile, 
            this.state.newAttachmentFile.name 
        );

        // const postFormData = {
        //     todo: this.props.match.params.id,
        //     attachment: this.state.newAttachmentFile,
        // };

        Axios.post('/secure/todo/attachment/upload', formData)
            .then((response) => {
                this.setState({
                    newAttachmentFile: null,
                });

                toast(response.data.message, {
                    autoClose: 8000,
                    hideProgressBar: true,
                    type: toast.TYPE.SUCCESS,
                    position: toast.POSITION.BOTTOM_RIGHT,
                    pauseOnHover: true,
                    pauseOnFocusLoss: true,
                });
                this.getTodoDetails();
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    toast(error.response.data.message, {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });

                    // this.showValidationErrorAfterFormSubmit(error.response.data);
                    
                } else {
                    toast('Your request could not be processed right now, please try again later', {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });
                }
                // else if (error.request) {
                //     console.log('[Resgister POST Error Request Caught]', error.request);
                // }
                // console.log('[Resgister POST Error Message Caught]', error.message);
            })
            .then(() => {
                this.setState({
                    newAttachmentSubmitting: false,
                });
            });
    }


    render()
    {
        let formFields = [];
        for (let eleName in this.state.editTodoFormData) {
            const element = this.state.editTodoFormData[eleName];
            formFields.push(
                <FormGroupInput 
                    key={eleName}
                    name={eleName}
                    elementType={element.elementType} 
                    value={element.value}
                    label={element.label}
                    customStyle={element.customStyle}
                    invalidMessage={element.invalidMessage}
                    elementConfig={element.elementConfig}
                    changeHandler={this.handleInputValueChange.bind(this, eleName, 'editTodoFormData')} />
            );
        }

        const attachmentListFields = [];
        let totalUploadedAttachmentCount = this.state.attachmentList.length;
        let oppositeNumbering = totalUploadedAttachmentCount;
        for (let eleName = 1; eleName <= totalUploadedAttachmentCount; eleName++) {
            const element = this.state.attachmentList[eleName-1];
            attachmentListFields.push(
                <div className="form-group" key={element.a__id}>
                    <label>Attachment {oppositeNumbering}: {element.a__created_at}</label>
                    <br />
                    <a href={'/secure/todos/'+element.a__todo_id+'/attachment/'+element.a__id+'/view-attachment-file'} target="_blank">{element.a__original_file_name}</a>
                </div>
            );
            oppositeNumbering--;
        }
        // for (let eleName in this.state.attachmentList) {
        //     const element = this.state.attachmentList[eleName];
        //     attachmentListFields.push(
        //         <div className="form-group" key={element.a__id}>
        //             <label>Attachment {eleName}: {element.a__created_at}</label>
        //             <br />
        //             <a href={'/secure/todos/'+element.a__todo_id+'/attachment/'+element.a__id+'/view-attachment-file'} target="_blank">{element.a__original_file_name}</a>
        //         </div>
        //     );
        //     totalUploadedAttachmentCount++;
        // }

        return (
            <React.Fragment>
                <MainNavHeader />
                <div className={'container-fluid ' + classes.MyContainer}>
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">

                            <div className="row">
                                <div className="col-md-12">
                                    <h3>Manage Todo Details</h3>
                                </div>
                            </div>
                            <div className="row" style={{marginTop: '1rem'}}>
                                <div className="col-md-8">
                                    Todo Details
                                    <hr />

                                    {/* <DayPicker /> */}

                                    <form onSubmit={this.handleEditTodoFormSubmit}>
                                        <div className="row">
                                            <div className="col-md-6 col-lg-5 col-xl-4">
                                                <div className="form-group form-check">
                                                    <input 
                                                        type="checkbox" 
                                                        checked={this.state.editTodoIsCompleted}
                                                        className="form-check-input"
                                                        onChange={this.handleCompletedCheckboxChange.bind(this)} />
                                                    <label className="form-check-label">Is Completed</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-5 col-xl-4" style={{marginBottom: '1rem'}}>
                                                <SubmitButton formHasError={this.state.editTodoFormHasError} label="Update Todo Details" isSubmitting={this.state.editTodoFormSubmitting} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-lg-5 col-xl-4">
                                                <FormGroupInput 
                                                    key={this.state.editTodoFormData.priority}
                                                    name={this.state.editTodoFormData.priority}
                                                    elementType={this.state.editTodoFormData.priority.elementType} 
                                                    value={this.state.editTodoFormData.priority.value}
                                                    label={this.state.editTodoFormData.priority.label}
                                                    customStyle={this.state.editTodoFormData.priority.customStyle}
                                                    invalidMessage={this.state.editTodoFormData.priority.invalidMessage}
                                                    elementConfig={this.state.editTodoFormData.priority.elementConfig}
                                                    changeHandler={this.handleInputValueChange.bind(this, 'priority', 'editTodoFormData')} />
                                            </div>
                                            <div className="col-md-6 col-lg-5 col-xl-4">
                                                <div className="form-group">
                                                    <label htmlFor="dueDate">* Due Date</label>
                                                    <DayPickerInput 
                                                        style={{display: 'block'}}
                                                        inputProps={{className: 'form-control', readOnly: true,}}
                                                        dayPickerProps={{disabledDays: {before: new Date()},}}
                                                        hideOnDayClick={true}
                                                        onDayChange={this.handleDayPickerDayChange}
                                                        formatDate={formatDate}
                                                        parseDate={parseDate}
                                                        placeholder={`${formatDate(new Date(), 'MMM Do, YYYY')}`}
                                                        selectedDays={this.state.dayPickerSelectedDay}
                                                        value={`${formatDate(this.state.dayPickerSelectedDay, 'MMM Do, YYYY')}`} />
                                                </div>
                                                {/* <FormGroupInput 
                                                    key={this.state.editTodoFormData.dueDate}
                                                    name={this.state.editTodoFormData.dueDate}
                                                    elementType={this.state.editTodoFormData.dueDate.elementType} 
                                                    value={this.state.editTodoFormData.dueDate.value}
                                                    label={this.state.editTodoFormData.dueDate.label}
                                                    customStyle={this.state.editTodoFormData.dueDate.customStyle}
                                                    invalidMessage={this.state.editTodoFormData.dueDate.invalidMessage}
                                                    elementConfig={this.state.editTodoFormData.dueDate.elementConfig}
                                                    changeHandler={this.handleInputValueChange.bind(this, 'dueDate', 'editTodoFormData')} /> */}
                                            </div>
                                        </div>

                                        <FormGroupInput 
                                            key={this.state.editTodoFormData.title}
                                            name={this.state.editTodoFormData.title}
                                            elementType={this.state.editTodoFormData.title.elementType} 
                                            value={this.state.editTodoFormData.title.value}
                                            label={this.state.editTodoFormData.title.label}
                                            customStyle={this.state.editTodoFormData.title.customStyle}
                                            invalidMessage={this.state.editTodoFormData.title.invalidMessage}
                                            elementConfig={this.state.editTodoFormData.title.elementConfig}
                                            changeHandler={this.handleInputValueChange.bind(this, 'title', 'editTodoFormData')} />
                                        
                                        
                                        {/* {formFields} */}
                                        

                                    </form>
                                </div>
                                <div className="col-md-4">
                                    Todo Attachments
                                    <hr />
                                    {
                                        (3 - totalUploadedAttachmentCount) > 0 ? 
                                            <form onSubmit={this.handleNewAttachmentFileSubmit}>
                                                <div className="form-group">
                                                    <label for="exampleFormControlFile1">Upload Attachment</label>
                                                    <input type="file" className="form-control-file" id="exampleFormControlFile1" onChange={this.handleNewAttachmentFileChange} />
                                                </div>
                                                
                                                <SubmitButton formHasError={this.state.newAttachmentHasError} label="Upload Attachment" isSubmitting={this.state.newAttachmentSubmitting} />
                                            </form>
                                        : null
                                    }
                                    
                                    <small className="form-text text-muted">You can upload upto 3 attachments per todo. Attachment file size cannot be more than 5MB.</small>
                                    <small className="form-text text-muted">You can upload <b>{(3 - totalUploadedAttachmentCount)} more</b> attachment(s).</small>
                                    <hr />
                                    {
                                        (totalUploadedAttachmentCount > 0) ? attachmentListFields : <p>No attachments uploaded yet</p>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


// Redux State Management
const mapStateToProps = (curState) => {
    return {
        token: curState.token,
        isAuthenticated: curState.isAuthenticated,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        getAuthToken: () => {
            return dispatch({type: actionTypes.AUTH_CHECK})
        }
    };
};


// Export Component
export default connect(mapStateToProps, mapDispatchToProps)(TodoDetails);