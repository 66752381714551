// Import React, Supporting Libraries Libraries
import React from 'react';
import Axios from '../../../../Axios/Axios';
import { Link } from 'react-router-dom';
// import { useToasts, withToastManager } from 'react-toast-notifications'
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as actionTypes from '../../../../redux/state/actions';
import * as utils from '../../../../utils/utils';


// Import Css Module
import classes from './PersonalDetails.module.css';


// Import Other Components
import FormGroupInput from '../../../../components/Form/FormGroupInput/FormGroupInput';
import SubmitButton from '../../../../components/Form/SubmitButton/SubmitButton';
import MainNavHeader from '../../../../components/Navigation/MainNavHeader/MainNavHeader';


// Class Component
class PersonalDetails extends React.Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            formUrl: '/secure/my-profile/personal-details',

            formData: {
                firstName: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        placeholder: 'Your first name',
                        required: true,
                    },
                    value: '',
                    label: 'First Name',
                    validation: {
                        required: true,
                        minLength: 2,
                        maxLength: 30,
                    },
                    invalidMessage: '',
                },
                lastName: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        placeholder: 'Your last name',
                        required: true,
                    },
                    value: '',
                    label: 'Last Name',
                    validation: {
                        required: true,
                        minLength: 2,
                        maxLength: 30,
                    },
                    invalidMessage: '',
                },
            },

            formHasError: false,
            formSubmitting: false,
        }

        this.handlePersonalDetailsFormSubmit = this.handlePersonalDetailsFormSubmit.bind(this);
    }


    componentDidMount() {
        const authToken = utils.validateIsAuthenticated();
        if (authToken != false) {
            Axios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
        } else {
            this.props.history.replace('/login');
        }

        this.getPersonalDetails();
    }


    getPersonalDetails()
    {
        const getPersonalDetailsURL = '/secure/my-profile/personal-details';
        Axios.get(getPersonalDetailsURL)
            .then((response) => {
                console.log(response.data.data);
                const formData = this.state.formData;
                formData.firstName.value = response.data.data.u__first_name;
                formData.lastName.value = response.data.data.u__last_name;
                this.setState({
                    formData: {
                        ...formData
                    }
                })
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    toast(error.response.data.message, {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });                    
                } else {
                    toast('Your request could not be processed right now, please try again later', {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });
                }
            })
            .then(() => {

            })
    }


    handleInputValueChange = (formEleName, event) => {
        const updatedFormData = this.state.formData;
        const updatedFormEle = updatedFormData[formEleName];
        
        updatedFormEle['value'] = event.target.value;
        updatedFormEle['invalidMessage'] = this.validateInputValue(event.target.value, updatedFormEle['validation']);
        
        let formHasError = false;
        if (updatedFormEle['invalidMessage'] !== '') {
            formHasError = true;
        }

        updatedFormData[formEleName] = updatedFormEle;

        this.setState({
            formData: updatedFormData,
            formHasError: formHasError,
        });
    }


    validateInputValue(inputValue, validationRules) {
        let validationErrorMessage = '';

        if (validationRules['required'] === true) {
            if (inputValue === '') {
                validationErrorMessage = 'This field cannot be empty';
            }
        }

        if (validationErrorMessage === '' && validationRules['validValues']) {
            validationErrorMessage = 'Please set a valid value from predefined list';
            for (let value in validationRules['validValues']) {
                if (value === inputValue) {
                    validationErrorMessage = '';
                }
            }
        }

        if (validationErrorMessage === '' && validationRules['minLength'])
        {
            if (inputValue.length < validationRules['minLength']) {
                validationErrorMessage = 'Please set a value with character length at least: '+validationRules['minLength']+' characters';
            }
        }

        if (validationErrorMessage === '' && validationRules['maxLength'])
        {
            if (inputValue.length > validationRules['maxLength']) {
                validationErrorMessage = 'Please set a value with character length not more than: '+validationRules['maxLength']+' characters';
            }
        }

        if (validationErrorMessage === '' && validationRules['sizeLength'])
        {
            if (inputValue.length !== validationRules['sizeLength']) {
                validationErrorMessage = 'Please set a value with character length exactly: '+validationRules['sizeLength']+' characters';
            }
        }

        return validationErrorMessage;
    }


    showValidationErrorAfterFormSubmit(errorResponsePayload) {

        const updatedFormData = this.state.formData;

        for (let fieldName in this.state.formData) {
            if (errorResponsePayload.data[fieldName] && errorResponsePayload.data[fieldName][0]) {
                const updatedFormEle = updatedFormData[fieldName];
                updatedFormEle['invalidMessage'] = errorResponsePayload.data[fieldName][0];
                updatedFormData[fieldName] = updatedFormEle;
            }
        }

        this.setState({
            formData: updatedFormData,
            formHasError: true,
        });
    }


    handlePersonalDetailsFormSubmit(event)
    {
        event.preventDefault();

        this.setState({
            formSubmitting: true,
        });

        const postFormData = {
            firstName: this.state.formData.firstName.value,
            lastName: this.state.formData.lastName.value,
        };

        Axios.post(this.state.formUrl, postFormData)
            .then((response) => {
                toast(response.data.message, {
                    autoClose: 8000,
                    hideProgressBar: true,
                    type: toast.TYPE.SUCCESS,
                    position: toast.POSITION.BOTTOM_RIGHT,
                    pauseOnHover: true,
                    pauseOnFocusLoss: true,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    toast(error.response.data.message, {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });

                    this.showValidationErrorAfterFormSubmit(error.response.data);
                    
                } else {
                    toast('Your request could not be processed right now, please try again later', {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });
                }
                // else if (error.request) {
                //     console.log('[Resgister POST Error Request Caught]', error.request);
                // }
                // console.log('[Resgister POST Error Message Caught]', error.message);
            })
            .then(() => {
                this.setState({
                    formSubmitting: false,
                });
            });
    }


    render()
    {
        let formFields = [];
        for (let eleName in this.state.formData) {
            const element = this.state.formData[eleName];
            formFields.push(
                <FormGroupInput 
                    key={eleName}
                    name={eleName}
                    elementType={element.elementType} 
                    value={element.value}
                    label={element.label}
                    invalidMessage={element.invalidMessage}
                    elementConfig={element.elementConfig}
                    changeHandler={this.handleInputValueChange.bind(this, eleName)}
                    />
            );
        }

        return(
            <React.Fragment>
                <MainNavHeader />
                <div className={'container ' + classes.MyContainer}>
                    <div className="row">
                        <div className="col-md-5 offset-md-4">
                        <h3>Personal Details</h3>
                        <form onSubmit={this.handlePersonalDetailsFormSubmit}>
                                <br />

                                {formFields}

                                <SubmitButton formHasError={this.state.formHasError} label="Update" isSubmitting={this.state.formSubmitting} />
                                <small className="form-text text-muted">* Marked fields are mandatory.</small>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
    
}


// Redux State Management
const mapStateToProps = (curState) => {
    return {
        token: curState.token,
        isAuthenticated: curState.isAuthenticated,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        getAuthToken: () => {
            return dispatch({type: actionTypes.AUTH_CHECK})
        }
    };
};


// Export Component
export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails);