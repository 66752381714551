// Import React, Supporting Libraries
import React from "react";
import ApiAxios from '../vendors/axios/api';
import { toast } from 'react-toastify';


// Import Other Components
import FormGroupInput from '../components/form/FormGroupInput/FormGroupInput';


// Private/internal/helper Functions/Constants, not exporting
const validateInputValue = (inputValue, validationRules) => {
    let validationErrorMessage = '';

    if (validationRules['required'] === true) {
        if (inputValue === '') {
            validationErrorMessage = 'This field cannot be empty';
        }
    }

    if (validationErrorMessage === '' && validationRules['validValues']) {
        validationErrorMessage = 'Please set a valid value from predefined list';
        // console.log(inputValue, validationRules['validValues']);
        for (let value in validationRules['validValues']) {
            if (validationRules['validValues'][value] == inputValue) {
                validationErrorMessage = '';
            }
        }
    }

    if (validationErrorMessage === '' && validationRules['minLength'])
    {
        if (inputValue.length < validationRules['minLength']) {
            validationErrorMessage = 'Please set a value with character length at least: '+validationRules['minLength']+' characters';
        }
    }

    if (validationErrorMessage === '' && validationRules['maxLength'])
    {
        if (inputValue.length > validationRules['maxLength']) {
            validationErrorMessage = 'Please set a value with character length not more than: '+validationRules['maxLength']+' characters';
        }
    }

    if (validationErrorMessage === '' && validationRules['sizeLength'])
    {
        if (inputValue.length !== validationRules['sizeLength']) {
            validationErrorMessage = 'Please set a value with character length exactly: '+validationRules['sizeLength']+' characters';
        }
    }

    return validationErrorMessage;
}


// Export Functions/Constants
export const authTokenErrorMessages = [
    'Oops!!! Looks like your access token has expired, please try logging in again before accessing this route.',
    'Oops!!! Looks like you are using an invalid access token, please try logging in again before accessing this route.',
    'Oops!!! Looks like you are trying to access a secure route without any access token, please try logging in again before accessing this route.',
    'Oops!!! Looks like you got logged out, please try logging in again.',
];


export const inputValueChangedHelper = (formEleName, newValue, formStateObj) => {
    const updatedFormEle = formStateObj.fields[formEleName];

    updatedFormEle['value'] = newValue;
    updatedFormEle['invalidMessage'] = validateInputValue(newValue, updatedFormEle['validation']);

    const formFields = formStateObj.fields;
    formFields[formEleName] = updatedFormEle;

    let formHasError = false;
    if (updatedFormEle['invalidMessage'] !== '') {
        formHasError = true;
    }

    return {
        ...formStateObj,
        fields: formFields,
        formHasError: formHasError,
    };
}


export const renderFormGroupComponentHelper = (formStateObj, inputValueChangeHandler, passwordShowButtonClickHandler = null) => {
    const formFields = [];
    for (let eleName in formStateObj) {
        const element = formStateObj[eleName];

        let formGroupElement = <FormGroupInput
            key={eleName}
            name={eleName}
            elementType={element.elementType}
            value={element.value}
            label={element.label}
            invalidMessage={element.invalidMessage}
            elementConfig={element.elementConfig}
            changeHandler={inputValueChangeHandler.bind(this, eleName)}
        />;

        // This is a password field
        if (element.isPasswordField) {
            formGroupElement = <FormGroupInput
                key={eleName}
                name={eleName}
                elementType={element.elementType}
                value={element.value}
                label={element.label}
                invalidMessage={element.invalidMessage}
                elementConfig={element.elementConfig}
                isPasswordField={element.isPasswordField}
                passwordShowButtonClickHandler={passwordShowButtonClickHandler.bind(this, eleName)}
                changeHandler={inputValueChangeHandler.bind(this, eleName)}
            />;
        }

        formFields.push(
            formGroupElement
        );
    }

    return formFields;
}


// NOT WORKING...... // DELETE IT
export const showToastNotification = (message, isError = false) => {
    if (isError) {
        toast(message, {
            autoClose: 8000,
            hideProgressBar: true,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_RIGHT,
            pauseOnHover: true,
            pauseOnFocusLoss: true,
        });
    } else {
        toast(message, {
            autoClose: 8000,
            hideProgressBar: true,
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.BOTTOM_RIGHT,
            pauseOnHover: true,
            pauseOnFocusLoss: true,
        });
    }
}


export const showBootstrapAlert = (closeButtonClickHandler, message, isError = false) => {
    if (!message) {
        return null;
    }

    let returnContent = (
        <div className="alert alert-success alert-dismissible fade show" role="alert" onClick={closeButtonClickHandler}>
            {message}
            <button type="button" className="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    );
    if (isError) {
        returnContent = (
            <div className="alert alert-warning alert-dismissible fade show" role="alert">
                {message}
                <button type="button" className="close" aria-label="Close" onClick={closeButtonClickHandler}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        );
    }

    return returnContent;
}


export const showValidationErrorAfterFormSubmit = (errorResponsePayload, formStateObj) => {

    const updatedFormData = formStateObj;

    for (let fieldName in formStateObj.fields) {
        if (errorResponsePayload.data[fieldName] && errorResponsePayload.data[fieldName][0]) {
            const updatedFormEle = formStateObj.fields[fieldName];
            updatedFormEle['invalidMessage'] = errorResponsePayload.data[fieldName][0];

            updatedFormData.fields[fieldName] = updatedFormEle;
            updatedFormData.formHasError = true;
        }
    }

    return {
        ...updatedFormData
    };
}


export const showInterPageMessageUsingBootstrapAlert = (closeButtonClickHandler, interPageMessage, interPageMessageType) => {
    if (!interPageMessageType) {
        return null;
    }

    let isError = false;
    if (interPageMessageType.toLowerCase() === 'error') {
        isError = true;
    }

    return showBootstrapAlert(closeButtonClickHandler, interPageMessage, isError);
}


export const getCurrentSystemDate = (doubleDigits = false) => {
    const today = new Date();

    let month  =  today.getMonth() + 1;
    let day    =  today.getDate();

    if (doubleDigits) {
        if (month < 10) {
            month = '0' + month;
        }

        if (day < 10) {
            day = '0' + day;
        }
    }

    return today.getFullYear()+'-'+month+'-'+day;
}


export const returnJustDate = (inDateTime) => {
    if (inDateTime == '' || inDateTime == null) {
        return null;
    }
    const dateTimeArray = inDateTime.split(" ");
    return formatDateToHumanReadableFormat(dateTimeArray[0]);
}


export const formatDateToHumanReadableFormat = (inDate) => {
    const dateArray = inDate.split("-");
    const yearValue = parseInt(dateArray[0]);
    const monthValue = parseInt(dateArray[1]);
    const dayValue = parseInt(dateArray[2]);

    const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    return dayValue+' '+monthNames[monthValue-1]+', '+yearValue;
}


export const getTodoDisplpayTitle = (inFullTitle, maxLength = 194) => {
    let modifiedTitle = inFullTitle;
    if (inFullTitle.length > maxLength) {
        modifiedTitle = inFullTitle.substring(0, maxLength)+' .....';
    }
    return modifiedTitle;
}


export const getProjectDisplpayTitle = (inFullTitle, maxLength = 294) => {
    if (!inFullTitle) {
        return inFullTitle;
    }

    let modifiedTitle = inFullTitle;
    if (inFullTitle.length > maxLength) {
        modifiedTitle = inFullTitle.substring(0, maxLength)+' .....';
    }
    return modifiedTitle;
}


export const validateIsAuthenticated = (props = undefined) => {
    const accessToken = localStorage.getItem('access_token');
    const expiresInSecs = localStorage.getItem('expires_in_secs');
    const tokenType = localStorage.getItem('token_type');
    if (accessToken && expiresInSecs && tokenType) {
        return accessToken;
    } else {
        localStorage.clear();
        if (typeof(props) !== 'undefined' && props != null) {
            // props.history.replace('/login');
            clearAuth('Oops!!! Looks like you got logged out, please try logging in again.', props, false);
            props.addInterPageMessage('Oops!!! Looks like you got logged out, please try logging in again.', 'error');
            props.history.replace({
                pathname: '/login',
                state: {},
            });
        }
    }

    return false;
}


export const clearAuth = (errorMessage, props, performRedirect = true) => {
    const accessToken = localStorage.getItem('access_token');
    localStorage.clear();
    if (accessToken) {
        ApiAxios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
        ApiAxios.post('/secure/my-profile/logout')
            .then((response) => {

            })
            .catch((error) => {

            });
    }

    if (performRedirect) {
        props.addInterPageMessage(errorMessage, 'error');
        props.history.replace({
            pathname: '/',
            state: {},
        });
    }
}


export const userReadablePriority = (pirorityCode) => {
    const priorityArray = ['Highest', 'High', 'Medium', 'Low', 'Lowest'];

    return priorityArray[pirorityCode];
}


export const priorityClassSuffix = (priorityName) => {
    const priorityClassSuffixArray = {
        Highest: 'danger',
        High: 'warning',
        Medium: 'primary',
        Low: 'secondary',
        Lowest: 'success',
    };

    return priorityClassSuffixArray[priorityName];
}


export const appendScriptToBodySync = (pathToScript) => {
    const script = document.createElement('script');
    script.src = pathToScript;
    script.async = false;
    document.body.appendChild(script);
}


export const redirectToLogin = (props) => {
    if (props.history) {
        clearAuth('Oops!!! Looks like you got logged out, please try logging in again.', props, false);
        props.addInterPageMessage('Oops!!! Looks like you got logged out, please try logging in again.', 'error');
        props.history.replace({
            pathname: '/login',
            state: {},
        });
        // props.history.replace('/login');
    } else {
        window.location.replace('/login');
    }
}
