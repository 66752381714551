// Import React, Supporting Libraries
import React from 'react';
import { connect } from 'react-redux';


// Import Other Components
import ApiAxios from '../../../vendors/axios/api';
import * as utils from '../../../utils/utils';
import * as actionTypes from '../../../vendors/redux/state/actions';


// Import Functional Components
import GuestOnlyCommonPageLayout from '../../../components/guest_only/CommonPageLayout/CommonPageLayout';
import SubmitButton from '../../../components/form/SubmitButton/SubmitButton';


// Class Component
class ResetPassword extends React.Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            verifyResetPasswordTokenUrl: '/guest/forgot-password/verify-token?forgotPasswordToken=',

            resetPasswordForm: {
                url: '/guest/forgot-password/reset-password',

                resetPasswordToken: null,

                fields: {
                    password: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'password',
                            placeholder: 'New Password for your account',
                            required: true,
                            minLength: 5,
                            maxLength: 20,
                        },
                        isPasswordField: true,
                        value: '',
                        label: 'New Password',
                        validation: {
                            required: true,
                            minLength: 5,
                            maxLength: 20,
                        },
                        invalidMessage: '',
                    },
                    password_confirmation: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'password',
                            placeholder: 'Retype new password again to confirm it',
                            required: true,
                            minLength: 5,
                            maxLength: 20,
                        },
                        isPasswordField: true,
                        value: '',
                        label: 'Retype Password',
                        validation: {
                            required: true,
                            minLength: 5,
                            maxLength: 20,
                        },
                        invalidMessage: '',
                    },
                },

                formHasError: false,
                formSubmitting: false,

                bootstrapAlertContent: null,
            },
        }

        // Regular Functions
        this.validateFormHasErrorBeforeSubmit       =  this.validateFormHasErrorBeforeSubmit.bind(this);

        // Event Handlers
        this.inputValueChangeHandler                =  this.inputValueChangeHandler.bind(this);
        this.resetPasswordFormSubmitHandler         =  this.resetPasswordFormSubmitHandler.bind(this);
        this.bootstrapAlertCloseButtonClickHandler  =  this.bootstrapAlertCloseButtonClickHandler.bind(this);
        this.passwordShowButtonClickHandler         =  this.passwordShowButtonClickHandler.bind(this);
    }


    componentDidMount() {
        const authToken = utils.validateIsAuthenticated();
        if (authToken != false) {
            this.props.history.replace('/secure/todos');
        }

        if (this.props.match && this.props.match.params && this.props.match.params.token) {
            ApiAxios.get(this.state.verifyResetPasswordTokenUrl + this.props.match.params.token)
                .then((response) => {
                    if (response.data.status !== 'success') {
                        this.props.addInterPageMessage(response.data.message, 'error');
                        this.props.history.push({
                            pathname: '/',
                            state: {},
                        });
                    } else {
                        this.setState({
                            resetPasswordForm: {
                                ...this.state.resetPasswordForm,
                                resetPasswordToken: this.props.match.params.token,
                            },
                        });
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        this.props.addInterPageMessage(error.response.data.message, 'error');
                        this.props.history.push({
                            pathname: '/',
                            state: {},
                        });
                    } else {
                        this.props.addInterPageMessage('Your request could not be processed right now, please try again later', 'error');
                        this.props.history.push({
                            pathname: '/',
                            state: {},
                        });
                    }
                })
                .then(() => {

                });
        } else {
            this.props.addInterPageMessage('Invalid reset password token', 'error');
            this.props.history.push({
                pathname: '/',
                state: {},
            });
        }

    }


    inputValueChangeHandler(formEleName, event) {
        this.setState({
            resetPasswordForm: {
                ...utils.inputValueChangedHelper(formEleName, event.target.value, this.state.resetPasswordForm)
            }
        });
    }


    validateFormHasErrorBeforeSubmit() {
        let formHasError = false;
        for (let eleName in this.state.resetPasswordForm.fields) {
            const element = this.state.resetPasswordForm.fields[eleName];

            const validationResponse = utils.inputValueChangedHelper(eleName, element.value, this.state.resetPasswordForm);
            this.setState({
                resetPasswordForm: {
                    ...validationResponse
                }
            });
            if (validationResponse.formHasError) {
                formHasError = true;
            }
        }

        return formHasError;
    }


    bootstrapAlertCloseButtonClickHandler(event) {
        this.setState({
            resetPasswordForm: {
                ...this.state.resetPasswordForm,
                bootstrapAlertContent: null,
            },
        });
    }


    resetPasswordFormSubmitHandler(event)
    {
        event.preventDefault();

        if (this.validateFormHasErrorBeforeSubmit()) {
            return false;
        }

        this.setState({
            resetPasswordForm: {
                ...this.state.resetPasswordForm,
                formSubmitting: true,
                bootstrapAlertContent: null,
            }
        });

        const postFormData = {
            resetPasswordToken: this.state.resetPasswordForm.resetPasswordToken,
            password: this.state.resetPasswordForm.fields.password.value,
            password_confirmation: this.state.resetPasswordForm.fields.password_confirmation.value,
        };

        ApiAxios.post(this.state.resetPasswordForm.url, postFormData)
            .then((response) => {
                this.props.addInterPageMessage(response.data.message, 'success');
                this.props.history.push({
                    pathname: '/login',
                    state: {},
                });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({
                        resetPasswordForm: {
                            ...this.state.resetPasswordForm,
                            ...utils.showValidationErrorAfterFormSubmit(error.response.data, this.state.resetPasswordForm),
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                        }
                    });
                } else {
                    this.props.addInterPageMessage('Your request could not be processed right now, please try again later', 'error');
                    this.props.history.push({
                        pathname: '/login',
                        state: {},
                    });
                }
                this.setState({
                    resetPasswordForm: {
                        ...this.state.resetPasswordForm,
                        formHasError: true,
                    }
                });
            })
            .then(() => {
                this.setState({
                    resetPasswordForm: {
                        ...this.state.resetPasswordForm,
                        formSubmitting: false,
                    }
                });
            });
    }


    passwordShowButtonClickHandler(formEleName, event)
    {
        const resetPasswordForm = this.state.resetPasswordForm;
        if (this.state.resetPasswordForm.fields[formEleName].elementConfig.type == 'password') {
            resetPasswordForm.fields[formEleName].elementConfig.type = 'text';
        } else {
            resetPasswordForm.fields[formEleName].elementConfig.type = 'password';
        }
        this.setState({
            resetPasswordForm: {
                ...resetPasswordForm,
            }
        })
    }


    render()
    {

        return (
            <GuestOnlyCommonPageLayout onFormSubmit={this.resetPasswordFormSubmitHandler} mainTitle="Set New Password" subTitle="Generate new password for your EsyTodo account." footerLinkLabel="Remembered your Password ? " footerLink="/login" footerLinkContent="Sign In" >
                { this.state.resetPasswordForm.bootstrapAlertContent }

                { utils.renderFormGroupComponentHelper(this.state.resetPasswordForm.fields, this.inputValueChangeHandler, this.passwordShowButtonClickHandler) }

                <div className="form-group row mb-0 justify-content-end">
                    <div className="col-5">
                        <SubmitButton
                            label="Update Password"
                            formHasError={this.state.resetPasswordForm.formHasError}
                            isSubmitting={this.state.resetPasswordForm.formSubmitting} />
                    </div>
                </div>
            </GuestOnlyCommonPageLayout>
        )
    }
}


// Redux State Management
const mapStateToProps = (curState) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addInterPageMessage: (message, messageType) => {
            return dispatch({type: actionTypes.INTER_PAGE_MESSAGE_ADDED, message: message, messageType: messageType});
        },
    };
};


// Export Component
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);