// Import React, Supporting Libraries Libraries
import React from 'react';


// Import Css Module
import classes from './FormGroupInput.module.css';


// Functional Component
const formGroupInput = (props) => {

    let inputElement = null;

    let customStyle = {};
    if (props.customStyle) {
        customStyle = props.customStyle;
    }

    if (props.elementType == 'input') {
        if (props.isPasswordField) {
            
            let eyeIcon = <i className="mdi mdi-eye-outline"></i>;
            if (props.elementConfig.type != 'password') {
                eyeIcon = <i className="mdi mdi-eye-off-outline"></i>;
            }
            inputElement =
                <div className="input-group auth-pass-inputgroup">
                    <input
                        id={props.name}
                        className="form-control"
                        style={customStyle}
                        {...props.elementConfig}
                        value={props.value}
                        onChange={props.changeHandler}
                        aria-label="Password" />
                    <button onClick={props.passwordShowButtonClickHandler} className="btn btn-light" type="button">{eyeIcon}</button>
                </div>
        } else {
            inputElement = <input
                id={props.name}
                className="form-control"
                style={customStyle}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changeHandler} />
        }

    } else if (props.elementType == 'textarea') {
        inputElement = <textarea
            id={props.name}
            className="form-control"
            style={customStyle}
            {...props.elementConfig}
            value={props.value}
            onChange={props.changeHandler} />
    } else if (props.elementType == 'select') {
        inputElement = <select id={props.name} onChange={props.changeHandler} value={props.value} className="form-control" style={customStyle}>
            {
                props.elementConfig.options.map((optionEle) => {
                    return (
                        <option key={optionEle['value']} value={optionEle['value']}>{optionEle['displayValue']}</option>
                    );
                })
            }
        </select>;
    }

    let errorEle = null;
    if (props.invalidMessage !== '') {
        errorEle = <div className={classes.InvalidFormField}>{props.invalidMessage}</div>
    }

    return (
        <div className="form-group">
            <label htmlFor={props.name}>{ props.elementConfig.required ? '* ' : '' }{props.label}</label>
            { inputElement }
            { errorEle }
        </div>
    );
}


// Export Components
export default formGroupInput;