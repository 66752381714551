// Import React, Supporting Libraries Libraries
import React from 'react';
import Axios from '../../Axios/Axios';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/state/actions';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate, } from 'react-day-picker/moment';


// Import Css Module
import classes from './AddTodo.module.css';


// Import Other Components
import FormGroupInput from '../../components/Form/FormGroupInput/FormGroupInput';
import SubmitButton from '../../components/Form/SubmitButton/SubmitButton';


// Class Component
class AddTodo extends React.Component
{

    constructor(props)
    {
        super(props);

        const today = new Date();
        let month = today.getMonth()+1;
        if (month < 10) {
            month = '0'+month;
        }
        const dateString = today.getFullYear()+'-'+month+'-'+today.getDate();

        this.state = {
            formData: {
                newTodo: {
                    elementType: 'textarea',
                    elementConfig: {
                        type: 'text',
                        placeholder: 'New todo content',
                        required: true,
                        rows: 6,
                    },
                    value: '',
                    label: 'Todo',
                    customStyle: {
                        resize: 'none',
                    },
                    validation: {
                        required: true,
                        minLength: 2,
                        maxLength: 300,
                    },
                    invalidMessage: '',
                },
                newTodoPriority: {
                    elementType: 'select',
                    elementConfig: {
                        options: [
                            {value: '1', displayValue:'Highest'},
                            {value: '2', displayValue:'High'},
                            {value: '3', displayValue:'Medium'},
                            {value: '4', displayValue:'Low'},
                            {value: '5', displayValue:'Lowest'},
                        ]
                    },
                    value: '3',
                    label: 'Priority',
                    validation: {
                        required: true,
                        validValues: ['5', '4', '3', '2', '1'],
                    },
                    invalidMessage: '',
                },
                newTodoDueDate: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        placeholder: 'Due date',
                        required: true,
                        'data-provide': 'datepicker',
                    },
                    value: dateString,
                    label: 'Due Date',
                    validation: {
                        required: true,
                        minLength: 2,
                        maxLength: 10,
                    },
                    invalidMessage: '',
                },
            },

            formHasError: false,
            formSubmitting: false,

            dayPickerSelectedDay: undefined,
        };

        this.handleDayPickerDayChange = this.handleDayPickerDayChange.bind(this);
    }


    componentDidMount() {
        this.props.getAuthToken();
        if (this.props.token.access_token) {
            Axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.props.token.access_token;
        } else {
            const accessToken = localStorage.getItem('access_token');
            Axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
        }
    }


    handleDayPickerDayChange(selectedDay, modifiers, dayPickerInput) {
        const updatedFormData = this.state.formData;
        const updatedFormEle = updatedFormData.newTodoDueDate;
        
        updatedFormEle['value'] = formatDate(selectedDay, 'YYYY-MM-DD'); // event.target.value;
        updatedFormEle['invalidMessage'] = this.validateInputValue(updatedFormEle['value'], updatedFormEle['validation']);
        
        let formHasError = false;
        if (updatedFormEle['invalidMessage'] !== '') {
            formHasError = true;
        }

        updatedFormData.newTodoDueDate = updatedFormEle;
        
        this.setState({
            formData: updatedFormData,
            formHasError: formHasError,
            dayPickerSelectedDay: selectedDay,
        });
    }


    handleInputValueChange = (formEleName, event) => {
        const updatedFormData = this.state.formData;
        const updatedFormEle = updatedFormData[formEleName];
        
        updatedFormEle['value'] = event.target.value;
        updatedFormEle['invalidMessage'] = this.validateInputValue(event.target.value, updatedFormEle['validation']);
        
        let formHasError = false;
        if (updatedFormEle['invalidMessage'] !== '') {
            formHasError = true;
        }

        updatedFormData[formEleName] = updatedFormEle;

        this.setState({
            formData: updatedFormData,
            formHasError: formHasError,
        });
    }


    validateInputValue(inputValue, validationRules) {
        let validationErrorMessage = '';

        if (validationRules['required'] === true) {
            if (inputValue === '') {
                validationErrorMessage = 'This field cannot be empty';
            }
        }

        if (validationErrorMessage === '' && validationRules['validValues']) {
            validationErrorMessage = 'Please set a valid value from predefined list';
            for (let value in validationRules['validValues']) {
                if (value === inputValue) {
                    validationErrorMessage = '';
                }
            }
        }

        if (validationErrorMessage === '' && validationRules['minLength'])
        {
            if (inputValue.length < validationRules['minLength']) {
                validationErrorMessage = 'Please set a value with character length at least: '+validationRules['minLength']+' characters';
            }
        }

        if (validationErrorMessage === '' && validationRules['maxLength'])
        {
            if (inputValue.length > validationRules['maxLength']) {
                validationErrorMessage = 'Please set a value with character length not more than: '+validationRules['maxLength']+' characters';
            }
        }

        if (validationErrorMessage === '' && validationRules['sizeLength'])
        {
            if (inputValue.length !== validationRules['sizeLength']) {
                validationErrorMessage = 'Please set a value with character length exactly: '+validationRules['sizeLength']+' characters';
            }
        }

        return validationErrorMessage;
    }


    handleAddTodoFormSubmit = (event) =>
    {
        event.preventDefault();

        this.setState({
            formSubmitting: true,
        });

        const postFormData = {
            title: this.state.formData.newTodo.value,
            priority: this.state.formData.newTodoPriority.value,
            dueDate: this.state.formData.newTodoDueDate.value,
        };

        Axios.post('/secure/todo/create', postFormData)
            .then((response) => {
                toast(response.data.message, {
                    autoClose: 8000,
                    hideProgressBar: true,
                    type: toast.TYPE.SUCCESS,
                    position: toast.POSITION.BOTTOM_RIGHT,
                    pauseOnHover: true,
                    pauseOnFocusLoss: true,
                });
                this.props.newTodoGotAdded();

                const updatedFormData = this.state.formData;
                const updatedNewTodo = {
                    ...updatedFormData.newTodo,
                    value: '',
                };
                updatedFormData.newTodo = updatedNewTodo;
                this.setState({
                    formData: updatedFormData,
                });

                // document.getElementById('addNewTodoModal').modal('hide');
                var newModal = document.getElementById('addNewTodoModal');
                var closeArr = newModal.getElementsByClassName('close');
                closeArr[0].click();
            })
            .catch((error) => {
                if (error.response) {
                    toast(error.response.data.message, {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });
                } else {
                    toast('Your request could not be processed right now, please try again later', {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });
                }
            })
            .then(() => {
                this.setState({
                    formSubmitting: false,
                });
            });
    }


    render()
    {
        return (
            // Modal
            <div className="modal fade" data-show="true" id="addNewTodoModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <form onSubmit={this.handleAddTodoFormSubmit}>
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add New Todo</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="dueDate">* Due Date</label>
                                        <DayPickerInput 
                                            style={{display: 'block'}}
                                            inputProps={{className: 'form-control', readOnly: true,}}
                                            dayPickerProps={{disabledDays: {before: new Date()},}}
                                            hideOnDayClick={true}
                                            onDayChange={this.handleDayPickerDayChange}
                                            formatDate={formatDate}
                                            parseDate={parseDate}
                                            placeholder={`${formatDate(new Date(), 'MMM Do, YYYY')}`}
                                            selectedDays={this.state.dayPickerSelectedDay}
                                            value={`${formatDate(this.state.dayPickerSelectedDay, 'MMM Do, YYYY')}`} />
                                    </div>
                                    {/* <FormGroupInput 
                                        name='dueDate'
                                        elementType={this.state.formData.newTodoDueDate.elementType}
                                        value={this.state.formData.newTodoDueDate.value}
                                        label={this.state.formData.newTodoDueDate.label}
                                        invalidMessage={this.state.formData.newTodoDueDate.invalidMessage}
                                        elementConfig={this.state.formData.newTodoDueDate.elementConfig}
                                        changeHandler={this.handleInputValueChange.bind(this, 'newTodoDueDate')} /> */}
                                </div>
                                <div className="col-sm-6">
                                    <FormGroupInput 
                                        name='priority'
                                        elementType={this.state.formData.newTodoPriority.elementType}
                                        value={this.state.formData.newTodoPriority.value}
                                        label={this.state.formData.newTodoPriority.label}
                                        invalidMessage={this.state.formData.newTodoPriority.invalidMessage}
                                        elementConfig={this.state.formData.newTodoPriority.elementConfig}
                                        changeHandler={this.handleInputValueChange.bind(this, 'newTodoPriority')} />
                                </div>
                            </div>
                            <FormGroupInput 
                                name='title'
                                elementType={this.state.formData.newTodo.elementType}
                                customStyle={this.state.formData.newTodo.customStyle}
                                value={this.state.formData.newTodo.value}
                                label={this.state.formData.newTodo.label}
                                invalidMessage={this.state.formData.newTodo.invalidMessage}
                                elementConfig={this.state.formData.newTodo.elementConfig}
                                changeHandler={this.handleInputValueChange.bind(this, 'newTodo')}
                                />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            <SubmitButton formHasError={this.state.formHasError} label="Add Todo" isSubmitting={this.state.formSubmitting} />
                        </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}


// Redux State Management
const mapStateToProps = (curState) => {
    return {
        token: curState.token,
        isAuthenticated: curState.isAuthenticated,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        getAuthToken: () => {
            return dispatch({type: actionTypes.AUTH_CHECK})
        },
        newTodoGotAdded: () => {
            return dispatch({type: actionTypes.NEW_TODO_GOT_ADDED})
        },
    };
};


// Export Component
export default connect(mapStateToProps, mapDispatchToProps)(AddTodo);