// Import React, Supporting Libraries Libraries
import React from 'react';
import Axios from '../../../Axios/Axios';
import { Link } from 'react-router-dom';
// import { useToasts, withToastManager } from 'react-toast-notifications'
import { toast } from 'react-toastify';
import * as utils from '../../../utils/utils';


// Import Css Module
import classes from './Register.module.css';


// Import Other Components
import FormGroupInput from '../../../components/Form/FormGroupInput/FormGroupInput';
import SubmitButton from '../../../components/Form/SubmitButton/SubmitButton';


// Class Component
class Register extends React.Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            formUrl: '/guest/register',

            formData: {
                firstName: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        placeholder: 'Your first name',
                        // required: true,
                    },
                    value: '',
                    label: 'First Name',
                    validation: {
                        // required: true,
                        // minLength: 2,
                        // maxLength: 30,
                    },
                    invalidMessage: '',
                },
                lastName: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        placeholder: 'Your last name',
                        required: true,
                    },
                    value: '',
                    label: 'Last Name',
                    validation: {
                        required: true,
                        minLength: 2,
                        maxLength: 30,
                    },
                    invalidMessage: '',
                },
                email: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'email',
                        placeholder: 'Your existing email address',
                        required: true,
                    },
                    value: '',
                    label: 'Email Address',
                    validation: {
                        required: true,
                        minLength: 5,
                        maxLength: 50,
                    },
                    invalidMessage: '',
                },
                password: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'password',
                        placeholder: 'Password for your new account',
                        required: true,
                    },
                    value: '',
                    label: 'Password',
                    validation: {
                        required: true,
                        minLength: 5,
                        maxLength: 20,
                    },
                    invalidMessage: '',
                },
            },

            formHasError: false,
            formSubmitting: false,
        }

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }


    componentDidMount() {
        const authToken = utils.validateIsAuthenticated();
        if (authToken != false) {
            this.props.history.replace('/secure/todos');
        }
    }


    handleInputValueChange = (formEleName, event) => {
        const updatedFormData = this.state.formData;
        const updatedFormEle = updatedFormData[formEleName];
        
        updatedFormEle['value'] = event.target.value;
        updatedFormEle['invalidMessage'] = this.validateInputValue(event.target.value, updatedFormEle['validation']);
        
        let formHasError = false;
        if (updatedFormEle['invalidMessage'] !== '') {
            formHasError = true;
        }

        updatedFormData[formEleName] = updatedFormEle;

        this.setState({
            formData: updatedFormData,
            formHasError: formHasError,
        });
    }


    validateInputValue(inputValue, validationRules) {
        let validationErrorMessage = '';

        if (validationRules['required'] === true) {
            if (inputValue === '') {
                validationErrorMessage = 'This field cannot be empty';
            }
        }

        if (validationErrorMessage === '' && validationRules['validValues']) {
            validationErrorMessage = 'Please set a valid value from predefined list';
            for (let value in validationRules['validValues']) {
                if (value === inputValue) {
                    validationErrorMessage = '';
                }
            }
        }

        if (validationErrorMessage === '' && validationRules['minLength'])
        {
            if (inputValue.length < validationRules['minLength']) {
                validationErrorMessage = 'Please set a value with character length at least: '+validationRules['minLength']+' characters';
            }
        }

        if (validationErrorMessage === '' && validationRules['maxLength'])
        {
            if (inputValue.length > validationRules['maxLength']) {
                validationErrorMessage = 'Please set a value with character length not more than: '+validationRules['maxLength']+' characters';
            }
        }

        if (validationErrorMessage === '' && validationRules['sizeLength'])
        {
            if (inputValue.length !== validationRules['sizeLength']) {
                validationErrorMessage = 'Please set a value with character length exactly: '+validationRules['sizeLength']+' characters';
            }
        }

        return validationErrorMessage;
    }


    showValidationErrorAfterFormSubmit(errorResponsePayload) {

        const updatedFormData = this.state.formData;

        for (let fieldName in this.state.formData) {
            if (errorResponsePayload.data[fieldName] && errorResponsePayload.data[fieldName][0]) {
                const updatedFormEle = updatedFormData[fieldName];
                updatedFormEle['invalidMessage'] = errorResponsePayload.data[fieldName][0];
                updatedFormData[fieldName] = updatedFormEle;
            }
        }

        this.setState({
            formData: updatedFormData,
            formHasError: true,
        });
    }


    handleFormSubmit(event)
    {
        event.preventDefault();

        this.setState({
            formSubmitting: true,
        });

        const postFormData = {
            firstName: this.state.formData.firstName.value,
            lastName: this.state.formData.lastName.value,
            email: this.state.formData.email.value,
            password: this.state.formData.password.value,
        };

        Axios.post(this.state.formUrl, postFormData)
            .then((response) => {
                this.props.history.push({
                    pathname: '/',
                    state: {
                        successMessage: response.data.message,
                    },
                });
            })
            .catch((error) => {

                if (error.response) {
                    toast(error.response.data.message, {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });

                    this.showValidationErrorAfterFormSubmit(error.response.data);
                    
                } else {
                    toast('Your request could not be processed right now, please try again later', {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });
                }
                this.setState({
                    formSubmitting: false,
                });
                // else if (error.request) {
                //     console.log('[Resgister POST Error Request Caught]', error.request);
                // }
                // console.log('[Resgister POST Error Message Caught]', error.message);
            })
            .then(() => {
                
            });
    }


    render() {

        let formFields = [];
        for (let eleName in this.state.formData) {
            const element = this.state.formData[eleName];
            formFields.push(
                <FormGroupInput 
                    key={eleName}
                    name={eleName}
                    elementType={element.elementType} 
                    value={element.value}
                    label={element.label}
                    invalidMessage={element.invalidMessage}
                    elementConfig={element.elementConfig}
                    changeHandler={this.handleInputValueChange.bind(this, eleName)}
                    />
            );
        }

        return (
            <div className={'container ' + classes.MyContainer}>
                <div className="row">
                    <div className="col-md-5 offset-md-4">
                        <h3>Sign Up</h3>
                        <form onSubmit={this.handleFormSubmit}>
                            <br />

                            {formFields}

                            <div className="form-group form-check">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1" required />
                                <label className="form-check-label" htmlFor="exampleCheck1">Agree to Terms &amp; Conditions</label>
                            </div>

                            <SubmitButton formHasError={this.state.formHasError} label="Sign Up" isSubmitting={this.state.formSubmitting} />
                            <small className="form-text text-muted">* Marked fields are mandatory.</small>
                            <hr />
                            Already have an account? <Link to="/login">Log In</Link>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}


// Export Component
export default Register