// Import React, Supporting Libraries
import React from 'react';
import { NavLink } from 'react-router-dom';


// Import Css Module
import './TodoList.css';


// Other Components
import * as utils from '../../../utils/utils';
import * as actionTypes from "../../../vendors/redux/state/actions";
import {connect} from "react-redux";


// Functional Component
const todoList = (props) => {

    function displayTodos()
    {
        const todoListArray = [];

        for (let todoKey in props.todoList) {
            const todoEle = props.todoList[todoKey];

            const priorityName = utils.userReadablePriority(todoEle.t__priority - 1);
            const priorityBadgeClassString = "badge badge-"+utils.priorityClassSuffix(priorityName);

            let projectId = null;
            if (props.projectId) {
                projectId = props.projectId;
            } else if (todoEle.t__project_id) {
                projectId = todoEle.t__project_id;
            }

            // let label = <NavLink className="" to={'/project/'+projectId+'/todo/'+todoEle.t__id+'/details'}>{utils.getTodoDisplpayTitle(todoEle.t__title)}</NavLink>
            const label = <a href="#" onClick={quickEditTodoModal.bind(this, todoEle.t__id, todoEle.t__title, todoEle.t__due_date, todoEle.t__priority, todoEle.t__project_id)} data-toggle="modal" data-target="#quickEditTodoModal">{utils.getTodoDisplpayTitle(todoEle.t__title)}</a>;

            let checked = false;
            let completedAtHtml = null;
            if (todoEle.t__completed_at) {
                // label = <NavLink className="" to={'/todos/'+todoEle.t__id+'/details'}><s>{utils.getTodoDisplpayTitle(todoEle.t__title)}</s></NavLink>
                checked = true;
                completedAtHtml = (
                    <div className="row" style={{marginBottom:'5px'}}>
                        <div className="col-6">
                            Completed On:
                        </div>
                        <div className="col-6 text-right">
                            {utils.returnJustDate(todoEle.t__completed_at)}
                        </div>
                    </div>
                );
            }

            let dropdownMenuCompletedString = <a className='dropdown-item'>Mark as Completed</a>;
            let completedBadgeString = null;
            if (checked) {
                dropdownMenuCompletedString = <a className='dropdown-item'>Unmark as Completed</a>;
                completedBadgeString = <span className="badge badge-info" style={{backgroundColor:'green'}}>Completed</span>;
            }


            todoListArray.push(
                <div className="col-xl-3 col-lg-4 col-sm-6 mb-3" key={todoEle.t__id}>
                    <div className="card h-100" style={{backgroundColor:'antiquewhite',borderRadius:'8px'}}>
                        <div className="card-body" style={{padding:'1rem 1rem 0'}}>

                            <div className="row">

                                <div className="col-3">
                                    <div className="dropdown ms-auto">
                                        <a className="text-muted dropdown-toggle font-size-16" role="button"
                                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="mdi mdi-dots-vertical"></i>
                                        </a>

                                        <div className="dropdown-menu dropdown-menu-end">
                                            {/*{ dropdownMenuCompletedString }*/}
                                            <a className="dropdown-item" onClick={quickEditTodoModal.bind(this, todoEle.t__id, todoEle.t__title, todoEle.t__due_date, todoEle.t__priority, todoEle.t__project_id)}
                                                data-toggle="modal" data-target="#quickEditTodoModal">Quick View/Edit Details</a>
                                            <NavLink className="dropdown-item" to={'/project/'+projectId+'/todo/'+todoEle.t__id+'/details'}>Open Todo Details</NavLink>
                                            {
                                                !props.allTodosPage ? <React.Fragment><div className="dropdown-divider"></div>
                                                                        <a className="dropdown-item" onClick={populateMigrateToAnotherProjectModal.bind(this, todoEle.t__id)}
                                                                        data-toggle="modal" data-target="#migrateToAnotherProjectModal">Migrate to another project</a></React.Fragment>
                                                    : null
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="custom-control custom-checkbox mb-3 text-right">
                                        <input type="checkbox" onChange={isCompletedCheckboxChangeHandler.bind(this, todoEle.t__id, todoEle.t__project_id)} className="custom-control-input" id={todoEle.t__id} checked={checked} />
                                        <label className="custom-control-label" htmlFor={todoEle.t__id}>Mark Completed</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom:'5px'}}>
                                <div className="col-6">
                                    Due On:
                                </div>
                                <div className="col-6 text-right">
                                    {utils.returnJustDate(todoEle.t__due_date)}
                                </div>
                            </div>

                            { completedAtHtml }

                            <div className="row">
                                <div className="col-12">
                                    <span className={priorityBadgeClassString}>Priority: {priorityName}</span> {completedBadgeString}
                                </div>
                            </div>
                            <hr />
                            <div className="media-body overflow-hidden">
                                { label }
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-sm-5">
                        <div className="btn-toolbar p-3" role="toolbar">
                            <div className="btn-group mr-2 mb-2 mb-sm-0">
                                <button type="button" className="btn btn-primary waves-light waves-effect dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                    Results Per Page ({props.todoListPagination.resultsPerPage})
                                    <i className="mdi mdi-chevron-down ml-1"></i>
                                </button>
                                { generateResultsPerPageDropdownContent() }
                            </div>
                        </div>
                    </div>
                </div>

                { generatePaginationElement() }

                <div className="row" style={{padding:'0 12px'}}>
                    {todoListArray}
                </div>

            </React.Fragment>
        );
    }


    function localSearchFormSubmitHandler(event)
    {
        if (props.todoListFilterStateName) {
            props.localSearchFormSubmitHandler(props.todoListFilterStateName, event);
        } else {
            props.localSearchFormSubmitHandler(event);
        }
    }


    function localSearchStringChangeHandler(event)
    {
        if (props.todoListFilterStateName) {
            props.localSearchStringChangeHandler(props.todoListFilterStateName, event);
        } else {
            props.localSearchStringChangeHandler(event);
        }
    }


    function paginatorClickHandler(isNext, event)
    {
        if (props.todoListPaginationStateName) {
            props.paginatorClickHandler(props.todoListPaginationStateName, isNext, event);
        } else {
            props.paginatorClickHandler(isNext, event);
        }
    }


    function resultsPerPageChangeHandler(newValue, event)
    {
        if (props.todoListPaginationStateName) {
            props.resultsPerPageChangeHandler(props.todoListPaginationStateName, newValue, event);
        } else {
            props.resultsPerPageChangeHandler(newValue, event);
        }
    }


    function generatePaginationElement()
    {
        let returnOutput = null;
        let paginationString = null
        let paginator = null;

        if (props.todoListPagination.totalCount > 0) {

            let maxLimit = props.todoListPagination.resultsPerPage;
            if (props.todoListPagination.totalCount < props.todoListPagination.resultsPerPage) {
                maxLimit = props.todoListPagination.totalCount;
            }

            let upperLimit = props.todoListPagination.currentPage * maxLimit;
            const lowerLimit = upperLimit - maxLimit + 1;

            if (props.todoListPagination.totalCount > props.todoListPagination.resultsPerPage) {

                let previousButton = (
                    <button onClick={paginatorClickHandler.bind(this, false)} type="button" className="btn btn-sm btn-success waves-effect"><i className="fa fa-chevron-left"></i></button>
                );
                if (props.todoListPagination.currentPage === 1) {
                    previousButton = (
                        <button type="button" className="btn btn-sm btn-success waves-effect disabled" disabled><i className="fa fa-chevron-left"></i></button>
                    );
                }

                let nextButton = (
                    <button onClick={paginatorClickHandler.bind(this, true)} type="button" className="btn btn-sm btn-success waves-effect"><i className="fa fa-chevron-right"></i></button>
                );
                if (props.todoListPagination.currentPage === props.todoListPagination.noOfPages) {
                    upperLimit = props.todoListPagination.totalCount;
                    nextButton = (
                        <button type="button" className="btn btn-sm btn-success waves-effect disabled" disabled><i className="fa fa-chevron-right"></i></button>
                    );
                }

                paginator = (
                    <div className="col-5">
                        <div className="btn-group float-right">
                            {previousButton}
                            {nextButton}
                        </div>
                    </div>
                );
            }

            paginationString = (
                <div className="col-7">Showing {lowerLimit} - {upperLimit} of {props.todoListPagination.totalCount}</div>
            );


            returnOutput = (
                <div className="row" style={{padding:'1rem 1.4rem',width:'100%'}}>
                    { paginationString }

                    { paginator }
                </div>
            );
        }

        return returnOutput;
    }


    function generateResultsPerPageDropdownContent()
    {
        const dropdownValueArray = ['12', '24', '48', '96', '192'];
        const displayDropdownList = [];

        for (let i in dropdownValueArray) {
            const value = dropdownValueArray[i];

            if (props.todoListPagination.resultsPerPage == value) {
                displayDropdownList.push(
                    <p className="dropdown-item active" onClick={resultsPerPageChangeHandler.bind(this, value)} key={i}>{value}</p>
                );
            } else {
                displayDropdownList.push(
                    <p className="dropdown-item" onClick={resultsPerPageChangeHandler.bind(this, value)} key={i}>{value}</p>
                );
            }
        }

        return (
            <div className="dropdown-menu">
                { displayDropdownList }
            </div>
        );
    }


    function isCompletedCheckboxChangeHandler(todoId, projectId, event)
    {
        if (props.todoListStateName) {
            props.isCompletedCheckboxChangeHandler(props.todoListStateName, todoId, event, projectId);
            // props.isCompletedCheckboxChangeHandler(props.todoListStateName, todoId, event);
        } else {
            props.isCompletedCheckboxChangeHandler(todoId, event, projectId);
        }
    }


    let searchTextboxStyle = {
        color: 'black',
        // marginBottom: '5px',
    };
    if (props.showLocalSearch)
    {
        if (props.filters.localSearchErrorString) {
            searchTextboxStyle = {
                ...searchTextboxStyle,
                color: 'red',
            };
        }
    }


    function populateMigrateToAnotherProjectModal(id, event)
    {
        props.populateQuickMigrateTodoToAnotherProjectModal(id);
    }


    function quickEditTodoModal(id, title, dueDate, priority, projectId, event)
    {
        props.populateQuickEditTodoDetailsModal(id, title, dueDate, priority, projectId);
    }


    let mainLeftDivLength = '<div className="col-sm-5">';
    if (props.allTodosPage) {
        mainLeftDivLength = '<div className="col-sm-3">';
    }

    let mainRightDivLength = '<div className="col-sm-7">';
    if (props.allTodosPage) {
        mainRightDivLength = '<div className="col-sm-9">';
    }


    return (
        <div className="card" style={{width:'100%'}}>
            {
                props.listTitle ?
                    <div className="row" style={{width:'100%'}}>
                        <div className="col-sm-5">
                            <h5 style={{padding:'1.2rem'}}>{props.listTitle}</h5>
                        </div>

                        {
                            props.showLocalSearch ?
                                <div className="col-sm-7">
                                    <form className="form-inline p-3 my-2 my-lg-0" onSubmit={localSearchFormSubmitHandler}>
                                        <input className="form-control mr-sm-2"
                                               style={searchTextboxStyle}
                                               type="search" placeholder="Local Search..."
                                               aria-label="Search"
                                               value={props.filters.localSearchString}
                                               onChange={localSearchStringChangeHandler}
                                               minLength="3"
                                               maxLength="15" />
                                        <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                                    </form>
                                </div>
                            : null
                        }

                    </div>
                : null
            }

            {
                props.bootstrapAlertContent ?
                    <div className="row-col-12" style={{marginLeft:'1.2rem', marginRight:'1.2rem'}}>
                        { props.bootstrapAlertContent }
                    </div>
                : null
            }

            {
                props.todoList ?
                    props.todoList.length > 0 ?
                        displayTodos()
                    : <p style={{paddingLeft:'1.2rem'}}>No todos to display</p>
                : <p style={{paddingLeft:'1.2rem'}}>No todos to display</p>
            }
        </div>
    );
};


// Redux State Management
const mapStateToProps = (curState) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        populateQuickMigrateTodoToAnotherProjectModal: (id) => {
            return dispatch({type: actionTypes.QUICK_MIGRATE_TODO_PROJECT, id: id})
        },
        populateQuickEditTodoDetailsModal: (id, title, dueDate, priority, projectId) => {
            return dispatch({type: actionTypes.QUICK_EDIT_TODO, id: id, title: title, dueDate: dueDate, priority: priority, projectId: projectId})
        },
    };
};


// Export Components
export default connect(mapStateToProps, mapDispatchToProps)(todoList);