// Import React, Supporting Libraries
import React from 'react';
import { connect } from 'react-redux';


// Other Components
import CommonPageLayout from '../../../components/auth_only/CommonPageLayout/CommonPageLayout';
import ApiAxios from '../../../vendors/axios/api';
import * as utils from '../../../utils/utils';
import TodoFilters from '../../../components/auth_only/TodoFilters/TodoFilters';
import TodoList from '../../../components/auth_only/TodoList/TodoList';
import * as actionTypes from "../../../vendors/redux/state/actions";
import {toast} from "react-toastify";
import {Link, NavLink} from "react-router-dom";
import AddTodo from "../../../containers/auth_only/AddTodo/AddTodo";
import MigrateProjectComponent from "../../../containers/auth_only/MigrateProject/MigrateProject";
import QuickEditTodoComponent from "../../../containers/auth_only/QuickEditTodo/QuickEditTodo";


// Class Component
class PastCompletedTodoList extends React.Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            bootstrapAlertContent: null,

            pastCompletedTodoList: {},

            pastCompletedTodoListPagination: {
                resultsPerPage: 12,
                totalCount: 0,
                noOfPages: 1,
                currentPage: 1,
            },

            todoListFilters: {
                filterPriority: 3, // 1,2,3,4,5
                filterViewAll: false,

                localSearchString: '',
                localSearchErrorString: '',
            },

            projectDetails: {},
        };

        // Event Handlers
        this.populateEditModal                      =  this.populateEditModal.bind(this);
        this.filterViewAllChangeHandler             =  this.filterViewAllChangeHandler.bind(this);
        this.filterPriorityClickHandler             =  this.filterPriorityClickHandler.bind(this);
        this.paginatorClickHandler                  =  this.paginatorClickHandler.bind(this);
        this.resultsPerPageChangeHandler            =  this.resultsPerPageChangeHandler.bind(this);
        this.isCompletedCheckboxChangeHandler       =  this.isCompletedCheckboxChangeHandler.bind(this);
        this.bootstrapAlertCloseButtonClickHandler  =  this.bootstrapAlertCloseButtonClickHandler.bind(this);
        this.localSearchStringChangeHandler         =  this.localSearchStringChangeHandler.bind(this);
        this.localSearchFormSubmitHandler           =  this.localSearchFormSubmitHandler.bind(this);
        this.todoMigratedEventHandler               =  this.todoMigratedEventHandler.bind(this);
        this.todoQuickUpdatedEventHandler           =  this.todoQuickUpdatedEventHandler.bind(this);
    }


    componentDidMount()
    {
        const authToken = utils.validateIsAuthenticated(this.props);
        if (authToken != false) {
            ApiAxios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
        } else {
            utils.redirectToLogin(this.props);
        }

        this.getProjectDetails(this.props.match.params.projectId);

        this.getTodoList();
    }


    componentDidUpdate(prevProps, prevState, snapshot)
    {
        // prevProps.newTodoGotAdded !== this.props.newTodoGotAdded
        if (this.props.projectGotUpdated === true) {
            this.getProjectDetails(this.props.match.params.projectId);

            this.props.processProjectChanges();
        }
    }


    populateEditModal()
    {
        this.props.populateUpdateProjectModal(this.props.match.params.projectId, this.state.projectDetails.p__title, this.state.projectDetails.p__description);
    }


    getProjectDetails(projectId)
    {
        let getParams = {
            project: projectId,
        };

        ApiAxios.get('/secure/project/details', {
            params: getParams
        })
            .then((response) => {
                this.setState({
                    projectDetails: response.data.data,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        activeTodoListBootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                    });
                } else {
                    this.setState({
                        activeTodoListBootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                    });
                }
            })
            .then(() => {

            });
    }


    getTodoList(todoListFilters = null, todoListPagination = null)
    {
        if (!todoListFilters) {
            todoListFilters = this.state.todoListFilters;
        }

        if (!todoListPagination) {
            todoListPagination = this.state.pastCompletedTodoListPagination;
        }

        this.setState({
            bootstrapAlertContent: null,
        });

        let getParams = {
            filterPriority: todoListFilters.filterPriority,
        };

        if (todoListFilters.filterViewAll) {
            getParams = {};
        }

        getParams = {
            ...getParams,
            pageNo: todoListPagination.currentPage,
            resultPerPage: todoListPagination.resultsPerPage,
            project: this.props.match.params.projectId,
        }

        if (todoListFilters.localSearchString != '') {
            getParams = {
                ...getParams,
                titleSearch: todoListFilters.localSearchString,
            }
        }

        ApiAxios.get('/secure/project/todo/past-completed-list', {
            params: getParams
        })
            .then((response) => {
                this.setState({
                    pastCompletedTodoList: response.data.data,
                    pastCompletedTodoListPagination: {
                        ...this.state.pastCompletedTodoListPagination,
                        totalCount: response.data.pagination.totalCount ? response.data.pagination.totalCount : 0,
                        noOfPages: response.data.pagination.noOfPages ? response.data.pagination.noOfPages : 1,
                    },
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                    });
                } else {
                    this.setState({
                        bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                    });
                }
            })
            .then(() => {

            });
    }


    bootstrapAlertCloseButtonClickHandler(event)
    {
        this.setState({
            bootstrapAlertContent: null,
        });
    }


    filterViewAllChangeHandler(event)
    {
        const todoListFilters = this.state.todoListFilters;
        todoListFilters['filterViewAll'] = event.target.checked;

        this.setState({
            todoListFilters: {
                ...todoListFilters,
            },
        });

        this.getTodoList(todoListFilters);
    }


    filterPriorityClickHandler(inValue)
    {
        const todoListFilters = this.state.todoListFilters;
        todoListFilters['filterPriority'] = inValue;

        const pastCompletedTodoListPagination = this.state.pastCompletedTodoListPagination;
        pastCompletedTodoListPagination.currentPage = 1;

        this.setState({
            todoListFilters: {
                ...todoListFilters,
            },
        });

        this.getTodoList(todoListFilters, pastCompletedTodoListPagination);
    }


    paginatorClickHandler(isNextClick = false)
    {
        const pastCompletedTodoListPagination = this.state.pastCompletedTodoListPagination;

        if (isNextClick) {
            if (pastCompletedTodoListPagination.currentPage === pastCompletedTodoListPagination.noOfPages) {
                return false;
            }
            pastCompletedTodoListPagination.currentPage++;
        } else {
            if (pastCompletedTodoListPagination.currentPage === 1) {
                return false;
            }
            pastCompletedTodoListPagination.currentPage--;
        }

        this.setState({
            pastCompletedTodoListPagination: {
                ...pastCompletedTodoListPagination,
            },
        });

        this.getTodoList(null, pastCompletedTodoListPagination);
    }


    resultsPerPageChangeHandler(newValue)
    {
        const pastCompletedTodoListPagination = this.state.pastCompletedTodoListPagination;
        pastCompletedTodoListPagination.resultsPerPage = newValue;
        pastCompletedTodoListPagination.currentPage = 1;

        this.setState({
            pastCompletedTodoListPagination: pastCompletedTodoListPagination,
        });

        this.getTodoList(null, pastCompletedTodoListPagination);
    }


    isCompletedCheckboxChangeHandler(todoId, event)
    {
        const postData = {
            todo: todoId,
            completed: event.target.checked ? 1 : 0,
            project: this.props.match.params.projectId,
        };

        this.setState({
            bootstrapAlertContent: null,
        });

        ApiAxios.post('/secure/project/todo/update/completed', postData)
            .then((response) => {
                this.getTodoList();
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                    });
                } else {
                    this.setState({
                        bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                    });
                }
            })
            .then(() => {

            });
    }


    localSearchStringChangeHandler(event)
    {
        const searchString = event.target.value;

        let errorMessage = '';
        if (searchString != '' && searchString.length < 3) {
            errorMessage = 'Please ensure search string is more than 2 chars in length';
        }
        if (searchString.length > 15) {
            errorMessage = 'Please ensure search string is less than 16 chars in length';
        }

        const updatedFilter = this.state.todoListFilters;
        updatedFilter['localSearchString'] = searchString;
        updatedFilter['localSearchErrorString'] = errorMessage;

        if (searchString === '') {
            updatedFilter['localSearchString'] = '';
            updatedFilter['localSearchErrorString'] = '';
        }

        this.setState({
            todoListFilters: {
                ...updatedFilter,
            },
        });

        if (searchString === '') {
            this.getTodoList(updatedFilter, null);
        }
    }


    localSearchFormSubmitHandler(event)
    {
        event.preventDefault();

        const searchString = this.state.todoListFilters.localSearchString;

        let errorMessage = '';
        if (searchString != '' && searchString.length < 3) {
            errorMessage = 'Please ensure search string is more than 2 chars in length';
        }
        if (searchString.length > 15) {
            errorMessage = 'Please ensure search string is less than 16 chars in length';
        }

        const updatedFilter = this.state.todoListFilters;
        updatedFilter['localSearchString'] = searchString;
        updatedFilter['localSearchErrorString'] = errorMessage;

        this.setState({
            todoListFilters: {
                ...updatedFilter,
            },
        });

        if (errorMessage != '') {
            this.setState({
                bootstrapAlertContent: errorMessage,
            });

            return false;
        }

        this.getTodoList(updatedFilter, null);
    }


    todoMigratedEventHandler(todoId, event)
    {
        this.getTodoList();
    }


    todoQuickUpdatedEventHandler(todoId, event)
    {
        this.getTodoList();
    }


    render()
    {
        const breadcrumbLinks = (
            <React.Fragment>
                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                <li className="breadcrumb-item active">Past Completed Todos</li>
            </React.Fragment>
        );

        return (
            <CommonPageLayout breadcrumbLinks={breadcrumbLinks} pageTitle="Past Completed Todos">

                <MigrateProjectComponent projectId={this.props.match.params.projectId} comingFrom="pastCompletedTodoList" todoMigratedEventHandler={this.todoMigratedEventHandler} />
                <QuickEditTodoComponent projectId={this.props.match.params.projectId} comingFrom="pastCompletedTodoList" todoQuickUpdatedEventHandler={this.todoQuickUpdatedEventHandler} />

                <div className="row">
                    <div className="col-12">
                        <div className="email-leftbar card">
                            <div style={{marginBottom:'3rem'}}>
                                <h5>Project</h5>
                                <hr />
                                {utils.getProjectDisplpayTitle(this.state.projectDetails.p__title)}
                            </div>

                            <div style={{marginBottom:'3rem', color:'white'}}>
                                <h5>Quick Actions</h5>
                                <hr />
                                <a className="btn btn-primary btn-block waves-effect waves-light" onClick={this.populateEditModal} data-toggle="modal" data-target="#updateProjectModal">View / Manage Project Details</a>
                                <a className="btn btn-warning btn-block waves-effect waves-light" data-toggle="modal" data-target="#addNewTodoModal">Add New Todo</a>
                                <NavLink to={"/project/"+this.props.match.params.projectId+"/active-todos"} className="btn btn-danger btn-block waves-effect waves-light">View Active Todo List</NavLink>
                            </div>

                            <TodoFilters
                                showViewAllCheckbox
                                showPriorityFilter
                                filters={this.state.todoListFilters}
                                filterViewAllChangeHandler={this.filterViewAllChangeHandler}
                                filterPriorityClickHandler={this.filterPriorityClickHandler} />
                        </div>


                        <div className="row" style={{paddingLeft:'1rem'}}>
                            <TodoList
                                listTitle="Past Completed Todos"
                                showLocalSearch
                                projectId={this.props.match.params.projectId}
                                filters={this.state.todoListFilters}
                                todoListPagination={this.state.pastCompletedTodoListPagination}
                                todoList={this.state.pastCompletedTodoList}
                                bootstrapAlertContent={this.state.bootstrapAlertContent}
                                paginatorClickHandler={this.paginatorClickHandler}
                                resultsPerPageChangeHandler={this.resultsPerPageChangeHandler}
                                localSearchStringChangeHandler={this.localSearchStringChangeHandler}
                                localSearchFormSubmitHandler={this.localSearchFormSubmitHandler}
                                isCompletedCheckboxChangeHandler={this.isCompletedCheckboxChangeHandler} />
                        </div>
                    </div>
                </div>

                <AddTodo projectId={this.props.match.params.projectId} />
            </CommonPageLayout>
        );
    }
}


// Redux State Management
const mapStateToProps = (curState) => {
    return {
        projectGotUpdated: curState.projectGotUpdated,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addInterPageMessage: (message, messageType) => {
            return dispatch({type: actionTypes.INTER_PAGE_MESSAGE_ADDED, message: message, messageType: messageType});
        },
        populateUpdateProjectModal: (id, title, description) => {
            return dispatch({type: actionTypes.QUICK_EDIT_PROJECT, id: id, title: title, description: description})
        },
        processProjectChanges: () => {
            return dispatch({type: actionTypes.NEW_PROJECT_GOT_PROCESSED})
        },
    };
};


// Export Components
export default connect(mapStateToProps, mapDispatchToProps)(PastCompletedTodoList);