// Import React, Supporting Libraries
import React from 'react';
import { NavLink, Link } from "react-router-dom";
import { connect } from 'react-redux';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate, } from 'react-day-picker/moment';


// Import Css
import 'react-day-picker/lib/style.css';


// Other Components
import CommonPageLayout from "../../../components/auth_only/CommonPageLayout/CommonPageLayout";
import ApiAxios from "../../../vendors/axios/api";
import * as utils from "../../../utils/utils";
import * as actionTypes from "../../../vendors/redux/state/actions";
import SubmitButton from "../../../components/form/SubmitButton/SubmitButton";
import FormGroupInput from "../../../components/form/FormGroupInput/FormGroupInput";
import MigrateProjectComponent from "../../../containers/auth_only/MigrateProject/MigrateProject";


// Class Component
class TodoDetails extends React.Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            attachmentList: {},

            todoDetailsForm: {
                url: '/secure/project/todo/edit',

                fields: {
                    title: {
                        elementType: 'textarea',
                        elementConfig: {
                            placeholder: 'Updated todo content',
                            required: true,
                            rows: 25,
                            minLength: 2,
                            maxLength: 5000,
                        },
                        customStyle: {
                            resize: 'none',
                        },
                        value: '',
                        label: 'Todo',
                        validation: {
                            required: true,
                            minLength: 2,
                            maxLength: 5000,
                        },
                        invalidMessage: '',
                    },
                    priority: {
                        elementType: 'select',
                        elementConfig: {
                            required: true,
                            options: [
                                {value: '1', displayValue:'Highest'},
                                {value: '2', displayValue:'High'},
                                {value: '3', displayValue:'Medium'},
                                {value: '4', displayValue:'Low'},
                                {value: '5', displayValue:'Lowest'},
                            ]
                        },
                        value: '',
                        label: 'Priority',
                        validation: {
                            required: true,
                            validValues: ['5', '4', '3', '2', '1'],
                        },
                        invalidMessage: '',
                    },
                    dueDate: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            placeholder: 'Due date',
                            required: true,
                            minLength: 2,
                            maxLength: 10,
                        },
                        value: utils.getCurrentSystemDate(true),
                        label: 'Due Date',
                        validation: {
                            required: true,
                            minLength: 2,
                            maxLength: 10,
                        },
                        invalidMessage: '',
                    },
                },

                isCompletedCheckbox: false,
                dayPickerSelectedDay: undefined,

                formHasError: false,
                formSubmitting: false,

                bootstrapAlertContent: null,
            },

            // New Attachment Form Data config settings
            newAttachmentFile: null,
            newAttachmentHasError: false,
            newAttachmentSubmitting: false,

            bootstrapAlertContent: null,
        };

        // Regular Functions
        this.getTodoDetails                         =  this.getTodoDetails.bind(this);
        this.validateFormHasErrorBeforeSubmit       =  this.validateFormHasErrorBeforeSubmit.bind(this);

        // Event Handlers
        this.inputValueChangeHandler                =  this.inputValueChangeHandler.bind(this);
        this.isCompletedCheckboxChangeHandler       =  this.isCompletedCheckboxChangeHandler.bind(this);
        this.dayPickerDayChangeHandler              =  this.dayPickerDayChangeHandler.bind(this);
        this.todoDetailsFormSubmitHandler           =  this.todoDetailsFormSubmitHandler.bind(this);
        this.bootstrapAlertCloseButtonClickHandler  =  this.bootstrapAlertCloseButtonClickHandler.bind(this);

        this.newAttachmentFileChangeHandler         =  this.newAttachmentFileChangeHandler.bind(this);
        this.newAttachmentFileSubmitHandler         =  this.newAttachmentFileSubmitHandler.bind(this);
        this.populateMigrateToAnotherProjectModal   =  this.populateMigrateToAnotherProjectModal.bind(this);
    }


    componentDidMount()
    {
        const authToken = utils.validateIsAuthenticated(this.props);
        if (authToken != false) {
            ApiAxios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
        } else {
            utils.redirectToLogin(this.props);
        }

        this.getTodoDetails();
    }


    getTodoDetails()
    {
        const todoDetailsURL = '/secure/project/todo/details';
        const getParams = {
            todo: this.props.match.params.todoId,
            project: this.props.match.params.projectId,
        };

        this.setState({
            todoDetailsForm: {
                ...this.state.todoDetailsForm,
                bootstrapAlertContent: null,
            },
        });

        ApiAxios.get(todoDetailsURL, {
            params: getParams
        })
            .then((response) => {

                const dueDateArray = response.data.data.t__due_date.split(" ");

                const updatedTodoDetailsFormData = this.state.todoDetailsForm;

                updatedTodoDetailsFormData.fields.title.value = response.data.data.t__title;
                updatedTodoDetailsFormData.fields.priority.value = response.data.data.t__priority;
                updatedTodoDetailsFormData.fields.dueDate.value = dueDateArray[0];

                this.setState({
                    attachmentList: response.data.data.attachmentList,

                    todoDetailsForm: {
                        ...updatedTodoDetailsFormData,
                        isCompletedCheckbox: response.data.data.t__completed_at === null ? false : true,
                        dayPickerSelectedDay: new Date(parseDate(dueDateArray[0], 'YYYY-MM-DD')),
                    },
                });

            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        todoDetailsForm: {
                            ...this.state.todoDetailsForm,
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                        },
                    });
                } else {
                    this.setState({
                        todoDetailsForm: {
                            ...this.state.todoDetailsForm,
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                        },
                    });
                }
            })
            .then(() => {

            });
    }


    inputValueChangeHandler(formEleName, event)
    {
        this.setState({
            todoDetailsForm: {
                ...utils.inputValueChangedHelper(formEleName, event.target.value, this.state.todoDetailsForm)
            }
        });
    }


    isCompletedCheckboxChangeHandler(event)
    {
        this.setState({
            todoDetailsForm: {
                ...this.state.todoDetailsForm,
                isCompletedCheckbox: event.target.checked ? true : false,
            },
        });
    }


    dayPickerDayChangeHandler(selectedDay, modifiers, dayPickerInput)
    {
        const updatedFormData = this.state.todoDetailsForm;
        const updatedFormEle = updatedFormData.fields.dueDate;

        updatedFormEle['value'] = formatDate(selectedDay, 'YYYY-MM-DD'); // event.target.value;
        // updatedFormEle['invalidMessage'] = this.validateInputValue(updatedFormEle['value'], updatedFormEle['validation']);

        let formHasError = false;
        // if (updatedFormEle['invalidMessage'] !== '') {
        //     formHasError = true;
        // }

        updatedFormData.fields.dueDate = updatedFormEle;

        this.setState({
            todoDetailsForm: {
                ...updatedFormData,
                formHasError: formHasError,
                dayPickerSelectedDay: selectedDay,
            },
        });
    }


    validateFormHasErrorBeforeSubmit()
    {
        let formHasError = false;
        for (let eleName in this.state.todoDetailsForm.fields) {
            const element = this.state.todoDetailsForm.fields[eleName];

            const validationResponse = utils.inputValueChangedHelper(eleName, element.value, this.state.todoDetailsForm);
            this.setState({
                todoDetailsForm: {
                    ...validationResponse
                }
            });
            if (validationResponse.formHasError) {
                formHasError = true;
            }
        }

        return formHasError;
    }


    todoDetailsFormSubmitHandler(event)
    {
        event.preventDefault();

        if (this.validateFormHasErrorBeforeSubmit()) {
            return false;
        }

        this.setState({
            todoDetailsForm: {
                ...this.state.todoDetailsForm,
                formSubmitting: true,
                bootstrapAlertContent: null,
            }
        });

        const postFormData = {
            todo: this.props.match.params.todoId,
            title: this.state.todoDetailsForm.fields.title.value,
            priority: parseInt(this.state.todoDetailsForm.fields.priority.value),
            dueDate: this.state.todoDetailsForm.fields.dueDate.value,
            completed: this.state.todoDetailsForm.isCompletedCheckbox ? 1 : 0,
            project: this.props.match.params.projectId,
        };

        ApiAxios.post(this.state.todoDetailsForm.url, postFormData)
            .then((response) => {
                this.setState({
                    todoDetailsForm: {
                        ...this.state.todoDetailsForm,
                        bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, response.data.message),
                    },
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        todoDetailsForm: {
                            ...this.state.todoDetailsForm,
                            ...utils.showValidationErrorAfterFormSubmit(error.response.data, this.state.todoDetailsForm),
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                        },
                    });
                } else {
                    this.setState({
                        todoDetailsForm: {
                            ...this.state.todoDetailsForm,
                            ...utils.showValidationErrorAfterFormSubmit(error.response.data, this.state.todoDetailsForm),
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                        },
                    });
                }
                this.setState({
                    todoDetailsForm: {
                        ...this.state.todoDetailsForm,
                        formHasError: true,
                    },
                });
            })
            .then(() => {
                this.setState({
                    todoDetailsForm: {
                        ...this.state.todoDetailsForm,
                        formSubmitting: false,
                    },
                });
            });
    }


    bootstrapAlertCloseButtonClickHandler(event)
    {
        this.setState({
            todoDetailsForm: {
                ...this.state.todoDetailsForm,
                bootstrapAlertContent: null,
            },
            bootstrapAlertContent: null,
        });
    }


    newAttachmentFileChangeHandler(event)
    {
        this.setState({
            newAttachmentFile: event.target.files[0],
        });
    }


    newAttachmentFileSubmitHandler(event)
    {
        event.preventDefault();

        if (!this.state.newAttachmentFile) {
            alert('No file selected for uploading');
            return false;
        }

        this.setState({
            newAttachmentSubmitting: true,
            bootstrapAlertContent: null,
        });

        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append (
            "todo",
            this.props.match.params.todoId
        );
        formData.append (
            "fileAttachment",
            this.state.newAttachmentFile,
            this.state.newAttachmentFile.name
        );
        formData.append(
            "project",
            this.props.match.params.projectId,
        );

        ApiAxios.post('/secure/project/todo/attachment/upload', formData)
            .then((response) => {
                this.setState({
                    newAttachmentFile: null,
                });
                this.getTodoDetails();
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                    });
                } else {
                    this.setState({
                        bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                    });
                }
                this.setState({
                    newAttachmentHasError: true,
                });
            })
            .then(() => {
                this.setState({
                    newAttachmentFile: null,
                    newAttachmentSubmitting: false,
                });
            });
    }


    populateMigrateToAnotherProjectModal(id, event)
    {
        this.props.populateQuickMigrateTodoToAnotherProjectModal(id);
    }


    render()
    {

        const breadcrumbLinks = (
            <React.Fragment>
                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                <li className="breadcrumb-item"><Link to={"/project/"+this.props.match.params.projectId+"/active-todos"}>Active Todos</Link></li>
                <li className="breadcrumb-item active">Todo Details</li>
            </React.Fragment>
        );


        const attachmentListFields = [];
        let totalUploadedAttachmentCount = this.state.attachmentList.length;
        let oppositeNumbering = totalUploadedAttachmentCount;
        for (let eleName = 1; eleName <= totalUploadedAttachmentCount; eleName++) {
            const element = this.state.attachmentList[eleName-1];
            attachmentListFields.push(
                <div className="form-group" key={element.a__id}>
                    <label>Attachment {oppositeNumbering}: {element.a__created_at}</label>
                    <br />
                    <NavLink to={'/project/'+this.props.match.params.projectId+'/todo/'+element.a__todo_id+'/attachment/'+element.a__id+'/view-attachment-file'} target="_blank">{element.a__original_file_name}</NavLink>
                </div>
            );
            oppositeNumbering--;
        }


        return (
            <CommonPageLayout breadcrumbLinks={breadcrumbLinks} pageTitle="Manage Todo Details">

                <MigrateProjectComponent projectId={this.props.match.params.projectId} comingFrom="todo-details-page" />

                <div className="row">
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title mb-4">Todo Details</h4>

                                { this.state.todoDetailsForm.bootstrapAlertContent }

                                <form onSubmit={this.todoDetailsFormSubmitHandler}>
                                    <div className="row">
                                        <div className="col-md-5 col-lg-4 col-xl-3">
                                            <div className="form-group form-check">
                                                <input
                                                    type="checkbox"
                                                    checked={this.state.todoDetailsForm.isCompletedCheckbox}
                                                    className="form-check-input"
                                                    onChange={this.isCompletedCheckboxChangeHandler.bind(this)} />
                                                <label className="form-check-label">Is Completed</label>
                                            </div>
                                        </div>
                                        <div className="col-md-5 col-lg-4 col-xl-3" style={{marginBottom: '1rem'}}>
                                            <SubmitButton
                                                label="Update Todo Details"
                                                formHasError={this.state.todoDetailsForm.formHasError}
                                                isSubmitting={this.state.todoDetailsForm.formSubmitting} />
                                        </div>
                                        <div className="col-md-5 col-lg-4 col-xl-3" style={{color:'white'}}>
                                            <a className="btn btn-warning btn-block waves-effect waves-light"
                                               onClick={this.populateMigrateToAnotherProjectModal.bind(this, this.props.match.params.todoId)}
                                               data-toggle="modal" data-target="#migrateToAnotherProjectModal">Migrate to another project</a>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5 col-lg-4 col-xl-3">
                                            <FormGroupInput
                                                key={this.state.todoDetailsForm.fields.priority}
                                                name={this.state.todoDetailsForm.fields.priority}
                                                elementType={this.state.todoDetailsForm.fields.priority.elementType}
                                                value={this.state.todoDetailsForm.fields.priority.value}
                                                label={this.state.todoDetailsForm.fields.priority.label}
                                                customStyle={this.state.todoDetailsForm.fields.priority.customStyle}
                                                invalidMessage={this.state.todoDetailsForm.fields.priority.invalidMessage}
                                                elementConfig={this.state.todoDetailsForm.fields.priority.elementConfig}
                                                changeHandler={this.inputValueChangeHandler.bind(this, 'priority')} />
                                        </div>
                                        <div className="col-md-5 col-lg-4 col-xl-3">
                                            <div className="form-group">
                                                <label htmlFor="dueDate">* Due Date</label>
                                                <DayPickerInput
                                                    style={{display: 'block'}}
                                                    inputProps={{className: 'form-control', readOnly: true,}}
                                                    dayPickerProps={{disabledDays: {before: new Date()},}}
                                                    hideOnDayClick={true}
                                                    onDayChange={this.dayPickerDayChangeHandler}
                                                    formatDate={formatDate}
                                                    parseDate={parseDate}
                                                    placeholder={`${formatDate(new Date(), 'MMM Do, YYYY')}`}
                                                    selectedDays={this.state.todoDetailsForm.dayPickerSelectedDay}
                                                    value={`${formatDate(this.state.todoDetailsForm.dayPickerSelectedDay, 'MMM Do, YYYY')}`} />
                                            </div>
                                        </div>
                                    </div>

                                    <FormGroupInput
                                        key={this.state.todoDetailsForm.fields.title}
                                        name={this.state.todoDetailsForm.fields.title}
                                        elementType={this.state.todoDetailsForm.fields.title.elementType}
                                        value={this.state.todoDetailsForm.fields.title.value}
                                        label={this.state.todoDetailsForm.fields.title.label}
                                        customStyle={this.state.todoDetailsForm.fields.title.customStyle}
                                        invalidMessage={this.state.todoDetailsForm.fields.title.invalidMessage}
                                        elementConfig={this.state.todoDetailsForm.fields.title.elementConfig}
                                        changeHandler={this.inputValueChangeHandler.bind(this, 'title')} />
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title mb-4">Todo Attachments</h4>

                                { this.state.bootstrapAlertContent }

                                {
                                    (3 - totalUploadedAttachmentCount) > 0 ?
                                        <form onSubmit={this.newAttachmentFileSubmitHandler}>
                                            <div className="form-group">
                                                <label for="exampleFormControlFile1">Upload Attachment</label>
                                                <input type="file" className="form-control-file" id="exampleFormControlFile1" onChange={this.newAttachmentFileChangeHandler} />
                                            </div>

                                            <div className="col-sm-12 col-lg-8 col-xl-6" style={{paddingLeft:'0rem'}}>
                                                <SubmitButton
                                                    label="Upload Attachment"
                                                    formHasError={this.state.newAttachmentHasError}
                                                    isSubmitting={this.state.newAttachmentSubmitting} />
                                            </div>
                                        </form>
                                        : null
                                }

                                <small className="form-text text-muted">You can upload upto 3 attachments per todo. Attachment file size cannot be more than 5MB.</small>
                                <small className="form-text text-muted">You can upload <b>{(3 - totalUploadedAttachmentCount)} more</b> attachment(s).</small>

                                <hr />
                                {
                                    (totalUploadedAttachmentCount > 0) ? attachmentListFields : <p>No attachments uploaded yet</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </CommonPageLayout>
        );
    }
}


// Redux State Management
const mapStateToProps = (curState) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addInterPageMessage: (message, messageType) => {
            return dispatch({type: actionTypes.INTER_PAGE_MESSAGE_ADDED, message: message, messageType: messageType});
        },
        populateQuickMigrateTodoToAnotherProjectModal: (id) => {
            return dispatch({type: actionTypes.QUICK_MIGRATE_TODO_PROJECT, id: id})
        },
    };
};


// Export Components
export default connect(mapStateToProps, mapDispatchToProps)(TodoDetails);