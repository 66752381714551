// Import React, Supporting Libraries
import React from 'react';


// Import Css Module
import classes from './Footer.module.css';


// Functional Component
const footer = (props) => {

    const currentYear = new Date().getFullYear();

    return (

        <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6">
                        © { currentYear } EsyTodo
                    </div>
                    <div className="col-sm-6">
                        <div className="text-sm-right d-none d-sm-block">
                            Design & Develop by Aditya Sharma
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}


// Export Components
export default footer;