// Import React, Support Libraries
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import appReducer from './esytodo_frontend_skote/vendors/redux/state/reducer';


// Import CSS
import './index.css';


// Import Main App Component
// Desktop Screens
import DesktopApp from './esytodo_frontend_skote/App';
// Mobile Screens
import MobileApp from './esytodo_frontend_bootstrap/App';


let App = DesktopApp;


// Redux app-state
const appStore = createStore(appReducer);


var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};
if (isMobile.any()) {
    // App = MobileApp;
}

// Render React on Webpage
ReactDOM.render(
    <React.StrictMode>
        <Provider store={appStore}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)