// Import React, Supporting Libraries
import React from 'react';
import { connect } from 'react-redux';


// Other Components
import ApiAxios from '../../../vendors/axios/api';
import SubmitButton from '../../../components/form/SubmitButton/SubmitButton';
import * as utils from '../../../utils/utils';
import * as actionTypes from "../../../vendors/redux/state/actions";


// Class Component
class ChangePassword extends React.Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            changePasswordForm: {
                url: '/secure/my-profile/change-password',

                fields: {
                    currentPassword: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'password',
                            placeholder: 'Your current password',
                            required: true,
                            minLength: 5,
                            maxLength: 20,
                        },
                        isPasswordField: true,
                        value: '',
                        label: 'Current Password',
                        validation: {
                            required: true,
                            minLength: 5,
                            maxLength: 20,
                        },
                        invalidMessage: '',
                    },
                    password: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'password',
                            placeholder: 'Your new password',
                            required: true,
                            minLength: 5,
                            maxLength: 20,
                        },
                        isPasswordField: true,
                        value: '',
                        label: 'New Password',
                        validation: {
                            required: true,
                            minLength: 5,
                            maxLength: 20,
                        },
                        invalidMessage: '',
                    },
                    password_confirmation: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'password',
                            placeholder: 'Retype your new password to confirm it',
                            required: true,
                            minLength: 5,
                            maxLength: 20,
                        },
                        isPasswordField: true,
                        value: '',
                        label: 'Retype New Password',
                        validation: {
                            required: true,
                            minLength: 5,
                            maxLength: 20,
                        },
                        invalidMessage: '',
                    },
                },

                formHasError: false,
                formSubmitting: false,

                bootstrapAlertContent: null,
            },
        };

        // Regular Functions
        this.validateFormHasErrorBeforeSubmit       =  this.validateFormHasErrorBeforeSubmit.bind(this);

        // Event Handlers
        this.inputValueChangeHandler                =  this.inputValueChangeHandler.bind(this);
        this.changePasswordFormSubmitHandler        =  this.changePasswordFormSubmitHandler.bind(this);
        this.bootstrapAlertCloseButtonClickHandler  =  this.bootstrapAlertCloseButtonClickHandler.bind(this);
        this.passwordShowButtonClickHandler         =  this.passwordShowButtonClickHandler.bind(this);
    }


    componentDidMount()
    {
        const authToken = utils.validateIsAuthenticated(this.props);
        if (authToken != false) {
            ApiAxios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
        } else {
            utils.redirectToLogin(this.props);
        }
    }


    inputValueChangeHandler(formEleName, event)
    {
        this.setState({
            changePasswordForm: {
                ...utils.inputValueChangedHelper(formEleName, event.target.value, this.state.changePasswordForm)
            }
        });
    }


    validateFormHasErrorBeforeSubmit()
    {
        let formHasError = false;
        for (let eleName in this.state.changePasswordForm.fields) {
            const element = this.state.changePasswordForm.fields[eleName];

            const validationResponse = utils.inputValueChangedHelper(eleName, element.value, this.state.changePasswordForm);
            this.setState({
                changePasswordForm: {
                    ...validationResponse
                }
            });
            if (validationResponse.formHasError) {
                formHasError = true;
            }
        }

        return formHasError;
    }


    changePasswordFormSubmitHandler(event)
    {
        event.preventDefault();

        if (this.validateFormHasErrorBeforeSubmit()) {
            return false;
        }

        this.setState({
            changePasswordForm: {
                ...this.state.changePasswordForm,
                formSubmitting: true,
            }
        });

        const postFormData = {
            currentPassword: this.state.changePasswordForm.fields.currentPassword.value,
            password: this.state.changePasswordForm.fields.password.value,
            password_confirmation: this.state.changePasswordForm.fields.password_confirmation.value,
        };

        ApiAxios.post(this.state.changePasswordForm.url, postFormData)
            .then((response) => {
                this.props.addInterPageMessage(response.data.message, 'success');
                this.props.clearAuthToken();
                ApiAxios.post('/secure/my-profile/logout');

                utils.redirectToLogin(this.props);
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        changePasswordForm: {
                            ...this.state.changePasswordForm,
                            ...utils.showValidationErrorAfterFormSubmit(error.response.data, this.state.changePasswordForm),
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                        },
                    });

                } else {
                    this.setState({
                        changePasswordForm: {
                            ...this.state.changePasswordForm,
                            ...utils.showValidationErrorAfterFormSubmit(error.response.data, this.state.changePasswordForm),
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                        },
                    });
                }
                this.setState({
                    changePasswordForm: {
                        ...this.state.changePasswordForm,
                        formHasError: true,
                    },
                });
            })
            .then(() => {
                this.setState({
                    changePasswordForm: {
                        ...this.state.changePasswordForm,
                        formSubmitting: false,
                    },
                });
            });
    }


    bootstrapAlertCloseButtonClickHandler(event)
    {
        this.setState({
            changePasswordForm: {
                ...this.state.changePasswordForm,
                bootstrapAlertContent: null,
            },
        });
    }


    passwordShowButtonClickHandler(formEleName, event)
    {
        const changePasswordForm = this.state.changePasswordForm;
        if (this.state.changePasswordForm.fields[formEleName].elementConfig.type == 'password') {
            changePasswordForm.fields[formEleName].elementConfig.type = 'text';
        } else {
            changePasswordForm.fields[formEleName].elementConfig.type = 'password';
        }
        this.setState({
            changePasswordForm: {
                ...changePasswordForm,
            }
        })
    }


    render()
    {

        return (
            <form onSubmit={this.changePasswordFormSubmitHandler}>
                { this.state.changePasswordForm.bootstrapAlertContent }

                { utils.renderFormGroupComponentHelper(this.state.changePasswordForm.fields, this.inputValueChangeHandler, this.passwordShowButtonClickHandler) }

                <div className="form-group row justify-content-end">
                    <div className="col-sm-6">
                        <SubmitButton
                            label="Update Password"
                            formHasError={this.state.changePasswordForm.formHasError}
                            isSubmitting={this.state.changePasswordForm.formSubmitting} />
                    </div>
                </div>
            </form>
        );
    }

}


// Redux State Management
const mapStateToProps = (curState) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addInterPageMessage: (message, messageType) => {
            return dispatch({type: actionTypes.INTER_PAGE_MESSAGE_ADDED, message: message, messageType: messageType});
        },
        clearAuthToken: () => {
            return dispatch({type: actionTypes.LOGOUT})
        },
    };
};


// Export Components
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);