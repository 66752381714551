// Import React, Supporting Libraries
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';


// Other Components
import CommonPageLayout from '../../../components/auth_only/CommonPageLayout/CommonPageLayout';
import ApiAxios from '../../../vendors/axios/api';
import * as utils from '../../../utils/utils';
import TodoFilters from '../../../components/auth_only/TodoFilters/TodoFilters';
import TodoList from "../../../components/auth_only/TodoList/TodoList";
import * as actionTypes from "../../../vendors/redux/state/actions";
import QuickEditTodoComponent from "../../../containers/auth_only/QuickEditTodo/QuickEditTodo";
// import MigrateProjectComponent from "../../../containers/auth_only/MigrateProject/MigrateProject";
import AddTodo from "../../../containers/auth_only/AddTodo/AddTodo";
import GenericQuickActionButtons from "../../../components/auth_only/GenericQuickActionButtons/GenericQuickActionButtons";


// Class Component
class AllOverdueTodoList extends React.Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            overdueTodoListBootstrapAlertContent: null,

            overdueTodoList: {},

            overdueTodoListPagination: {
                resultsPerPage: 12,
                totalCount: 0,
                noOfPages: 1,
                currentPage: 1,
            },

            overdueTodoListFilters: {
                filterPriority: 3, // 1,2,3,4,5
                filterViewAll: true,

                localSearchString: '',
                localSearchErrorString: '',
            },
        };

        // Event Handlers
        this.filterViewAllChangeHandler             =  this.filterViewAllChangeHandler.bind(this);
        this.filterPriorityClickHandler             =  this.filterPriorityClickHandler.bind(this);
        this.paginatorClickHandler                  =  this.paginatorClickHandler.bind(this);
        this.resultsPerPageChangeHandler            =  this.resultsPerPageChangeHandler.bind(this);
        this.isCompletedCheckboxChangeHandler       =  this.isCompletedCheckboxChangeHandler.bind(this);
        this.bootstrapAlertCloseButtonClickHandler  =  this.bootstrapAlertCloseButtonClickHandler.bind(this);
        this.localSearchStringChangeHandler         =  this.localSearchStringChangeHandler.bind(this);
        this.localSearchFormSubmitHandler           =  this.localSearchFormSubmitHandler.bind(this);
        // this.todoMigratedEventHandler               =  this.todoMigratedEventHandler.bind(this);
        this.todoQuickUpdatedEventHandler           =  this.todoQuickUpdatedEventHandler.bind(this);
    }


    componentDidMount()
    {
        const authToken = utils.validateIsAuthenticated(this.props);
        if (authToken != false) {
            ApiAxios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
        } else {
            utils.redirectToLogin(this.props);
        }

        this.getOverdueTodoList();
    }


    componentDidUpdate(prevProps, prevState, snapshot)
    {
        // prevProps.newTodoGotAdded !== this.props.newTodoGotAdded
        if (this.props.newTodoGotAdded === true) {
            this.getOverdueTodoList();
        }
    }


    getOverdueTodoList(todoListFilters = null, todoListPagination = null)
    {
        if (!todoListFilters) {
            todoListFilters = this.state.overdueTodoListFilters;
        }

        if (!todoListPagination) {
            todoListPagination = this.state.overdueTodoListPagination;
        }

        this.setState({
            overdueTodoListBootstrapAlertContent: null,
        });

        let getParams = {
            filterPriority: todoListFilters.filterPriority,
        };
        if (todoListFilters.filterViewAll) {
            getParams = {};
        }

        if (todoListFilters.showCompletedTodos) {
            getParams = {
                ...getParams,
                filterIncludeCompleted: 1,
            };
        }

        getParams = {
            ...getParams,
            pageNo: todoListPagination.currentPage,
            resultPerPage: todoListPagination.resultsPerPage,
        }

        if (todoListFilters.localSearchString != '') {
            getParams = {
                ...getParams,
                titleSearch: todoListFilters.localSearchString,
            }
        }

        ApiAxios.get('/secure/project/todo/get-all-todos/deadline-overdue', {
            params: getParams
        })
            .then((response) => {
                this.setState({
                    overdueTodoList: response.data.data,
                    overdueTodoListPagination: {
                        ...this.state.overdueTodoListPagination,
                        totalCount: response.data.pagination.totalCount ? response.data.pagination.totalCount : 0,
                        noOfPages: response.data.pagination.noOfPages ? response.data.pagination.noOfPages : 1,
                    },
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        overdueTodoListBootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                    });
                } else {
                    this.setState({
                        overdueTodoListBootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                    });
                }
            })
            .then(() => {

            });
    }


    bootstrapAlertCloseButtonClickHandler(bootstrapAlertContentStateName, event)
    {
        this.setState({
            [bootstrapAlertContentStateName]: null,
        });
    }


    filterViewAllChangeHandler(todoListFiltersStateName, event)
    {
        const todoListFilters = this.state[todoListFiltersStateName];
        todoListFilters['filterViewAll'] = event.target.checked;

        this.setState({
            [todoListFiltersStateName]: {
                ...todoListFilters,
            },
        });

        this.getOverdueTodoList(todoListFilters);
    }


    filterPriorityClickHandler(todoListFiltersStateName, todoListPaginationStateName, inValue)
    {
        const todoListFilters = this.state[todoListFiltersStateName];
        todoListFilters['filterPriority'] = inValue;

        const todoListPagination = this.state[todoListPaginationStateName];
        todoListPagination.currentPage = 1;

        this.setState({
            [todoListFiltersStateName]: {
                ...todoListFilters,
            },
        });

        this.getOverdueTodoList(todoListFilters, todoListPagination);
    }


    paginatorClickHandler(todoListPaginationStateName, isNextClick = false)
    {
        const todoListPagination = this.state[todoListPaginationStateName];

        if (isNextClick) {
            if (todoListPagination.currentPage === todoListPagination.noOfPages) {
                return false;
            }
            todoListPagination.currentPage++;
        } else {
            if (todoListPagination.currentPage === 1) {
                return false;
            }
            todoListPagination.currentPage--;
        }

        this.setState({
            [todoListPaginationStateName]: {
                ...todoListPagination,
            },
        });

        if (todoListPaginationStateName === 'overdueTodoListPagination') {
            // this.getOverdueTodoList(null, todoListPagination);
        }

        this.getOverdueTodoList(null, todoListPagination);
    }


    resultsPerPageChangeHandler(todoListPaginationStateName, newValue)
    {
        const todoListPagination = this.state[todoListPaginationStateName];
        todoListPagination.resultsPerPage = newValue;
        todoListPagination.currentPage = 1;

        this.setState({
            [todoListPaginationStateName]: todoListPagination,
        });

        if (todoListPaginationStateName === 'overdueTodoListPagination') {
            // this.getOverdueTodoList(null, todoListPagination);
        }

        this.getOverdueTodoList(null, todoListPagination);
    }


    isCompletedCheckboxChangeHandler(todoListStateName, todoId, event, projectId)
    {
        const postData = {
            todo: todoId,
            completed: event.target.checked ? 1 : 0,
            project: projectId,
        };

        this.setState({
            bootstrapAlertContent: null,
        });

        ApiAxios.post('/secure/project/todo/update/completed', postData)
            .then((response) => {
                if (todoListStateName === 'overdueTodoList') {
                    // this.getOverdueTodoList();
                }
                this.getOverdueTodoList();
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                    });
                } else {
                    this.setState({
                        bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                    });
                }
            })
            .then(() => {

            });
    }


    localSearchStringChangeHandler(todoListFiltersStateName, event)
    {
        const searchString = event.target.value;

        let errorMessage = '';
        if (searchString != '' && searchString.length < 3) {
            errorMessage = 'Please ensure search string is more than 2 chars in length';
        }
        if (searchString.length > 15) {
            errorMessage = 'Please ensure search string is less than 16 chars in length';
        }

        const updatedFilter = this.state[todoListFiltersStateName];
        updatedFilter['localSearchString'] = searchString;
        updatedFilter['localSearchErrorString'] = errorMessage;

        if (searchString === '') {
            updatedFilter['localSearchString'] = '';
            updatedFilter['localSearchErrorString'] = '';
        }

        this.setState({
            [todoListFiltersStateName]: {
                ...updatedFilter,
            },
        });

        if (searchString === '') {
            if (todoListFiltersStateName === 'overdueTodoListFilters') {
                // this.getOverdueTodoList(updatedFilter, null);
            }
            this.getOverdueTodoList(updatedFilter, null);
        }
    }


    localSearchFormSubmitHandler(todoListFiltersStateName, event)
    {
        event.preventDefault();

        const searchString = this.state[todoListFiltersStateName].localSearchString;

        let errorMessage = '';
        if (searchString != '' && searchString.length < 3) {
            errorMessage = 'Please ensure search string is more than 2 chars in length';
        }
        if (searchString.length > 15) {
            errorMessage = 'Please ensure search string is less than 16 chars in length';
        }

        const updatedFilter = this.state[todoListFiltersStateName];
        updatedFilter['localSearchString'] = searchString;
        updatedFilter['localSearchErrorString'] = errorMessage;

        this.setState({
            [todoListFiltersStateName]: {
                ...updatedFilter,
            },
        });

        if (errorMessage != '') {
            if (todoListFiltersStateName === 'overdueTodoListFilters') {
                // this.setState({
                //     overdueTodoListBootstrapAlertContent: errorMessage,
                // });
            }
            this.setState({
                overdueTodoListBootstrapAlertContent: errorMessage,
            });

            return false;
        }

        if (todoListFiltersStateName === 'overdueTodoListFilters') {
            // this.getOverdueTodoList(updatedFilter, null);
        }
        this.getOverdueTodoList(updatedFilter, null);
    }


    // todoMigratedEventHandler(todoId, event)
    // {
    //     this.getOverdueTodoList();
    //     // this.getOverdueTodoList();
    // }


    todoQuickUpdatedEventHandler(todoId, event)
    {
        this.getOverdueTodoList();
        // this.getOverdueTodoList();
    }


    render()
    {

        const breadcrumbLinks = (
            <React.Fragment>
                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                <li className="breadcrumb-item overdue">All Overdue Todos</li>
            </React.Fragment>
        );

        return (
            <CommonPageLayout breadcrumbLinks={breadcrumbLinks} pageTitle="All Overdue Todos">

                {/*<MigrateProjectComponent projectId={this.props.match.params.projectId} comingFrom="overdueTodoList" todoMigratedEventHandler={this.todoMigratedEventHandler} />*/}
                <QuickEditTodoComponent comingFrom="overdueTodoList" todoQuickUpdatedEventHandler={this.todoQuickUpdatedEventHandler} />

                <div className="row">
                    <div className="col-12">
                        <div className="email-leftbar card">

                            <div style={{marginBottom:'3rem', color:'white'}}>
                                <GenericQuickActionButtons currentPage="allOverdueTodoList" />
                            </div>

                            <TodoFilters
                                showCompletedTodosCheckbox={false}
                                showViewAllCheckbox
                                showDateFilter={false}
                                showPriorityFilter
                                filters={this.state.overdueTodoListFilters}
                                showCompletedTodosChangeHandler={this.showCompletedTodosChangeHandler}
                                filterViewAllChangeHandler={this.filterViewAllChangeHandler}
                                filterPriorityClickHandler={this.filterPriorityClickHandler}
                                todoListFiltersStateName="overdueTodoListFilters"
                                todoListPaginationStateName="overdueTodoListPagination" />
                        </div>

                        <div className="email-rightbar mb-3">
                            <TodoList
                                allTodosPage
                                listTitle="All Overdue Todos"
                                showLocalSearch
                                todoListStateName="overdueTodoList"
                                filters={this.state.overdueTodoListFilters}
                                todoListPagination={this.state.overdueTodoListPagination}
                                todoList={this.state.overdueTodoList}
                                bootstrapAlertContent={this.state.overdueTodoListBootstrapAlertContent}
                                paginatorClickHandler={this.paginatorClickHandler}
                                resultsPerPageChangeHandler={this.resultsPerPageChangeHandler}
                                todoListPaginationStateName="overdueTodoListPagination"
                                todoListFilterStateName="overdueTodoListFilters"
                                localSearchStringChangeHandler={this.localSearchStringChangeHandler}
                                localSearchFormSubmitHandler={this.localSearchFormSubmitHandler}
                                isCompletedCheckboxChangeHandler={this.isCompletedCheckboxChangeHandler} />
                        </div>

                    </div>
                </div>

                <AddTodo projectId={this.props.match.params.projectId} />
            </CommonPageLayout>
        );
    }
}



// Redux State Management
const mapStateToProps = (curState) => {
    return {
        newTodoGotAdded: curState.newTodoGotAdded,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addInterPageMessage: (message, messageType) => {
            return dispatch({type: actionTypes.INTER_PAGE_MESSAGE_ADDED, message: message, messageType: messageType});
        },
    };
};


// Export Components
export default connect(mapStateToProps, mapDispatchToProps)(AllOverdueTodoList);