// Import React, Supporting Libraries
import React from 'react';
import { NavLink } from 'react-router-dom';


// Import Css Module
import './ProjectList.css';


// Other Components
import * as utils from '../../../utils/utils';
import * as actionTypes from "../../../vendors/redux/state/actions";
import {connect} from "react-redux";


// Functional Component
const projectList = (props) => {

    function populateEditModal(id, title, description, event)
    {
        props.populateUpdateProjectModal(id, title, description);
    }


    function displayProjects()
    {
        const projectListArray = [];

        for (let projectKey in props.projectList) {
            const projectEle = props.projectList[projectKey];

            let label = <NavLink className="" to={'/project/'+projectEle.p__id+'/active-todos'}>{utils.getProjectDisplpayTitle(projectEle.p__title)}</NavLink>


            projectListArray.push(
                <div className="col-xl-3 col-lg-4 col-sm-6 mb-3" key={projectEle.p__id}>
                    <div className="card h-100" style={{backgroundColor:'antiquewhite',borderRadius:'8px'}}>
                        <div className="card-body" style={{padding:'1rem 1rem 0'}}>
                            <div className="d-flex flex-wrap">
                                <div className="dropdown ml-auto">
                                    <a className="text-muted dropdown-toggle font-size-16" role="button"
                                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="mdi mdi-dots-vertical"></i>
                                    </a>

                                    <div className="dropdown-menu dropdown-menu-right">
                                        <a className="dropdown-item" onClick={populateEditModal.bind(this, projectEle.p__id, projectEle.p__title, projectEle.p__description)} data-toggle="modal" data-target="#updateProjectModal">Edit Details</a>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom:'5px'}}>
                                <div className="col-6">
                                    Created On:
                                </div>
                                <div className="col-6">
                                    {utils.returnJustDate(projectEle.p__created_at)}
                                </div>
                            </div>
                            <hr />
                            <div className="media-body overflow-hidden">
                                { label }
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <div className="row" style={{padding:'0 12px'}}>
                    {projectListArray}
                </div>
            </React.Fragment>
        );
    }


    return (
        <div className="card" style={{width:'100%'}}>
            {
                props.listTitle ?
                    <div className="row" style={{width:'100%'}}>
                        <div className="col-sm-5">
                            <h5 style={{padding:'1.2rem'}}>{props.listTitle}</h5>
                        </div>
                    </div>
                    : null
            }

            {
                props.bootstrapAlertContent ?
                    <div className="row-col-12" style={{marginLeft:'1.2rem', marginRight:'1.2rem'}}>
                        { props.bootstrapAlertContent }
                    </div>
                    : null
            }

            {
                props.projectList ?
                    props.projectList.length > 0 ?
                        displayProjects()
                        : <p style={{paddingLeft:'1.2rem'}}>No projects to display</p>
                    : <p style={{paddingLeft:'1.2rem'}}>No projects to display</p>
            }
        </div>
    );
};


// Redux State Management
const mapStateToProps = (curState) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        populateUpdateProjectModal: (id, title, description) => {
            return dispatch({type: actionTypes.QUICK_EDIT_PROJECT, id: id, title: title, description: description})
        },
    };
};


// Export Components
export default connect(mapStateToProps, mapDispatchToProps)(projectList);