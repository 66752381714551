// Import React, Supporting Libraries
import React from 'react';
import { connect } from 'react-redux';

// Other Components
import CommonPageLayout from '../../../components/auth_only/CommonPageLayout/CommonPageLayout';
import ApiAxios from '../../../vendors/axios/api';
import * as utils from '../../../utils/utils';
// import TodoFilters from '../../../components/auth_only/TodoFilters/TodoFilters';
// import TodoList from "../../../components/auth_only/TodoList/TodoList";
import ProjectList from "../../../components/auth_only/ProjectList/ProjectList";
import * as actionTypes from "../../../vendors/redux/state/actions";
// import {NavLink} from "react-router-dom";
import GenericQuickActionButtons from "../../../components/auth_only/GenericQuickActionButtons/GenericQuickActionButtons";


// Class Component
class Dashboard extends React.Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            projectListBootstrapAlertContent: null,
            recentProjectListBootstrapAlertContent: null,

            projectList: {},
            recentProjectList: {},

            projectListPagination: {
                resultsPerPage: 50,
                totalCount: 0,
                noOfPages: 1,
                currentPage: 1,
            },
        };

        // Regular Functions
        this.getProjectList                         =  this.getProjectList.bind(this);
        this.getRecentProjectList                   =  this.getRecentProjectList.bind(this);

        // Event Handlers
        this.bootstrapAlertCloseButtonClickHandler  =  this.bootstrapAlertCloseButtonClickHandler.bind(this);
    }


    componentDidMount()
    {
        const authToken = utils.validateIsAuthenticated(this.props);
        if (authToken != false) {
            ApiAxios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
        } else {
            utils.redirectToLogin(this.props);
        }

        this.getRecentProjectList();
        this.getProjectList();
    }


    componentDidUpdate(prevProps, prevState, snapshot)
    {
        // prevProps.newTodoGotAdded !== this.props.newTodoGotAdded
        if (this.props.newProjectGotCreated === true) {
            this.getRecentProjectList();
            this.getProjectList();

            this.props.processNewProject();
        }

        if (this.props.projectGotUpdated === true) {
            this.getRecentProjectList();
            this.getProjectList();

            this.props.processNewProject();
        }
    }


    getProjectList()
    {
        const projectListPagination = this.state.projectListPagination;
        const getParams = {
            pageNo: projectListPagination.currentPage,
            resultPerPage: projectListPagination.resultsPerPage,
        };

        const getUrl = '/secure/project/list';

        this.setState({
            projectListBootstrapAlertContent: null,
        });

        ApiAxios.get(getUrl, {
            params: getParams
        })
            .then((response) => {
                this.setState({
                    projectList: response.data.data,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        projectListBootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                    });
                } else {
                    this.setState({
                        projectListBootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                    });
                }
            })
            .then(() => {

            });
    }


    getRecentProjectList()
    {
        const getUrl = '/secure/project/list/recently-accessed';

        this.setState({
            recentProjectListBootstrapAlertContent: null,
        });

        ApiAxios.get(getUrl)
            .then((response) => {
                this.setState({
                    recentProjectList: response.data.data,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        recentProjectListBootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                    });
                } else {
                    this.setState({
                        recentProjectListBootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                    });
                }
            })
            .then(() => {

            });
    }


    bootstrapAlertCloseButtonClickHandler(bootstrapAlertContentStateName, event)
    {
        this.setState({
            [bootstrapAlertContentStateName]: null,
        });
    }


    render()
    {
        const breadcrumbLinks = (
            <React.Fragment>
                <li className="breadcrumb-item active">Dashboard</li>
            </React.Fragment>
        );

        return (
            <CommonPageLayout breadcrumbLinks={breadcrumbLinks} pageTitle="Dashboard">
                <div className="row">
                    <div className="col-12">
                        <div className="email-leftbar card">
                            <GenericQuickActionButtons currentPage="dashboard" />
                        </div>

                        <div className="email-rightbar mb-3">
                            <ProjectList
                                listTitle="Recently Viewed"
                                projectList={this.state.recentProjectList}
                                bootstrapAlertContent={this.state.recentProjectListBootstrapAlertContent} />

                            <ProjectList
                                listTitle="All Projects"
                                projectList={this.state.projectList}
                                bootstrapAlertContent={this.state.projectListBootstrapAlertContent} />
                        </div>
                    </div>
                </div>
            </CommonPageLayout>
        );
    }
}


// Redux State Management
const mapStateToProps = (curState) => {
    return {
        newProjectGotCreated: curState.newProjectGotCreated,
        projectGotUpdated: curState.projectGotUpdated,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addInterPageMessage: (message, messageType) => {
            return dispatch({type: actionTypes.INTER_PAGE_MESSAGE_ADDED, message: message, messageType: messageType});
        },
        processNewProject: () => {
            return dispatch({type: actionTypes.NEW_PROJECT_GOT_PROCESSED})
        },
    };
};


// Export Components
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);