// Import React, Supporting Libraries
import React from 'react';
import { connect } from 'react-redux';
import FormGroupInput from "../../../components/form/FormGroupInput/FormGroupInput";
import SubmitButton from "../../../components/form/SubmitButton/SubmitButton";
import * as utils from "../../../utils/utils";
import ApiAxios from "../../../vendors/axios/api";
import * as $AB from 'jquery';
import * as actionTypes from "../../../vendors/redux/state/actions";


// Class Component
class MigrateProject extends React.Component
{

    constructor(props)
    {
        super(props);

        this.state = {

            projectDetails: {},
            projectDetailsBootstrapAlertContent: null,

            projectList: {},
            projectListBootstrapAlertContent: null,

            migrateToProjectForm: {
                formSubmitting: false,
                bootstrapAlertContent: null,
            },

            newProjectId: null,
        };


        // Regular Functions
        this.getDropdownValuesFromList              =  this.getDropdownValuesFromList.bind(this);

        // Event Handlers
        this.migrateProjectChangeHandler            =  this.migrateProjectChangeHandler.bind(this);
        this.migrateProjectFormSubmitHandler        =  this.migrateProjectFormSubmitHandler.bind(this);
        this.bootstrapAlertCloseButtonClickHandler  =  this.bootstrapAlertCloseButtonClickHandler.bind(this);

        this.props.populateQuickMigrateTodoToAnotherProjectModalOpened();
    }


    componentDidMount()
    {
        const authToken = utils.validateIsAuthenticated(this.props);
        if (authToken != false) {
            ApiAxios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
        } else {
            utils.redirectToLogin(this.props);
        }

        this.getProjectDetails(this.props.projectId);

        this.getProjectList();

        // $(document).on("click", ".migrate-to-another-project-modal", function (event) {
        //     const button = $(event.target); // Button that triggered the modal
        //
        //     const projectId = button.data('project'); // Extract info from data-* attributes
        //     const todoId = button.data('todo'); // Extract info from data-* attributes
        //
        //     // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
        //     // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
        //     const modal = $("#migrateToAnotherProjectModal");
        //     modal.find(".modal-project").val(projectId);
        //     modal.find(".modal-todo").val(todoId);
        // });
    }


    getProjectDetails(projectId)
    {
        let getParams = {
            project: projectId,
        };

        ApiAxios.get('/secure/project/details', {
            params: getParams
        })
            .then((response) => {
                this.setState({
                    projectDetails: response.data.data,
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        projectDetailsBootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                    });
                } else {
                    this.setState({
                        projectDetailsBootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                    });
                }
            })
            .then(() => {

            });
    }


    getProjectList()
    {
        const projectListPagination = this.state.projectListPagination;
        const getParams = {
            pageNo: 1,
            resultPerPage: 50,
        };

        const getUrl = '/secure/project/list';

        this.setState({
            // migrateToProjectForm: {
            //     ...this.state.migrateToProjectForm,
            //     bootstrapAlertContent: null,
            // },
            projectListBootstrapAlertContent: null,
        });

        ApiAxios.get(getUrl, {
            params: getParams
        })
            .then((response) => {
                this.setState({
                    projectList: response.data.data,
                });
                this.getDropdownValuesFromList();
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        projectListBootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                    });
                } else {
                    this.setState({
                        projectListBootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                    });
                }
            })
            .then(() => {

            });
    }


    migrateProjectFormSubmitHandler(event)
    {
        event.preventDefault();

        // const projectId  =  $("#migrateToAnotherProjectModal .modal-project").val();
        // const todoId     =  $("#migrateToAnotherProjectModal .modal-todo").val();

        window.$('#migrateToAnotherProjectModal').modal('hide');

        if (!this.state.newProjectId) {
            alert('First please select a project you would like to migrate your todo to before clicking on submit button');
            return false;
        }

        this.setState({
            migrateToProjectForm: {
                ...this.state.migrateToProjectForm,
                formSubmitting: true,
                bootstrapAlertContent: null,
            }
        });

        let postFormData = {
            project: this.props.projectId,
            todo: this.props.quickMigrateTodoId,
            migrateToProject: this.state.newProjectId,
        };

        ApiAxios.post('/secure/project/todo/migrate-to-project', postFormData)
            .then((response) => {

                if (this.props.comingFrom == 'todo-details-page') {
                    window.location.replace('/project/'+this.state.newProjectId+'/todo/'+this.props.quickMigrateTodoId+'/details');
                }
                else if (this.props.comingFrom == 'activeTodoList') {
                    // if todo is from active Todo List then set that
                    // this.props.todoMigratedEventHandler('activeTodoList', this.props.quickMigrateTodoId);
                }
                else if (this.props.comingFrom == 'pastCompletedTodoList') {
                    // this.props.todoMigratedEventHandler(this.props.quickMigrateTodoId);
                }
                this.props.todoMigratedEventHandler(this.props.quickMigrateTodoId);

                // window.location.replace('/project/'+this.state.newProjectId+'/active-todos');
                // triggerEvent



            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        editProjectForm: {
                            ...this.state.editProjectForm,
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                        },
                    });
                } else {
                    this.setState({
                        editProjectForm: {
                            ...this.state.editProjectForm,
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                        },
                    });
                }
            })
            .then(() => {
                this.setState({
                    editProjectForm: {
                        ...this.state.editProjectForm,
                        formSubmitting: false,
                    },
                });
            });
    }


    bootstrapAlertCloseButtonClickHandler(event)
    {
        this.setState({
            migrateToProjectForm: {
                ...this.state.migrateToProjectForm,
                bootstrapAlertContent: null,
            },
            projectDetailsBootstrapAlertContent: null,
            projectListBootstrapAlertContent: null,
        });
    }


    getDropdownValuesFromList()
    {
        const projectListArray = [];

        let counter = 0;
        for (const projectKey in this.state.projectList)
        {
            const projectEle = this.state.projectList[projectKey];

            if (projectEle['p__title'] == this.state.projectDetails.p__title) {
                continue;
            }

            if (!this.state.newProjectId && counter === 0) {
                this.setState({
                    newProjectId: projectEle['p__id'],
                });
            }

            projectListArray.push(
                <option key={projectEle['p__id']} value={projectEle['p__id']}>{utils.getProjectDisplpayTitle(projectEle['p__title'], 60)}</option>
            );

            counter++;
        }

        return projectListArray;
    }


    migrateProjectChangeHandler(event)
    {
        this.setState({
            newProjectId: event.target.value
        });
    }


    render()
    {
        return (
            // Modal
            <div className="modal fade" data-show="true" id="migrateToAnotherProjectModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <form onSubmit={this.migrateProjectFormSubmitHandler}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Migrate todo to another project</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                { this.state.projectDetailsBootstrapAlertContent }

                                { this.state.projectListBootstrapAlertContent }

                                { this.state.migrateToProjectForm.bootstrapAlertContent }

                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor='migrate'>* Migrate todo to project</label>
                                            <select id='migrate' className="form-control" onChange={this.migrateProjectChangeHandler.bind(this)}>
                                                { this.getDropdownValuesFromList() }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="row justify-content-end" style={{width:'100%'}}>
                                    <div className="col-sm-2" style={{marginBottom:'1rem'}}>
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                    </div>
                                    <div className="col-sm-6">
                                        <SubmitButton
                                            label="Migrate to another project"
                                            formHasError={this.state.formHasError}
                                            isSubmitting={this.state.formSubmitting} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}


// Redux State Management
const mapStateToProps = (curState) => {
    return {
        quickMigrateTodoId: curState.quickMigrateTodoId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        populateQuickMigrateTodoToAnotherProjectModalOpened: () => {
            return dispatch({type: actionTypes.QUICK_MIGRATE_TODO_PROJECT_PROCESSED})
        },
    };
};



// Export Component
export default connect(mapStateToProps, mapDispatchToProps)(MigrateProject);