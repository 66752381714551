// Import React, Supporting Libraries
import React from 'react';
import { connect } from 'react-redux';


// Other Components
import ApiAxios from '../../../vendors/axios/api';
import SubmitButton from '../../../components/form/SubmitButton/SubmitButton';
import * as utils from '../../../utils/utils';
import * as actionTypes from "../../../vendors/redux/state/actions";


// Class Component
class PersonalDetails extends React.Component
{

    constructor(props)
    {

        super(props);

        this.state = {
            getPersonalDetailsURL: '/secure/my-profile/personal-details',

            personalDetailsForm: {
                url: '/secure/my-profile/personal-details',

                fields: {
                    firstName: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            placeholder: 'Your first name',
                            required: true,
                            minLength: 2,
                            maxLength: 30,
                        },
                        value: '',
                        label: 'First Name',
                        validation: {
                            required: true,
                            minLength: 2,
                            maxLength: 30,
                        },
                        invalidMessage: '',
                    },
                    lastName: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            placeholder: 'Your last name',
                            required: true,
                            minLength: 2,
                            maxLength: 30,
                        },
                        value: '',
                        label: 'Last Name',
                        validation: {
                            required: true,
                            minLength: 2,
                            maxLength: 30,
                        },
                        invalidMessage: '',
                    },
                },

                formHasError: false,
                formSubmitting: false,

                bootstrapAlertContent: null,
            },
        };

        // Regular Functions
        this.getPersonalDetails                     =  this.getPersonalDetails.bind(this);
        this.validateFormHasErrorBeforeSubmit       =  this.validateFormHasErrorBeforeSubmit.bind(this);

        // Event Handlers
        this.inputValueChangeHandler                =  this.inputValueChangeHandler.bind(this);
        this.personalDetailsFormSubmitHandler       =  this.personalDetailsFormSubmitHandler.bind(this);
        this.bootstrapAlertCloseButtonClickHandler  =  this.bootstrapAlertCloseButtonClickHandler.bind(this);
    }


    componentDidMount()
    {
        const authToken = utils.validateIsAuthenticated(this.props);
        if (authToken != false) {
            ApiAxios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
        } else {
            utils.redirectToLogin(this.props);
        }

        this.getPersonalDetails();
    }


    getPersonalDetails()
    {
        ApiAxios.get(this.state.getPersonalDetailsURL)
            .then((response) => {
                const personalDetailsForm = this.state.personalDetailsForm;
                personalDetailsForm.fields.firstName.value  =  response.data.data.u__first_name;
                personalDetailsForm.fields.lastName.value   =  response.data.data.u__last_name;
                this.setState({
                    personalDetailsForm: {
                        ...personalDetailsForm,
                    }
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                } else {
                    this.setState({
                        personalDetailsForm: {
                            ...this.state.personalDetailsForm,
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                        }
                    });
                }
            })
            .then(() => {

            });
    }


    inputValueChangeHandler(formEleName, event)
    {
        this.setState({
            personalDetailsForm: {
                ...utils.inputValueChangedHelper(formEleName, event.target.value, this.state.personalDetailsForm)
            }
        });
    }


    validateFormHasErrorBeforeSubmit()
    {
        let formHasError = false;
        for (let eleName in this.state.personalDetailsForm.fields) {
            const element = this.state.personalDetailsForm.fields[eleName];

            const validationResponse = utils.inputValueChangedHelper(eleName, element.value, this.state.personalDetailsForm);
            this.setState({
                personalDetailsForm: {
                    ...validationResponse
                }
            });
            if (validationResponse.formHasError) {
                formHasError = true;
            }
        }

        return formHasError;
    }


    personalDetailsFormSubmitHandler(event)
    {
        event.preventDefault();

        if (this.validateFormHasErrorBeforeSubmit()) {
            return false;
        }

        this.setState({
            personalDetailsForm: {
                ...this.state.personalDetailsForm,
                formSubmitting: true,
            }
        });

        const postFormData = {
            firstName: this.state.personalDetailsForm.fields.firstName.value,
            lastName: this.state.personalDetailsForm.fields.lastName.value,
        };

        ApiAxios.post(this.state.personalDetailsForm.url, postFormData)
            .then((response) => {
                this.setState({
                    personalDetailsForm: {
                        ...this.state.personalDetailsForm,
                        bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, response.data.message),
                    },
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        personalDetailsForm: {
                            ...this.state.personalDetailsForm,
                            ...utils.showValidationErrorAfterFormSubmit(error.response.data, this.state.personalDetailsForm),
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                        }
                    });

                } else {
                    this.setState({
                        personalDetailsForm: {
                            ...this.state.personalDetailsForm,
                            ...utils.showValidationErrorAfterFormSubmit(error.response.data, this.state.personalDetailsForm),
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                        }
                    });
                }
                this.setState({
                    personalDetailsForm: {
                        ...this.state.personalDetailsForm,
                        formHasError: true,
                    }
                });
            })
            .then(() => {
                this.setState({
                    personalDetailsForm: {
                        ...this.state.personalDetailsForm,
                        formSubmitting: false,
                    }
                });
            });
    }


    bootstrapAlertCloseButtonClickHandler(event)
    {
        this.setState({
            personalDetailsForm: {
                ...this.state.personalDetailsForm,
                bootstrapAlertContent: null,
            },
        });
    }


    render()
    {

        return (
            <form onSubmit={this.personalDetailsFormSubmitHandler}>
                { this.state.personalDetailsForm.bootstrapAlertContent }

                { utils.renderFormGroupComponentHelper(this.state.personalDetailsForm.fields, this.inputValueChangeHandler) }

                <div className="form-group row justify-content-end">
                    <div className="col-sm-6">
                        <SubmitButton
                            label="Update Details"
                            formHasError={this.state.personalDetailsForm.formHasError}
                            isSubmitting={this.state.personalDetailsForm.formSubmitting} />
                    </div>
                </div>
            </form>
        );
    }
}


// Redux State Management
const mapStateToProps = (curState) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addInterPageMessage: (message, messageType) => {
            return dispatch({type: actionTypes.INTER_PAGE_MESSAGE_ADDED, message: message, messageType: messageType});
        },
    };
};


// Export Components
export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails);