// Import React, Supporting Libraries Libraries
import React from 'react';
import { NavLink } from 'react-router-dom';
import Axios from '../../../Axios/Axios';
import { Link } from 'react-router-dom';
// import { useToasts, withToastManager } from 'react-toast-notifications'
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as actionTypes from '../../../redux/state/actions';
import * as utils from '../../../utils/utils';


// Import Css Module
import classes from './TodoList.module.css';


// Import Other Components
import FormGroupInput from '../../../components/Form/FormGroupInput/FormGroupInput';
import SubmitButton from '../../../components/Form/SubmitButton/SubmitButton';
import MainNavHeader from '../../../components/Navigation/MainNavHeader/MainNavHeader';

// const bearerToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3Q6OTAzMVwvYXBpXC9ndWVzdFwvbG9naW4iLCJpYXQiOjE1OTMxNzY2MDksImV4cCI6MTU5MzIxOTgwOSwibmJmIjoxNTkzMTc2NjA5LCJqdGkiOiJCbEREeHlYbERWY0hha2FDIiwic3ViIjoiZTgyM2EwMTgtYTY0Yy00Y2RmLWE2MmMtYTE2NTljMWYwMTBkIiwicHJ2IjoiODdlMGFmMWVmOWZkMTU4MTJmZGVjOTcxNTNhMTRlMGIwNDc1NDZhYSJ9.08b4inq9LNO7O68qj39CRxOKIYXnrDa2PA_1pUzwnPI';


// Class Component
class TodoList extends React.Component
{

    constructor(props)
    {
        super(props);

        const today = new Date();
        const dateString = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

        this.state = {
            formUrl: '/secure/todo/list?filterDate=today',

            todoListFilters: {
                showCompletedTodos: false,
                filterDate: 'today', // upcoming,noDueDate
                filterPriority: 3, // 1,2,3,4,5
                filterViewAll: false,

                localSearchString: '',
                localSearchErrorString: '',
            },

            overdueTodoListFilters: {
                localSearchString: '',
                localSearchErrorString: '',
            },

            todoListResponse: {},
            pastTodoListResponse: {},

            regularTodoListPagination: {
                resultsPerPage: 10,
                totalCount: 0,
                noOfPages: 1,
                currentPage: 1,
            },

            overdueTodoListPagination: {
                resultsPerPage: 200,
                totalCount: 0,
                noOfPages: 1,
                currentPage: 1,
            },
        }

        this.handleCompletedCheckboxChange = this.handleCompletedCheckboxChange.bind(this);
        this.handleShowCompletedTodosChange = this.handleShowCompletedTodosChange.bind(this);
        this.handleDueDateFilterChange = this.handleDueDateFilterChange.bind(this);
        this.handlePriorityFilterChange = this.handlePriorityFilterChange.bind(this);
        this.handleNoFilterChange = this.handleNoFilterChange.bind(this);
    }


    componentDidMount() {
        const authToken = utils.validateIsAuthenticated();
        if (authToken != false) {
            Axios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
        } else {
            this.props.history.replace('/login');
        }

        this.getTodoList(this.state.todoListFilters, this.state.regularTodoListPagination);

        this.getOverdueTodoList(this.state.overdueTodoListFilters, this.state.overdueTodoListPagination);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        // prevProps.newTodoGotAdded !== this.props.newTodoGotAdded
        if (this.props.newTodoGotAdded === true) {
            this.getTodoList(this.state.todoListFilters, this.state.regularTodoListPagination);

            this.props.processNewTodo();
        }
    }


    getTodoList(todoListFilters = null, regularTodoListPagination = null) {
        if (!todoListFilters) {
            todoListFilters = this.state.todoListFilters;
        }

        if (!regularTodoListPagination) {
            regularTodoListPagination = this.state.regularTodoListPagination;
        }

        let getParams = {
            filterDate: todoListFilters.filterDate,
            filterPriority: todoListFilters.filterPriority,
        };
        if (todoListFilters.filterViewAll) {
            getParams = {};
        }

        if (todoListFilters.showCompletedTodos) {
            getParams = {
                ...getParams,
                filterIncludeCompleted: 1,
            };
        }

        getParams = {
            ...getParams,
            pageNo: regularTodoListPagination.currentPage,
            resultPerPage: regularTodoListPagination.resultsPerPage,
        }

        if (todoListFilters.localSearchString != '') {
            getParams = {
                ...getParams,
                titleSearch: todoListFilters.localSearchString,
            }
        }

        Axios.get('/secure/todo/list', {
            params: getParams
        })
            .then((response) => {

                this.setState({
                    todoListResponse: response.data.data,
                    regularTodoListPagination: {
                        ...this.state.regularTodoListPagination,
                        totalCount: response.data.pagination.totalCount ? response.data.pagination.totalCount : 0,
                        noOfPages: response.data.pagination.noOfPages ? response.data.pagination.noOfPages : 1,
                    }
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    toast(error.response.data.message, {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });                    
                } else {
                    toast('Your request could not be processed right now, please try again later', {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });
                }
                // else if (error.request) {
                //     console.log('[Resgister POST Error Request Caught]', error.request);
                // }
                // console.log('[Resgister POST Error Message Caught]', error.message);
            })
            .then(() => {
                
            });
    }


    getOverdueTodoList(todoListFilters = null, todoListPagination = null)
    {
        if (!todoListFilters) {
            todoListFilters = this.state.overdueTodoListFilters;
        }

        if (!todoListPagination) {
            todoListPagination = this.state.overdueTodoListPagination;
        }

        let getParams = {
            pageNo: todoListPagination.currentPage,
            resultPerPage: todoListPagination.resultsPerPage,
        };

        if (todoListFilters.localSearchString != '') {
            getParams = {
                ...getParams,
                titleSearch: todoListFilters.localSearchString,
            }
        }

        Axios.get('/secure/todo/overdue-list', {
            params: getParams
        })
            .then((response) => {
                this.setState({
                    pastTodoListResponse: response.data.data,
                    overdueTodoListPagination: {
                        ...this.state.overdueTodoListPagination,
                        totalCount: response.data.pagination.totalCount ? response.data.pagination.totalCount : 0,
                        noOfPages: response.data.pagination.noOfPages ? response.data.pagination.noOfPages : 1,
                    }
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }

                    toast(error.response.data.message, {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });
                } else {
                    toast('Your request could not be processed right now, please try again later', {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });
                }
                // else if (error.request) {
                //     console.log('[Resgister POST Error Request Caught]', error.request);
                // }
                // console.log('[Resgister POST Error Message Caught]', error.message);
            })
            .then(() => {
                
            });
    }


    handleCompletedCheckboxChange(todoId, todoListType, event) {
        const postData = {
            todo: todoId,
            completed: event.target.checked ? 1 : 0,
        };
        Axios.post('/secure/todo/update/completed', postData)
            .then((response) => {
                toast(response.data.message, {
                    autoClose: 8000,
                    hideProgressBar: true,
                    type: toast.TYPE.SUCCESS,
                    position: toast.POSITION.BOTTOM_RIGHT,
                    pauseOnHover: true,
                    pauseOnFocusLoss: true,
                });

                if (todoListType === 'regularTodoList') {
                    this.getTodoList(this.state.todoListFilters, this.state.regularTodoListPagination);
                } else if (todoListType === 'overdueTodoList') {
                    this.getOverdueTodoList(this.state.overdueTodoListFilters,  this.state.overdueTodoListPagination);
                }

                // const updatedTodoList = this.state.todoList;
                // for (let key in updatedTodoList) {
                //     const todoEle = updatedTodoList[key];

                //     if (todoEle.t__id === todoId) {

                //         let dateTime = null;
                //         if (event.target.checked) {
                //             const today = new Date();
                //             const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                //             const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                //             dateTime = date+' '+time;
                //         }
                        
                //         todoEle.t__completed_at = dateTime;
                //         updatedTodoList[key] = todoEle;

                //         break;
                //     }
                // }
                // this.setState({
                //     todoList: {
                //         ...updatedTodoList,
                //     }
                // });
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    toast(error.response.data.message, {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });

                    // this.showValidationErrorAfterFormSubmit(error.response.data);
                    
                } else {
                    toast('Your request could not be processed right now, please try again later', {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });
                }
            })
            .then(() => {
                // this.setState({
                //     formSubmitting: false,
                // });
            });
    }


    handleShowCompletedTodosChange(event)
    {
        const todoListFilters = {
            ...this.state.todoListFilters
        };
        todoListFilters.showCompletedTodos = event.target.checked;
        this.setState({
            todoListFilters: todoListFilters,
        });
        this.getTodoList(todoListFilters);
    }


    handleDueDateFilterChange(inValue, event)
    {
        const todoListFilters = {
            ...this.state.todoListFilters
        };

        todoListFilters.filterDate = inValue;

        this.setState({
            todoListFilters: {
                ...todoListFilters
            }
        });

        this.getTodoList(todoListFilters);
    }


    handlePriorityFilterChange(inValue, event)
    {
        const todoListFilters = {
            ...this.state.todoListFilters
        };

        todoListFilters.filterPriority = inValue;

        this.setState({
            todoListFilters: {
                ...todoListFilters
            }
        });

        this.getTodoList(todoListFilters);
    }


    handleNoFilterChange(event)
    {
        const todoListFilters = {
            ...this.state.todoListFilters
        };
        todoListFilters.filterViewAll = event.target.checked;
        this.setState({
            todoListFilters: todoListFilters,
        });
        this.getTodoList(todoListFilters);
    }


    handleRegularTodoListResultsPerPageChange = (event) => {
        const regularTodoListPagination = this.state.regularTodoListPagination;
        regularTodoListPagination.resultsPerPage = event.target.value;
        regularTodoListPagination.currentPage = 1;

        this.setState({
            regularTodoListPagination: regularTodoListPagination,
        });
        this.getTodoList(this.state.todoListFilters, regularTodoListPagination);
    }


    handleRegularTodoListPageButtonClick = (newPageNo, event) => {
        const regularTodoListPagination = this.state.regularTodoListPagination;
        regularTodoListPagination.currentPage = newPageNo;
        
        this.setState({
            regularTodoListPagination: regularTodoListPagination,
        });
        this.getTodoList(this.state.todoListFilters, regularTodoListPagination);
    }


    generatePaginationForRegularTodoList() {

        const currentPageNo = this.state.regularTodoListPagination.currentPage;
        const noOfPages = this.state.regularTodoListPagination.noOfPages;

        let previousButtonClass = 'page-item';
        let previousButtonDisabled = false;
        if (this.state.regularTodoListPagination.currentPage == 1) {
            previousButtonClass = previousButtonClass + ' disabled';
            previousButtonDisabled = true;
        }

        let nextButtonClass = 'page-item';
        let nextButtonDisabled = false;
        if (currentPageNo == noOfPages) {
            nextButtonClass = nextButtonClass + ' disabled';
            nextButtonDisabled = true;
        }

        const pageItemsArray = [];
        
        for (let i = 1; i <= noOfPages; i++) {
            let pageItemClassName = 'page-item';
            let pageItemDisabled = false;

            let pageItemElement = null;
            if (i == currentPageNo) {
                pageItemClassName = pageItemClassName + ' active';
                pageItemDisabled = true;
                // pageItemElement = <li className={pageItemClassName} aria-current="page"><span className="page-link">{i}<span class="sr-only">(current)</span></span></li>
                pageItemElement = <li className={pageItemClassName} aria-current="page"><span className="page-link">{i}<span class="sr-only">(current)</span></span></li>
            } else {
                // pageItemElement = <li className={pageItemClassName}><a className="page-link" href="#" aria-disabled={pageItemDisabled} onClick={this.handleRegularTodoListPageButtonClick.bind(this, i)}>{i}</a></li>
                pageItemElement = <li className={pageItemClassName}><button className="page-link" onClick={this.handleRegularTodoListPageButtonClick.bind(this, i)}>{i}</button></li>;
            }

            pageItemsArray.push(pageItemElement);
        }

        return (
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                    <li className={previousButtonClass}>
                        <button className="page-link" tabindex="-1" aria-disabled={previousButtonDisabled} onClick={this.handleRegularTodoListPageButtonClick.bind(this, (currentPageNo - 1))}>Previous</button>
                    </li>
                    
                    {pageItemsArray}

                    <li className={nextButtonClass}>
                        <button className="page-link" aria-disabled={nextButtonDisabled} onClick={this.handleRegularTodoListPageButtonClick.bind(this, (currentPageNo + 1))}>Next</button>
                    </li>
                </ul>
            </nav>
        );
    }


    handleOverdueTodoListPageButtonClick = (newPageNo, event) => {
        const todoListPagination = this.state.overdueTodoListPagination;
        todoListPagination.currentPage = newPageNo;
        
        this.setState({
            overdueTodoListPagination: todoListPagination,
        });
        this.getOverdueTodoList(this.state.overdueTodoListFilters, todoListPagination);
    }


    handleLocalSearchStringChange = (stateFilterVariableName, event) => {
        const searchString = event.target.value;

        if (searchString == '') {
            const todoListFilter = this.state[stateFilterVariableName];

            todoListFilter.localSearchString = '';
            todoListFilter.localSearchErrorString = '';

            this.setState({
                [stateFilterVariableName]: {
                    ...todoListFilter,
                },
            });

            if (stateFilterVariableName === 'todoListFilters') {
                this.getTodoList(todoListFilter, this.state.regularTodoListPagination);
            } else if (stateFilterVariableName === 'overdueTodoListFilters') {
                this.getOverdueTodoList(todoListFilter, this.state.overdueTodoListPagination);
            }

            return false;
        }

        let errorMessage = '';
        if (searchString != '' && searchString.length < 3) {
            errorMessage = 'Please ensure search string is more than 2 chars in length';
        }
        if (searchString.length > 15) {
            errorMessage = 'Please ensure search string is less than 16 chars in length';
        }

        this.setState({
            [stateFilterVariableName]: {
                ...this.state[stateFilterVariableName],
                localSearchString: searchString,
                localSearchErrorString: errorMessage,
            },
        });
    }


    handleOverdueTodoLocalSearchFormSubmit = (event) => {
        event.preventDefault();

        // overdueTodoListPagination
        // overdueTodoListFilters

        const searchString = this.state.overdueTodoListFilters.localSearchString;

        let errorMessage = '';
        if (searchString != '' && searchString.length < 3) {
            errorMessage = 'Please ensure search string is more than 2 chars in length';
        }
        if (searchString.length > 15) {
            errorMessage = 'Please ensure search string is less than 16 chars in length';
        }

        this.setState({
            overdueTodoListFilters: {
                ...this.state.overdueTodoListFilters,
                localSearchString: searchString,
                localSearchErrorString: errorMessage,
            },
        });

        if (errorMessage != '') {
            toast(errorMessage, {
                autoClose: 8000,
                hideProgressBar: true,
                type: toast.TYPE.ERROR,
                position: toast.POSITION.BOTTOM_RIGHT,
                pauseOnHover: true,
                pauseOnFocusLoss: true,
            });
    
            return false;
        }
        
        this.getOverdueTodoList(this.state.overdueTodoListFilters, this.state.overdueTodoListPagination);
    }


    handleRegularTodoLocalSearchFormSubmit = (event) => {
        event.preventDefault();

        const searchString = this.state.todoListFilters.localSearchString;

        let errorMessage = '';
        if (searchString != '' && searchString.length < 3) {
            errorMessage = 'Please ensure search string is more than 2 chars in length';
        }
        if (searchString.length > 15) {
            errorMessage = 'Please ensure search string is less than 16 chars in length';
        }

        this.setState({
            todoListFilters: {
                ...this.state.todoListFilters,
                localSearchString: searchString,
                localSearchErrorString: errorMessage,
            },
        });

        if (errorMessage != '') {
            toast(errorMessage, {
                autoClose: 8000,
                hideProgressBar: true,
                type: toast.TYPE.ERROR,
                position: toast.POSITION.BOTTOM_RIGHT,
                pauseOnHover: true,
                pauseOnFocusLoss: true,
            });
    
            return false;
        }
        
        this.getTodoList(this.state.todoListFilters, this.state.todoListPagination);
    }


    generatePaginationForOverdueTodoList() {

        const currentPageNo = this.state.overdueTodoListPagination.currentPage;
        const noOfPages = this.state.overdueTodoListPagination.noOfPages;

        let previousButtonClass = 'page-item';
        let previousButtonDisabled = false;
        if (this.state.overdueTodoListPagination.currentPage == 1) {
            previousButtonClass = previousButtonClass + ' disabled';
            previousButtonDisabled = true;
        }

        let nextButtonClass = 'page-item';
        let nextButtonDisabled = false;
        if (currentPageNo == noOfPages) {
            nextButtonClass = nextButtonClass + ' disabled';
            nextButtonDisabled = true;
        }

        const pageItemsArray = [];
        
        for (let i = 1; i <= noOfPages; i++) {
            let pageItemClassName = 'page-item';
            let pageItemDisabled = false;

            let pageItemElement = null;
            if (i == currentPageNo) {
                pageItemClassName = pageItemClassName + ' active';
                pageItemDisabled = true;
                // pageItemElement = <li className={pageItemClassName} aria-current="page"><span className="page-link">{i}<span class="sr-only">(current)</span></span></li>
                pageItemElement = <li className={pageItemClassName} aria-current="page"><span className="page-link">{i}<span class="sr-only">(current)</span></span></li>
            } else {
                // pageItemElement = <li className={pageItemClassName}><a className="page-link" href="#" aria-disabled={pageItemDisabled} onClick={this.handleOverdueTodoListPageButtonClick.bind(this, i)}>{i}</a></li>
                pageItemElement = <li className={pageItemClassName}><button className="page-link" onClick={this.handleOverdueTodoListPageButtonClick.bind(this, i)}>{i}</button></li>;
            }

            pageItemsArray.push(pageItemElement);
        }

        return (
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                    <li className={previousButtonClass}>
                        <button className="page-link" tabindex="-1" aria-disabled={previousButtonDisabled} onClick={this.handleOverdueTodoListPageButtonClick.bind(this, (currentPageNo - 1))}>Previous</button>
                    </li>
                    
                    {pageItemsArray}

                    <li className={nextButtonClass}>
                        <button className="page-link" aria-disabled={nextButtonDisabled} onClick={this.handleOverdueTodoListPageButtonClick.bind(this, (currentPageNo + 1))}>Next</button>
                    </li>
                </ul>
            </nav>
        );
    }


    render() {
        // let formFields = [];
        // for (let eleName in this.state.formData) {
        //     const element = this.state.formData[eleName];
        //     formFields.push(
        //         <FormGroupInput 
        //             key={eleName}
        //             name={eleName}
        //             elementType={element.elementType} 
        //             value={element.value}
        //             label={element.label}
        //             invalidMessage={element.invalidMessage}
        //             elementConfig={element.elementConfig}
        //             changeHandler={this.handleInputValueChange.bind(this, eleName)}
        //             />
        //     );
        // }

        // console.log(this.state.todoList)

        const todoList = [];
        for (let todoKey in this.state.todoListResponse) {
            const ele = this.state.todoListResponse[todoKey];
            let checked = false
            let label = <NavLink className="" to={this.props.match.url+'/'+ele.t__id+'/details'}>{utils.getTodoDisplpayTitle(ele.t__title)}</NavLink>
            let dueDateLabel = null; // t__due_date: "2020-05-31 23:59:59"
            let completedAtLabel = <React.Fragment><label style={{float: 'right'}}>-- ---- ----</label></React.Fragment>;
            if (ele.t__completed_at !== null) {
                checked = true;
                label = <NavLink className="" to={this.props.match.url+'/'+ele.t__id+'/details'}><s>{utils.getTodoDisplpayTitle(ele.t__title)}</s></NavLink>

                completedAtLabel = <React.Fragment><label style={{float: 'right'}}>{utils.returnJustDate(ele.t__completed_at)}</label></React.Fragment>; // t__due_date: "2020-05-31 23:59:59"
            }
            if (ele.t__due_date !== null) {
                dueDateLabel = <React.Fragment><label style={{float: 'right'}}>{utils.returnJustDate(ele.t__due_date)}</label></React.Fragment>
            }
            todoList.push(
                // <li className="list-group-item" key={ele.t__id}>
                //     <div className="form-group form-check">
                //         <input 
                //             type="checkbox" 
                //             className="form-check-input" 
                //             checked={checked}
                //             onChange={this.handleCompletedCheckboxChange.bind(this, ele.t__id, 'regularTodoList')} />
                //         {label}
                //         {dueDateLabel}
                //     </div>
                // </li>

                <div className="col mb-3" key={ele.t__id}>
                    <div className="card h-100">
                        {/* <div className="card-body" style={{padding: '1rem 2rem'}}>
                            
                        </div> */}
                        <div className="card-body">
                            <div className="form-check">
                                <input 
                                    id={'chk'+ele.t__id}
                                    type="checkbox" 
                                    className="form-check-input" 
                                    checked={checked}
                                    onChange={this.handleCompletedCheckboxChange.bind(this, ele.t__id, 'regularTodoList')} />
                                <label for={'chk'+ele.t__id}>Is Completed</label>
                            </div>
                            <div>
                                <label>Due On</label> {dueDateLabel}
                            </div>
                            <div>
                                <label>Completed On</label> {completedAtLabel}
                            </div>
                            <hr />
                            {label}
                        </div>
                    </div>
                </div>
                
            );
        }

        const overdueTodoList = [];
        for (let todoKey in this.state.pastTodoListResponse) {
            const ele = this.state.pastTodoListResponse[todoKey];
            let checked = false
            let label = <NavLink className="" to={this.props.match.url+'/'+ele.t__id+'/details'}>{utils.getTodoDisplpayTitle(ele.t__title)}</NavLink>
            let dueDateLabel = null; // t__due_date: "2020-05-31 23:59:59"
            if (ele.t__completed_at !== null) {
                checked = true;
                // label = <NavLink className="form-check-label" to={this.props.match.url+'/'+ele.t__id+'/details'}><s>{utils.getTodoDisplpayTitle(ele.t__title)}</s></NavLink>
                label = <NavLink className="" to={this.props.match.url+'/'+ele.t__id+'/details'}><s>{utils.getTodoDisplpayTitle(ele.t__title)}</s></NavLink>
            }
            if (ele.t__due_date !== null) {
                dueDateLabel = <React.Fragment><label style={{float: 'right'}}>{utils.returnJustDate(ele.t__due_date)}</label></React.Fragment>
            }
            overdueTodoList.push(
                // <li className="list-group-item" key={ele.t__id}>
                //     <div className="form-group form-check">
                //         <input 
                //             type="checkbox" 
                //             className="form-check-input" 
                //             checked={checked}
                //             onChange={this.handleCompletedCheckboxChange.bind(this, ele.t__id, 'overdueTodoList')} />
                //         {label}
                //         {dueDateLabel}
                //     </div>
                // </li>

                <div className="col mb-3" key={ele.t__id}>
                    <div className="card h-100">
                        <div className="card-body">
                        <div className="form-check">
                                <input 
                                    id={'chk'+ele.t__id}
                                    type="checkbox" 
                                    className="form-check-input" 
                                    checked={checked}
                                    onChange={this.handleCompletedCheckboxChange.bind(this, ele.t__id, 'overdueTodoList')} />
                                <label for={'chk'+ele.t__id}>Is Completed</label>
                            </div>
                            <div>
                                <label>Due On</label> {dueDateLabel}
                            </div>
                            <hr />
                            {label}
                        </div>
                    </div>
                </div>
                
            );
        }

        const today = new Date();
        const dateString = utils.formatDateToHumanReadableFormat(today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate());
        // const dateString = (today.getMonth()+1)+', '+today.getDate();
        // const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

        let visibleFilters = null;
        if (!this.state.todoListFilters.filterViewAll) {
            visibleFilters = <React.Fragment>
                <p>
                    <small>Due Date Filter</small>
                    <div className="btn-group-toggle" data-toggle="buttons">
                        <label className="btn btn-outline-danger btn-sm active" style={{marginBottom: '0.5rem'}}>
                            <input type="radio" checked onClick={this.handleDueDateFilterChange.bind(this, 'today')} /> Today
                        </label>
                        &nbsp;&nbsp;
                        <label className="btn btn-outline-info btn-sm" style={{marginBottom: '0.5rem'}}>
                            <input type="radio" onClick={this.handleDueDateFilterChange.bind(this, 'upcoming')} /> Upcoming
                        </label>
                        &nbsp;&nbsp;
                        <label className="btn btn-outline-secondary btn-sm" style={{marginBottom: '0.5rem'}}>
                            <input type="radio" onClick={this.handleDueDateFilterChange.bind(this, 'noDueDate')} /> No Due Date
                        </label>
                    </div>
                </p>

                <p>
                    <small>Priority Filter</small>
                    <div className="btn-group-toggle" data-toggle="buttons">
                        <label className="btn btn-outline-secondary btn-sm" style={{marginBottom: '0.5rem'}}>
                            <input type="radio" onClick={this.handlePriorityFilterChange.bind(this, '5')} /> Lowest
                        </label>
                        &nbsp;
                        <label className="btn btn-outline-secondary btn-sm" style={{marginBottom: '0.5rem'}}>
                            <input type="radio" onClick={this.handlePriorityFilterChange.bind(this, '4')} /> Low
                        </label>
                        &nbsp;
                        <label className="btn btn-outline-secondary btn-sm active" style={{marginBottom: '0.5rem'}}>
                            <input type="radio" checked onClick={this.handlePriorityFilterChange.bind(this, '3')} /> Medium
                        </label>
                        &nbsp;
                        <label className="btn btn-outline-secondary btn-sm" style={{marginBottom: '0.5rem'}}>
                            <input type="radio" onClick={this.handlePriorityFilterChange.bind(this, '2')} /> High
                        </label>
                        &nbsp;
                        <label className="btn btn-outline-secondary btn-sm" style={{marginBottom: '0.5rem'}}>
                            <input type="radio" onClick={this.handlePriorityFilterChange.bind(this, '1')} /> Highest
                        </label>
                    </div>
                </p>
            </React.Fragment>;
        }

        return (
            <React.Fragment>
                <MainNavHeader />
                <div className={'container-fluid ' + classes.MyContainer}>
                    <div className="row">
                        <div className="col-lg-3">
                            {/* Marked for deletion */}
                            {/* <button className="btn btn-primary btn-sm" data-toggle="modal" data-target="#exampleModal">Add Todo</button> */}
                            <h3 style={{marginBottom: '1.5rem'}}>Filters</h3>
                            <hr />

                            <p>
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" checked={this.state.todoListFilters.showCompletedTodos} className="custom-control-input" id="showCompletedToggle" onChange={this.handleShowCompletedTodosChange.bind(this)} />
                                    <label className="custom-control-label" for="showCompletedToggle">Show completed todos</label>
                                </div>
                            </p>


                            <p>
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" checked={this.state.todoListFilters.filterViewAll} className="custom-control-input" id="noFilterToggle" onChange={this.handleNoFilterChange.bind(this)} />
                                    <label className="custom-control-label" for="noFilterToggle">No Filter</label>
                                </div>
                            </p>

                            {visibleFilters}
                            
                            
                            {/* <button type="button" className="btn btn-outline-danger" data-toggle="button" aria-pressed="true">Today</button>
                            <button type="button" className="btn btn-outline-success" data-toggle="button">Upcoming</button> */}
                            <br />
                            
                        </div>
                        <div className="col-lg-9">
                            <div className="row">
                                <div className="col-sm-5">
                                    <h3>Overdue Todos</h3>
                                </div>
                                <div className="col-sm-7">
                                    <div style={{ marginTop: '0.2rem' }}>
                                        <form className="form-inline my-2 my-lg-0" onSubmit={this.handleOverdueTodoLocalSearchFormSubmit}>
                                            <input className="form-control mr-sm-2" 
                                                style={this.state.overdueTodoListFilters.localSearchErrorString != '' ? {color: 'red'} : {color: 'black'}} 
                                                type="search" placeholder="Local Todo Search" 
                                                required={true}
                                                aria-label="Search" 
                                                value={this.state.overdueTodoListFilters.localSearchString}
                                                onChange={this.handleLocalSearchStringChange.bind(this, 'overdueTodoListFilters')}
                                                minLength="3" 
                                                maxLength="15" />
                                            <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            
                            {/* this.state.overdueTodoListPagination.totalCount > 0 */}
                            {
                                this.state.overdueTodoListPagination.totalCount > 0 ? 
                                <React.Fragment>
                                    <div className="row" style={{marginTop: '0.5rem', marginBottom: '1rem'}}>
                                        <div className="col-md-6">
                                            <label for="inputState" className="" style={{display: 'inline', marginRight: '1rem'}}>Display Results Per Page</label>
                                            <select className="form-control" style={{display: 'inline', width:'5rem', marginRight: '1rem'}} disabled value={this.state.overdueTodoListPagination.resultsPerPage}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="200">200</option>
                                            </select>
                                            <label>Total Results: {this.state.overdueTodoListPagination.totalCount}</label>
                                        </div>
                                    </div>
                                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                                        {overdueTodoList}
                                    </div>
                                    { this.generatePaginationForOverdueTodoList() }  
                                </React.Fragment>
                                : <div style={{marginBottom: '3rem'}}>No overdue todos to display</div>
                            }

                            <br />
                            
                            <div className="row">
                                <div className="col-sm-5">
                                    <h3 style={{display: 'inline', marginRight: '1rem'}}>Today</h3>{dateString}
                                </div>
                                <div className="col-sm-7">
                                    <form className="form-inline my-2 my-lg-0" onSubmit={this.handleRegularTodoLocalSearchFormSubmit}>
                                        <input className="form-control mr-sm-2" 
                                            style={this.state.todoListFilters.localSearchErrorString != '' ? {color: 'red'} : {color: 'black'}} 
                                            type="search" placeholder="Local Todo Search" 
                                            aria-label="Search" 
                                            value={this.state.todoListFilters.localSearchString}
                                            onChange={this.handleLocalSearchStringChange.bind(this, 'todoListFilters')}
                                            minLength="3" 
                                            maxLength="15" />
                                        <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                                    </form>
                                </div>
                            </div>
                            
                            <hr />
                            {
                                this.state.regularTodoListPagination.totalCount > 0 ?
                                    <React.Fragment>
                                        <div className="row" style={{marginTop: '0.5rem', marginBottom: '1rem'}}>
                                            <div className="col-md-6">
                                                <label for="inputState" className="" style={{display: 'inline', marginRight: '1rem'}}>Display Results Per Page</label>
                                                <select className="form-control" style={{display: 'inline', width:'5rem', marginRight: '1rem'}} onChange={this.handleRegularTodoListResultsPerPageChange} value={this.state.regularTodoListPagination.resultsPerPage}>
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="200">200</option>
                                                </select>
                                                <label>Total Results: {this.state.regularTodoListPagination.totalCount}</label>
                                            </div>
                                        </div>

                                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                                            {todoList}
                                        </div>

                                        { this.generatePaginationForRegularTodoList() }
                                    </React.Fragment>
                                : 'No todos to display'
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


// Redux State Management
const mapStateToProps = (curState) => {
    return {
        token: curState.token,
        isAuthenticated: curState.isAuthenticated,
        newTodoGotAdded: curState.newTodoGotAdded,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        getAuthToken: () => {
            return dispatch({type: actionTypes.AUTH_CHECK})
        },
        processNewTodo: () => {
            return dispatch({type: actionTypes.NEW_TODO_GOT_PROCESSED})
        },
    };
};


// Export Component
export default connect(mapStateToProps, mapDispatchToProps)(TodoList);