// Import React, Supporting Libraries Libraries
import React from 'react';
import { NavLink } from 'react-router-dom';
import Axios from '../../../Axios/Axios';
// import { useToasts, withToastManager } from 'react-toast-notifications'
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as actionTypes from '../../../redux/state/actions';
import * as utils from '../../../utils/utils';


// Import Css Module
import classes from './PastCompletedTodoList.module.css';


// Import Other Components
import MainNavHeader from '../../../components/Navigation/MainNavHeader/MainNavHeader';


// Class Component
class PastCompletedTodoList extends React.Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            todoList: {},

            todoListPagination: {
                resultsPerPage: 10,
                totalCount: 0,
                noOfPages: 1,
                currentPage: 1,
            },

            todoListFilters: {
                filterPriority: 3, // 1,2,3,4,5
                filterViewAll: false,

                localSearchString: '',
                localSearchErrorString: '',
            },
        }
    }


    componentDidMount() {
        const authToken = utils.validateIsAuthenticated();
        if (authToken != false) {
            Axios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
        } else {
            this.props.history.replace('/login');
        }

        this.getTodoList();
    }


    getTodoList(todoListFilters = null, todoListPagination = null)
    {
        if (!todoListFilters) {
            todoListFilters = this.state.todoListFilters;
        }

        if (!todoListPagination) {
            todoListPagination = this.state.todoListPagination;
        }

        let getParams = {
            filterPriority: todoListFilters.filterPriority,
        };
        if (todoListFilters.filterViewAll) {
            getParams = {};
        }

        getParams = {
            ...getParams,
            pageNo: todoListPagination.currentPage,
            resultPerPage: todoListPagination.resultsPerPage,
        }

        if (todoListFilters.localSearchString != '') {
            getParams = {
                ...getParams,
                titleSearch: todoListFilters.localSearchString,
            }
        }

        Axios.get('/secure/todo/past-completed-list', {
            params: getParams
        })
            .then((response) => {
                this.setState({
                    todoList: response.data.data,
                    todoListPagination: {
                        ...this.state.todoListPagination,
                        totalCount: response.data.pagination.totalCount ? response.data.pagination.totalCount : 0,
                        noOfPages: response.data.pagination.noOfPages ? response.data.pagination.noOfPages : 1,
                    }
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    toast(error.response.data.message, {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });                    
                } else {
                    toast('Your request could not be processed right now, please try again later', {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });
                }
                // else if (error.request) {
                //     console.log('[Resgister POST Error Request Caught]', error.request);
                // }
                // console.log('[Resgister POST Error Message Caught]', error.message);
            })
            .then(() => {
                
            });
    }


    handleCompletedCheckboxChange(todoId, event) {
        const postData = {
            todo: todoId,
            completed: event.target.checked ? 1 : 0,
        };
        Axios.post('/secure/todo/update/completed', postData)
            .then((response) => {
                toast(response.data.message, {
                    autoClose: 8000,
                    hideProgressBar: true,
                    type: toast.TYPE.SUCCESS,
                    position: toast.POSITION.BOTTOM_RIGHT,
                    pauseOnHover: true,
                    pauseOnFocusLoss: true,
                });

                this.getTodoList(this.state.todoListFilters);

                // const updatedTodoList = this.state.todoList;
                // for (let key in updatedTodoList) {
                //     const todoEle = updatedTodoList[key];

                //     if (todoEle.t__id === todoId) {

                //         let dateTime = null;
                //         if (event.target.checked) {
                //             const today = new Date();
                //             const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                //             const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                //             dateTime = date+' '+time;
                //         }
                        
                //         todoEle.t__completed_at = dateTime;
                //         updatedTodoList[key] = todoEle;

                //         break;
                //     }
                // }
                // this.setState({
                //     todoList: {
                //         ...updatedTodoList,
                //     }
                // });
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    toast(error.response.data.message, {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });

                    // this.showValidationErrorAfterFormSubmit(error.response.data);
                    
                } else {
                    toast('Your request could not be processed right now, please try again later', {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });
                }
            })
            .then(() => {
                // this.setState({
                //     formSubmitting: false,
                // });
            });
    }


    handleTodoListResultsPerPageChange = (event) => {
        const todoListPagination = this.state.todoListPagination;
        todoListPagination.resultsPerPage = event.target.value;
        todoListPagination.currentPage = 1;

        this.setState({
            todoListPagination: todoListPagination,
        });
        this.getTodoList(this.state.todoListFilters, todoListPagination);
    }


    handleTodoListPageButtonClick = (newPageNo, event) => {
        const todoListPagination = this.state.todoListPagination;
        todoListPagination.currentPage = newPageNo;
        
        this.setState({
            todoListPagination: todoListPagination,
        });
        this.getTodoList(this.state.todoListFilters, todoListPagination);
    }


    generatePaginationForTodoList() {

        const currentPageNo = this.state.todoListPagination.currentPage;
        const noOfPages = this.state.todoListPagination.noOfPages;

        let previousButtonClass = 'page-item';
        let previousButtonDisabled = false;
        if (this.state.todoListPagination.currentPage == 1) {
            previousButtonClass = previousButtonClass + ' disabled';
            previousButtonDisabled = true;
        }

        let nextButtonClass = 'page-item';
        let nextButtonDisabled = false;
        if (currentPageNo == noOfPages) {
            nextButtonClass = nextButtonClass + ' disabled';
            nextButtonDisabled = true;
        }

        const pageItemsArray = [];
        
        for (let i = 1; i <= noOfPages; i++) {
            let pageItemClassName = 'page-item';
            let pageItemDisabled = false;

            let pageItemElement = null;
            if (i == currentPageNo) {
                pageItemClassName = pageItemClassName + ' active';
                pageItemDisabled = true;
                // pageItemElement = <li className={pageItemClassName} aria-current="page"><span className="page-link">{i}<span class="sr-only">(current)</span></span></li>
                pageItemElement = <li className={pageItemClassName} aria-current="page"><span className="page-link">{i}<span class="sr-only">(current)</span></span></li>
            } else {
                // pageItemElement = <li className={pageItemClassName}><a className="page-link" href="#" aria-disabled={pageItemDisabled} onClick={this.handleTodoListPageButtonClick.bind(this, i)}>{i}</a></li>
                pageItemElement = <li className={pageItemClassName}><button className="page-link" onClick={this.handleTodoListPageButtonClick.bind(this, i)}>{i}</button></li>;
            }

            pageItemsArray.push(pageItemElement);
        }

        return (
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                    <li className={previousButtonClass}>
                        <button className="page-link" tabindex="-1" aria-disabled={previousButtonDisabled} onClick={this.handleTodoListPageButtonClick.bind(this, (currentPageNo - 1))}>Previous</button>
                    </li>
                    
                    {pageItemsArray}

                    <li className={nextButtonClass}>
                        <button className="page-link" aria-disabled={nextButtonDisabled} onClick={this.handleTodoListPageButtonClick.bind(this, (currentPageNo + 1))}>Next</button>
                    </li>
                </ul>
            </nav>
        );
    }


    handleNoFilterChange = (event) => {
        const todoListFilters = {
            ...this.state.todoListFilters
        };
        todoListFilters.filterViewAll = event.target.checked;
        this.setState({
            todoListFilters: todoListFilters,
        });
        this.getTodoList(todoListFilters);
    }


    handlePriorityFilterChange = (inValue, event) => {
        const todoListFilters = {
            ...this.state.todoListFilters
        };

        todoListFilters.filterPriority = inValue;

        this.setState({
            todoListFilters: {
                ...todoListFilters
            }
        });

        this.getTodoList(todoListFilters);
    }


    handleLocalSearchStringChange = (event) => {
        const searchString = event.target.value;

        let errorMessage = '';
        if (searchString != '' && searchString.length < 3) {
            errorMessage = 'Please ensure search string is more than 2 chars in length';
        }
        if (searchString.length > 15) {
            errorMessage = 'Please ensure search string is less than 16 chars in length';
        }

        this.setState({
            todoListFilters: {
                ...this.state.todoListFilters,
                localSearchString: searchString,
                localSearchErrorString: errorMessage,
            },
        });
    }


    handleLocalSearchFormSubmit = (event) => {
        event.preventDefault();

        const searchString = this.state.todoListFilters.localSearchString;

        let errorMessage = '';
        if (searchString != '' && searchString.length < 3) {
            errorMessage = 'Please ensure search string is more than 2 chars in length';
        }
        if (searchString.length > 15) {
            errorMessage = 'Please ensure search string is less than 16 chars in length';
        }

        this.setState({
            todoListFilters: {
                ...this.state.todoListFilters,
                localSearchString: searchString,
                localSearchErrorString: errorMessage,
            },
        });

        if (errorMessage != '') {
            toast(errorMessage, {
                autoClose: 8000,
                hideProgressBar: true,
                type: toast.TYPE.ERROR,
                position: toast.POSITION.BOTTOM_RIGHT,
                pauseOnHover: true,
                pauseOnFocusLoss: true,
            });
    
            return false;
        }
        
        this.getTodoList(this.state.todoListFilters, this.state.todoListPagination);
    }


    render()
    {
        const todoList = [];
        for (let todoKey in this.state.todoList) {
            const ele = this.state.todoList[todoKey];

            const label = <NavLink className="form-check-label" to={'todos/'+ele.t__id+'/details'}>{utils.getTodoDisplpayTitle(ele.t__title)}</NavLink>
            const dueDateLabel = <React.Fragment><label style={{float: 'right'}}>{utils.returnJustDate(ele.t__due_date)}</label></React.Fragment>; // t__due_date: "2020-05-31 23:59:59"
            const completedAtLabel = <React.Fragment><label style={{float: 'right'}}>{utils.returnJustDate(ele.t__completed_at)}</label></React.Fragment>; // t__due_date: "2020-05-31 23:59:59"

            todoList.push(
                // <li className="list-group-item" key={ele.t__id}>
                //     <div className="form-group form-check">
                //         <input 
                //             type="checkbox" 
                //             className="form-check-input" 
                //             checked={true}
                //             // onChange={this.handleCompletedCheckboxChange.bind(this, ele.t__id)} 
                //             />
                //         {label}
                //         {dueDateLabel}
                //         {completedAtLabel}
                //     </div>
                // </li>

                <div class="col mb-3" key={ele.t__id}>
                    <div class="card h-100">
                        {/* <div class="card-body" style={{padding: '1rem 2rem'}}>
                            {/* <p class="card-text">
                                This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                            </p> *
                            <input 
                                type="checkbox" 
                                className="form-check-input" 
                                checked={true}
                                // onChange={this.handleCompletedCheckboxChange.bind(this, ele.t__id)} 
                                />
                            {label}
                            <div>
                                {dueDateLabel}
                                {completedAtLabel}
                            </div>
                        </div> */}

                        <div className="card-body">
                            <div className="form-check">
                                <input 
                                    id={'chk'+ele.t__id}
                                    type="checkbox" 
                                    className="form-check-input" 
                                    checked={true}
                                    onChange={this.handleCompletedCheckboxChange.bind(this, ele.t__id)} />
                                <label for={'chk'+ele.t__id}>Is Completed</label>
                            </div>
                            <div>
                                <label>Due On</label> {dueDateLabel}
                            </div>
                            <div>
                                <label>Completed On</label> {completedAtLabel}
                            </div>
                            <hr />
                            {label}
                        </div>
                    </div>
                </div>
                
            );
        }

        const today = new Date();
        const dateString = utils.formatDateToHumanReadableFormat(today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate());

        let visibleFilters = null;
        if (!this.state.todoListFilters.filterViewAll) {
            visibleFilters = <React.Fragment>
                <p>
                    <small>Priority Filter</small>
                    <div className="btn-group-toggle" data-toggle="buttons">
                        <label className="btn btn-outline-secondary btn-sm" style={{marginBottom: '0.5rem'}}>
                            <input type="radio" onClick={this.handlePriorityFilterChange.bind(this, '5')} /> Lowest
                        </label>
                        &nbsp;
                        <label className="btn btn-outline-secondary btn-sm" style={{marginBottom: '0.5rem'}}>
                            <input type="radio" onClick={this.handlePriorityFilterChange.bind(this, '4')} /> Low
                        </label>
                        &nbsp;
                        <label className="btn btn-outline-secondary btn-sm active" style={{marginBottom: '0.5rem'}}>
                            <input type="radio" checked onClick={this.handlePriorityFilterChange.bind(this, '3')} /> Medium
                        </label>
                        &nbsp;
                        <label className="btn btn-outline-secondary btn-sm" style={{marginBottom: '0.5rem'}}>
                            <input type="radio" onClick={this.handlePriorityFilterChange.bind(this, '2')} /> High
                        </label>
                        &nbsp;
                        <label className="btn btn-outline-secondary btn-sm" style={{marginBottom: '0.5rem'}}>
                            <input type="radio" onClick={this.handlePriorityFilterChange.bind(this, '1')} /> Highest
                        </label>
                    </div>
                </p>
            </React.Fragment>;
        }

        return (
            <React.Fragment>
                <MainNavHeader />
                <div className={'container-fluid ' + classes.MyContainer}>
                    <div className="row">
                        <div className="col-lg-3 col-xl-3">
                            <h3 style={{ marginBottom: '1.5rem' }}>Filters</h3>
                            <hr />

                            <p>
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" checked={this.state.todoListFilters.filterViewAll} className="custom-control-input" id="noFilterToggle" onChange={this.handleNoFilterChange.bind(this)} />
                                    <label className="custom-control-label" for="noFilterToggle">No Filter</label>
                                </div>
                            </p>

                            {visibleFilters}

                        </div>
                        <div className="col-lg-9 col-xl-9">
                            <div className="row">
                                <div className="col-xl-7">
                                    <h3 style={{display: 'inline', marginRight: '1rem'}}>Past Completed Todos</h3>Today's Date: {dateString}
                                </div>

                                <div className="col-xl-5">
                                    <div style={{ marginTop: '0.2rem' }}>
                                        <form className="form-inline my-2 my-lg-0" onSubmit={this.handleLocalSearchFormSubmit}>
                                            <input className="form-control mr-sm-2" 
                                                style={this.state.todoListFilters.localSearchErrorString != '' ? {color: 'red'} : {color: 'black'}} 
                                                type="search" placeholder="Local Todo Search" 
                                                aria-label="Search" 
                                                value={this.state.todoListFilters.localSearchString}
                                                onChange={this.handleLocalSearchStringChange}
                                                minLength="3" 
                                                maxLength="15" />
                                            <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            



                            



                            <hr />
                            {
                                this.state.todoListPagination.totalCount > 0 ? 
                                    <React.Fragment>
                                        <div className="row" style={{marginTop: '0.5rem', marginBottom: '1rem'}}>
                                            <div className="col-md-12">
                                                <label for="inputState" className="" style={{display: 'inline', marginRight: '1rem'}}>Display Results Per Page</label>
                                                <select className="form-control" style={{display: 'inline', width:'5rem', marginRight: '1rem'}} onChange={this.handleTodoListResultsPerPageChange} value={this.state.todoListPagination.resultsPerPage}>
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="200">200</option>
                                                </select>
                                                <label>Total Results: {this.state.todoListPagination.totalCount}</label>
                                            </div>
                                        </div>

                                        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">

                                            {todoList}

                                        </div>

                                        { this.generatePaginationForTodoList() }
                                    </React.Fragment>
                                : 'No todos to display'
                            }

                            {/* <ul className="list-group list-group-flush">
                                {todoList}
                            </ul> */}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


// Redux State Management
const mapStateToProps = (curState) => {
    return {
        token: curState.token,
        isAuthenticated: curState.isAuthenticated,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        getAuthToken: () => {
            return dispatch({type: actionTypes.AUTH_CHECK})
        }
    };
};


// Export Component
export default connect(mapStateToProps, mapDispatchToProps)(PastCompletedTodoList);