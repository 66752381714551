// Import React, Supporting Libraries
import React from 'react';
import { NavLink } from 'react-router-dom';
import DayPickerInput from "react-day-picker/DayPickerInput";
import {formatDate, parseDate} from "react-day-picker/moment";
import FormGroupInput from "../../form/FormGroupInput/FormGroupInput";
import SubmitButton from "../../form/SubmitButton/SubmitButton";


// Functional Component
class Header extends React.Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            globalSearchString: '',
            globalSearchErrorString: '',
        };

        // Event Handlers
        this.globalSearchStringChangeHandler  =  this.globalSearchStringChangeHandler.bind(this);
        this.globalSearchFormSubmitHandler    =  this.globalSearchFormSubmitHandler.bind(this);
    }


    componentDidMount()
    {
        /*
            This code logic simply repopulates search value in the search field when on search-results page
        */
        const urlArray = window.location.href.split('/');
        const secondLastUrlComponent = urlArray[urlArray.length-2];
        if (secondLastUrlComponent === 'search') {
            this.setState({
                globalSearchString: decodeURI(urlArray[urlArray.length-1]),
            })
        }
    }


    globalSearchStringChangeHandler(event)
    {
        const globalSearchString = event.target.value;

        let errorMessage = '';
        if (globalSearchString.length < 3) {
            errorMessage = 'Please ensure search string is more than 2 chars in length';
        }
        if (globalSearchString.length <= 0) {
            errorMessage = 'Cannot perform search on blank value';
        }
        if (globalSearchString.length > 15) {
            errorMessage = 'Please ensure search string is less than 16 chars in length';
        }

        this.setState({
            globalSearchString: globalSearchString,
            globalSearchErrorString: errorMessage,
        });
    }


    globalSearchFormSubmitHandler(event)
    {
        event.preventDefault();

        const globalSearchString = this.state.globalSearchString;

        let errorMessage = '';
        if (globalSearchString.length < 3) {
            errorMessage = 'Please ensure search string is more than 2 chars in length';
        }
        if (globalSearchString.length <= 0) {
            errorMessage = 'Cannot perform search on blank value';
        }
        if (globalSearchString.length > 15) {
            errorMessage = 'Please ensure search string is less than 16 chars in length';
        }

        this.setState({
            globalSearchString: globalSearchString,
            globalSearchErrorString: errorMessage,
        });

        if (errorMessage != '') {
            return false;
        }

        window.location.href = '/todo/search/'+globalSearchString;
        // this.props.history.replace('/todos/search/'+globalSearchString);
    }


    render()
    {
        return (
            <React.Fragment>

                <header id="page-topbar">
                    <div className="navbar-header">
                        <div className="d-flex">
                            {/*LOGO*/}
                            <div className="navbar-brand-box">
                                <NavLink to="/" className="logo logo-dark">
                                    <h3 style={{color: 'white', marginTop: '0.5rem'}}>EsyTodo</h3>
                                </NavLink>

                                <NavLink to="/" className="logo logo-light">
                                    <h3 style={{color: 'white', marginTop: '0.5rem'}}>EsyTodo</h3>
                                </NavLink>
                            </div>
                        </div>

                        <div className="d-flex">
                            <div className="btn-group" style={{marginLeft: '0rem'}}>
                                <button type="button" className="btn btn-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Quick Actions <i className="mdi mdi-chevron-down"></i>
                                </button>

                                <div className="dropdown-menu">
                                    <NavLink to="/dashboard" className="dropdown-item">Dashboard</NavLink>
                                    {/*<NavLink to="/dashboard" className="dropdown-item">Active Todos</NavLink>*/}
                                    {/*<NavLink to="/past-completed-todos" className="dropdown-item">Past Completed Todos</NavLink>*/}
                                    <hr />
                                    <h6 className="dropdown-header">Projects</h6>
                                    <a className="dropdown-item" data-toggle="modal" data-target="#createNewProjectModal">Create New Project</a>
                                    <hr />
                                    {/*<span className="dropdown-item-text">Todos</span>*/}
                                    <h6 className="dropdown-header">Todos</h6>
                                    <NavLink to="/all-todos/today" className="dropdown-item">View Today's Todos</NavLink>
                                    <NavLink to="/all-todos/tomorrow" className="dropdown-item">View Tomorrow's Todos</NavLink>
                                    <NavLink to="/all-todos/overdue" className="dropdown-item">View Overdue Todos</NavLink>
                                    <hr />
                                    <a className="dropdown-item" data-toggle="modal" data-target="#createNewGenericTodoModal">Create New Todo</a>
                                </div>
                            </div>
                            {/*<button type="button" className="btn btn-info btn-sm waves-effect waves-light">Add Todo</button>*/}
                        </div>

                        {/*<div className="d-flex u-sm-none">*/}
                        {/*    <div className="btn-group" style={{marginRight: '1rem'}}>*/}
                        {/*        <button type="button" className="btn btn-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
                        {/*            Todos <i className="mdi mdi-chevron-down"></i>*/}
                        {/*        </button>*/}

                        {/*        <div className="dropdown-menu">*/}
                        {/*            <a className="dropdown-item" href="#">Active Todos</a>*/}
                        {/*            <a className="dropdown-item" href="#">Past Completed Todos</a>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <button type="button" className="btn btn-info waves-effect waves-light">Add Todo</button>*/}
                        {/*</div>*/}

                        <div className="d-flex">

                            <div className="dropdown d-inline-block d-lg-none ml-2">
                                <button type="button" className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="mdi mdi-magnify"></i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0" aria-labelledby="page-header-search-dropdown">

                                    <form className="p-3" onSubmit={this.globalSearchFormSubmitHandler}>
                                        <div className="form-group m-0">
                                            <div className="input-group">
                                                <input
                                                    type="text" className="form-control"
                                                    required={true}
                                                    placeholder="Search ..." aria-label="Search"
                                                    value={this.state.globalSearchString}
                                                    onChange={this.globalSearchStringChangeHandler}
                                                    minLength="3"
                                                    maxLength="15" />
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="dropdown d-inline-block">
                                <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="bx bx-user align-middle mr-1" style={{fontSize:'24px'}}></i>
                                    {/*<img className="rounded-circle header-profile-user" src="/assets/images/users/avatar-1.jpg" alt="Header Avatar" />*/}
                                    <span className="d-none d-xl-inline-block ml-1">My Profile</span>
                                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-right">
                                    {/*item*/}
                                    <NavLink to="/my-profile" className="dropdown-item" href="#"><i className="bx bx-user font-size-16 align-middle mr-1"></i> Profile</NavLink>
                                    <NavLink to="/logout" className="dropdown-item text-danger"><i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> Logout</NavLink>
                                </div>
                            </div>

                            {/*App Search*/}
                            <form className="app-search d-none d-lg-block" style={{width: '20rem'}} onSubmit={this.globalSearchFormSubmitHandler}>
                                <div className="position-relative">
                                    <input type="text"
                                           className="form-control" placeholder="Search..."
                                           required={true}
                                           aria-label="Search"
                                           value={this.state.globalSearchString}
                                           onChange={this.globalSearchStringChangeHandler}
                                           minLength="3"
                                           maxLength="15"/>
                                    <span className="bx bx-search-alt"></span>
                                </div>
                            </form>

                        </div>
                    </div>
                </header>
            </React.Fragment>
        );
    }

}


// Export Components
export default Header;