// Import React, Supporting Libraries
import React from 'react';
import { connect } from 'react-redux';


// Import HOC Components
import withFormFeatures from '../../../hoc/guest_only/withFormFeatures/withFormFeatures';


// Other Components
import ApiAxios from '../../../vendors/axios/api';
import * as utils from '../../../utils/utils';


// Import Functional Components
import GuestOnlyCommonPageLayout from '../../../components/guest_only/CommonPageLayout/CommonPageLayout';
import SubmitButton from '../../../components/form/SubmitButton/SubmitButton';
import * as actionTypes from "../../../vendors/redux/state/actions";


// Class Component
class Login extends React.Component
{

    constructor(props) {
        super(props);

        this.state = {
            loginForm: {
                url: '/guest/login',

                fields: {
                    email: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'email',
                            placeholder: 'Enter your registered email address',
                            required: true,
                            minLength: 5,
                            maxLength: 50,
                        },
                        value: '',
                        label: 'Email Address',
                        validation: {
                            required: true,
                            minLength: 5,
                            maxLength: 50,
                        },
                        invalidMessage: '',
                    },
                    password: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'password',
                            placeholder: 'Password for your registered account',
                            required: true,
                            minLength: 5,
                            maxLength: 20,
                        },
                        isPasswordField: true,
                        value: '',
                        label: 'Password',
                        validation: {
                            required: true,
                            minLength: 5,
                            maxLength: 20,
                        },
                        invalidMessage: '',
                    },
                },

                formHasError: false,
                formSubmitting: false,

                bootstrapAlertContent: null,

                rememberMe: false,
            },
        };

        // Regular Functions
        this.validateFormHasErrorBeforeSubmit       =  this.validateFormHasErrorBeforeSubmit.bind(this);

        // Event Handlers
        this.inputValueChangeHandler                =  this.inputValueChangeHandler.bind(this);
        this.rememberMeCheckboxChangeHandler        =  this.rememberMeCheckboxChangeHandler.bind(this);
        this.loginFormSubmitHandler                 =  this.loginFormSubmitHandler.bind(this);
        this.bootstrapAlertCloseButtonClickHandler  =  this.bootstrapAlertCloseButtonClickHandler.bind(this);
        this.passwordShowButtonClickHandler         =  this.passwordShowButtonClickHandler.bind(this);
    }


    componentDidMount() {
        const authToken = utils.validateIsAuthenticated();
        if (authToken != false) {
            this.props.history.replace('/dashboard');
        }

        this.setState({
            loginForm: {
                ...this.state.loginForm,
                bootstrapAlertContent: utils.showInterPageMessageUsingBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, this.props.interPageMessage, this.props.interPageMessageType),
            }
        });

        // Unset redux inter-page message
        this.props.processedInterPageMessage();
    }


    inputValueChangeHandler(formEleName, event) {
        this.setState({
            loginForm: {
                ...utils.inputValueChangedHelper(formEleName, event.target.value, this.state.loginForm)
            }
        });
    }


    rememberMeCheckboxChangeHandler(event) {
        this.setState({
            loginForm: {
                ...this.state.loginForm,
                rememberMe: event.target.checked ? true : false,
            },
        });
    }


    validateFormHasErrorBeforeSubmit() {
        let formHasError = false;
        for (let eleName in this.state.loginForm.fields) {
            const element = this.state.loginForm.fields[eleName];

            const validationResponse = utils.inputValueChangedHelper(eleName, element.value, this.state.loginForm);
            this.setState({
                loginForm: {
                    ...validationResponse
                }
            });
            if (validationResponse.formHasError) {
                formHasError = true;
            }
        }

        return formHasError;
    }


    loginFormSubmitHandler(event) {
        event.preventDefault();

        if (this.validateFormHasErrorBeforeSubmit()) {
            return false;
        }

        this.setState({
            loginForm: {
                ...this.state.loginForm,
                formSubmitting: true,
            }
        });

        let postFormData = {
            email: this.state.loginForm.fields.email.value,
            password: this.state.loginForm.fields.password.value,
        };

        if (this.state.loginForm.rememberMe) {
            postFormData = {
                ...postFormData,
                rememberMe: 1,
            };
        }

        ApiAxios.post(this.state.loginForm.url, postFormData)
            .then((response) => {
                this.props.addAuthToken(response.data.data.token);
                this.props.history.push({
                    pathname: '/dashboard',
                    state: {},
                });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({
                        loginForm: {
                            ...this.state.loginForm,
                            ...utils.showValidationErrorAfterFormSubmit(error.response.data, this.state.loginForm),
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                        }
                    });
                } else {
                    this.setState({
                        loginForm: {
                            ...this.state.loginForm,
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                        }
                    });
                }
                this.setState({
                    loginForm: {
                        ...this.state.loginForm,
                        formHasError: true,
                    }
                });
            })
            .then(() => {
                this.setState({
                    loginForm: {
                        ...this.state.loginForm,
                        formSubmitting: false,
                    }
                });
            });
    }


    bootstrapAlertCloseButtonClickHandler(event) {
        this.setState({
            loginForm: {
                ...this.state.loginForm,
                bootstrapAlertContent: null,
            },
        });
    }


    passwordShowButtonClickHandler(formEleName, event)
    {
        const loginForm = this.state.loginForm;
        if (this.state.loginForm.fields[formEleName].elementConfig.type == 'password') {
            loginForm.fields[formEleName].elementConfig.type = 'text';
        } else {
            loginForm.fields[formEleName].elementConfig.type = 'password';
        }
        this.setState({
            loginForm: {
                ...loginForm,
            }
        })
    }


    render()
    {

        return(
            <GuestOnlyCommonPageLayout onFormSubmit={this.loginFormSubmitHandler} mainTitle="Welcome Back !" subTitle="Sign In to continue to EsyTodo." showForgotPasswordSection socialMediaLinkTitle="Sign In with" footerLinkLabel="Don't have an account ? " footerLink="/register" footerLinkContent="Sign Up" >

                { this.state.loginForm.bootstrapAlertContent }

                { utils.renderFormGroupComponentHelper(this.state.loginForm.fields, this.inputValueChangeHandler, this.passwordShowButtonClickHandler) }

                <div className="custom-control custom-checkbox">
                    <input
                        type="checkbox" className="custom-control-input" id="customControlInline"
                        checked={this.state.loginForm.rememberMe}
                        onChange={this.rememberMeCheckboxChangeHandler} />
                    <label className="custom-control-label" htmlFor="customControlInline">Keep me logged in (For 14 Days)</label>
                    <br />
                    <small>Check it only for Home / Personal Devices</small>
                </div>

                <div style={{marginTop:'1.5rem'}}>
                    <div className="mt-3">
                        <SubmitButton
                            label="Log In"
                            formHasError={this.state.loginForm.formHasError}
                            isSubmitting={this.state.loginForm.formSubmitting} />
                    </div>
                </div>
            </GuestOnlyCommonPageLayout>
        );
    }
}


// Redux State Management
const mapStateToProps = (curState) => {
    return {
        interPageMessage: curState.interPageMessage,
        interPageMessageType: curState.interPageMessageType,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addAuthToken: (token) => {
            return dispatch({type: actionTypes.LOGIN, token: token});
        },
        processedInterPageMessage: () => {
            return dispatch({type: actionTypes.INTER_PAGE_MESSAGE_PROCESSED});
        },
    };
};


// Export Components
export default connect(mapStateToProps, mapDispatchToProps)(Login);