// Import React, Supporting Libraries
import React from 'react';
import { connect } from 'react-redux';


// Import HOC Components
import withFormFeatures from '../../../hoc/guest_only/withFormFeatures/withFormFeatures';


// Other Components
import ApiAxios from '../../../vendors/axios/api';
import * as utils from '../../../utils/utils';
import * as actionTypes from '../../../vendors/redux/state/actions';


// Import Functional Components
import GuestOnlyCommonPageLayout from '../../../components/guest_only/CommonPageLayout/CommonPageLayout';
import SubmitButton from '../../../components/form/SubmitButton/SubmitButton';


// Class Component
class ForgotPassword extends React.Component
{

    constructor(props) {
        super(props);

        this.state = {
            forgotPasswordForm: {
                url: '/guest/forgot-password',

                fields: {
                    email: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'email',
                            placeholder: 'Your registered email address',
                            required: true,
                            minLength: 5,
                            maxLength: 50,
                        },
                        value: '',
                        label: 'Email Address',
                        validation: {
                            required: true,
                            minLength: 5,
                            maxLength: 50,
                        },
                        invalidMessage: '',
                    },
                },

                formHasError: false,
                formSubmitting: false,

                bootstrapAlertContent: null,
            },
        };

        // Regular Functions
        this.validateFormHasErrorBeforeSubmit       =  this.validateFormHasErrorBeforeSubmit.bind(this);

        // Event Handlers
        this.inputValueChangeHandler                =  this.inputValueChangeHandler.bind(this);
        this.forgotPasswordFormSubmitHandler        =  this.forgotPasswordFormSubmitHandler.bind(this);
        this.bootstrapAlertCloseButtonClickHandler  =  this.bootstrapAlertCloseButtonClickHandler.bind(this);
    }


    componentDidMount() {
        const authToken = utils.validateIsAuthenticated();
        if (authToken != false) {
            this.props.history.replace('/secure/todos');
        }
    }


    inputValueChangeHandler(formEleName, event) {
        this.setState({
            forgotPasswordForm: {
                ...utils.inputValueChangedHelper(formEleName, event.target.value, this.state.forgotPasswordForm)
            }
        });
    }


    validateFormHasErrorBeforeSubmit() {
        let formHasError = false;
        for (let eleName in this.state.forgotPasswordForm.fields) {
            const element = this.state.forgotPasswordForm.fields[eleName];

            const validationResponse = utils.inputValueChangedHelper(eleName, element.value, this.state.forgotPasswordForm);
            this.setState({
                forgotPasswordForm: {
                    ...validationResponse
                }
            });
            if (validationResponse.formHasError) {
                formHasError = true;
            }
        }

        return formHasError;
    }


    forgotPasswordFormSubmitHandler(event) {
        event.preventDefault();

        if (this.validateFormHasErrorBeforeSubmit()) {
            return false;
        }

        this.setState({
            forgotPasswordForm: {
                ...this.state.forgotPasswordForm,
                formSubmitting: true,
                bootstrapAlertContent: null,
            }
        });

        const postFormData = {
            email: this.state.forgotPasswordForm.fields.email.value,
        };

        ApiAxios.post(this.state.forgotPasswordForm.url, postFormData)
            .then((response) => {
                this.props.addInterPageMessage(response.data.message, 'success');
                this.props.history.push({
                    pathname: '/login',
                    state: {},
                });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({
                        forgotPasswordForm: {
                            ...this.state.forgotPasswordForm,
                            ...utils.showValidationErrorAfterFormSubmit(error.response.data, this.state.forgotPasswordForm),
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                        }
                    });
                } else {
                    this.setState({
                        forgotPasswordForm: {
                            ...this.state.forgotPasswordForm,
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                        }
                    });
                }
                this.setState({
                    forgotPasswordForm: {
                        ...this.state.forgotPasswordForm,
                        formHasError: true,
                    }
                });
            })
            .then(() => {
                this.setState({
                    forgotPasswordForm: {
                        ...this.state.forgotPasswordForm,
                        formSubmitting: false,
                    }
                });
            });
    }


    bootstrapAlertCloseButtonClickHandler(event) {
        this.setState({
            forgotPasswordForm: {
                ...this.state.forgotPasswordForm,
                bootstrapAlertContent: null,
            },
        });
    }


    render()
    {

        return(
            <GuestOnlyCommonPageLayout onFormSubmit={this.forgotPasswordFormSubmitHandler} mainTitle="Forgot Password" subTitle="Reset and get a new password from EsyTodo." footerLinkLabel="Remembered your Password ? " footerLink="/login" footerLinkContent="Sign In" >
                <div className="alert alert-primary text-center mb-4" role="alert">
                    Enter your Email and instructions will be sent to you!
                </div>

                { this.state.forgotPasswordForm.bootstrapAlertContent }

                { utils.renderFormGroupComponentHelper(this.state.forgotPasswordForm.fields, this.inputValueChangeHandler) }

                <div className="form-group row mb-0 justify-content-end">
                    <div className="col-5">
                        <SubmitButton
                            label="Reset"
                            formHasError={this.state.forgotPasswordForm.formHasError}
                            isSubmitting={this.state.forgotPasswordForm.formSubmitting} />
                    </div>
                </div>
            </GuestOnlyCommonPageLayout>
        );
    }
}


// Redux State Management
const mapStateToProps = (curState) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addInterPageMessage: (message, messageType) => {
            return dispatch({type: actionTypes.INTER_PAGE_MESSAGE_ADDED, message: message, messageType: messageType});
        },
    };
};


// Export Components
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);