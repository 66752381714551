// Import React, Supporting Libraries
import React from 'react';
import { connect } from 'react-redux';


// Other Components
import ApiAxios from '../../../vendors/axios/api';
import * as utils from '../../../utils/utils';
import * as actionTypes from '../../../vendors/redux/state/actions';


// Class Component
class VerifyAccount extends React.Component
{

    constructor(props)
    {
        super(props);
        this.state = {
            verifyAccountForm: {
                url: '/guest/register/verify-account',
            },
        };
    }


    componentDidMount() {
        const authToken = utils.validateIsAuthenticated();
        if (authToken != false) {
            this.props.history.replace('/secure/todos');
        }

        if (this.props.match && this.props.match.params && this.props.match.params.token) {
            const postFormData = {
                verificationToken: this.props.match.params.token,
            };

            ApiAxios.post(this.state.verifyAccountForm.url, postFormData)
                .then((response) => {
                    this.props.addInterPageMessage(response.data.message, 'success');
                    this.props.history.push({
                        pathname: '/login',
                        state: {},
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        this.props.addInterPageMessage(error.response.data.message, 'error');
                        this.props.history.push({
                            pathname: '/',
                            state: {},

                        });                        
                    } else {
                        this.props.addInterPageMessage('Your request could not be processed right now, please try again later', 'error');
                        this.props.history.push({
                            pathname: '/',
                            state: {},
                        });
                    }
                })
                .then(() => {
                    
                });

        } else {
            this.props.addInterPageMessage('Invalid account verification token', 'error');
            this.props.history.push({
                pathname: '/',
                state: {},
            });
        }
    }


    render()
    {

        return (
            <div>
                <h3>EsyTodo</h3>
                <p>Activating account, please wait.............</p>
            </div>
        );
    }
}


// Redux State Management
const mapStateToProps = (curState) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addInterPageMessage: (message, messageType) => {
            return dispatch({type: actionTypes.INTER_PAGE_MESSAGE_ADDED, message: message, messageType: messageType});
        },
    };
};


// Export Components
export default connect(mapStateToProps, mapDispatchToProps)(VerifyAccount);