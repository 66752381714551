export const LOGIN       =  'LOGIN';
export const LOGOUT      =  'LOGOUT';
export const AUTH_CHECK  =  'AUTH_CHECK';


export const NEW_TODO_GOT_ADDED = 'NEW_TODO_GOT_ADDED';
export const NEW_TODO_GOT_PROCESSED = 'NEW_TODO_GOT_PROCESSED';

export const INTER_PAGE_MESSAGE_ADDED = 'INTER_PAGE_MESSAGE_ADDED';
export const INTER_PAGE_MESSAGE_PROCESSED = 'INTER_PAGE_MESSAGE_PROCESSED';

export const NEW_PROJECT_GOT_CREATED = 'NEW_PROJECT_GOT_CREATED';
export const NEW_PROJECT_GOT_PROCESSED = 'NEW_PROJECT_GOT_PROCESSED';
export const PROJECT_GOT_UPDATED = 'PROJECT_GOT_UPDATED';

export const QUICK_EDIT_PROJECT = 'QUICK_EDIT_PROJECT';

export const QUICK_EDIT_TODO            =  'QUICK_EDIT_TODO';
export const QUICK_EDIT_TODO_PROCESSED  =  'QUICK_EDIT_TODO_PROCESSED';

export const QUICK_MIGRATE_TODO_PROJECT            =  'QUICK_MIGRATE_TODO_PROJECT';
export const QUICK_MIGRATE_TODO_PROJECT_PROCESSED  =  'QUICK_MIGRATE_TODO_PROJECT_PROCESSED';