// Import React, Support Libraries
import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';


// Import Css
import './App.css';


// Import Pages Components
import LoginPage from './pages/guest_only/Login/Login';
import RegisterPage from './pages/guest_only/Register/Register';
import ForgotPasswordPage from './pages/guest_only/ForgotPassword/ForgotPassword';
import VerifyAccountPage from './pages/guest_only/VerifyAccount/VerifyAccount';
import ResetPasswordPage from './pages/guest_only/ResetPassword/ResetPassword';

import MyProfilePage from './pages/auth_only/MyProfile/MyProfile';
import ActiveTodoListPage from './pages/auth_only/ActiveTodoList/ActiveTodoList';
import PastCompletedTodoListPage from './pages/auth_only/PastCompletedTodoList/PastCompletedTodoList';
import TodoDetailsPage from './pages/auth_only/TodoDetails/TodoDetails';
import GlobalSearchResultsListPage from './pages/auth_only/GlobalSearchResultsList/GlobalSearchResultsList';
import DashboardPage from "./pages/auth_only/Dashboard/Dashboard";

import ErrorPage404 from "./pages/common/404/404";
import LogoutPage from './pages/common/Logout/Logout';
import ViewAttachmentFilePage from "./pages/ViewAttachmentFile/ViewAttachmentFile";
import AllTodayTodoListPage from "./pages/auth_only/AllTodayTodoList/AllTodayTodoList";
import AllTomorrowTodoListPage from "./pages/auth_only/AllTomorrowTodoList/AllTomorrowTodoList";
import AllOverdueTodoListPage from "./pages/auth_only/AllOverdueTodoList/AllOverdueTodoList";


// Other Components
import * as utils from './utils/utils';


// Class Component
class App extends React.Component
{

    componentDidMount()
    {
        // Preparing BODY TAG for UI Template specific settings
        document.getElementsByTagName("body")[0].setAttribute('data-topbar', 'dark');
        document.getElementsByTagName("body")[0].setAttribute('data-layout', 'horizontal');

        // Import / Append JS files at the end of BODY tag of the page
        utils.appendScriptToBodySync('/assets/libs/jquery/jquery.min.js');
        utils.appendScriptToBodySync('/assets/libs/bootstrap/js/bootstrap.bundle.min.js');
        utils.appendScriptToBodySync('/assets/libs/metismenu/metisMenu.min.js');
        utils.appendScriptToBodySync('/assets/libs/simplebar/simplebar.min.js');
        utils.appendScriptToBodySync('/assets/libs/node-waves/waves.min.js');
        utils.appendScriptToBodySync('/assets/libs/sweetalert2/sweetalert2.min.js');
        utils.appendScriptToBodySync('/assets/js/pages/sweet-alerts.init.js');
        utils.appendScriptToBodySync('/assets/js/app.js');
        utils.appendScriptToBodySync('https://cdnjs.cloudflare.com/ajax/libs/toastr.js/latest/toastr.min.js');
    }


    render()
    {
        return (
            <React.Fragment>

                {/*App favicon*/}
                <link rel="shortcut icon" href={process.env.PUBLIC_URL+'/assets/images/favicon.ico'} />

                {/*Bootstrap Css*/}
                <link href={process.env.PUBLIC_URL+'/assets/css/bootstrap.min.css'} id="bootstrap-style" rel="stylesheet" type="text/css" />

                {/*Icons Css*/}
                <link href={process.env.PUBLIC_URL+'/assets/css/icons.min.css'} rel="stylesheet" type="text/css" />

                {/*App Css*/}
                <link href={process.env.PUBLIC_URL+'/assets/css/app.min.css'} id="app-style" rel="stylesheet" type="text/css" />

                {/*Toastr Css*/}
                <link rel="stylesheet" href='https://cdnjs.cloudflare.com/ajax/libs/toastr.js/latest/toastr.min.css' />

                <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />



                <BrowserRouter>
                    <Switch>
                        {/* Guest Only Routes */}
                        <Route path="/login" exact component={LoginPage} />
                        <Route path="/register/verify-account/:token" exact component={VerifyAccountPage} />
                        <Route path="/register" exact component={RegisterPage} />
                        <Route path="/reset-password/:token" exact component={ResetPasswordPage} />
                        <Route path="/forgot-password" exact component={ForgotPasswordPage} />

                        {/* Secured Routes */}
                        <Route path="/my-profile" exact component={MyProfilePage} />
                        <Route path="/dashboard" exact component={DashboardPage} />
                        <Route path="/project/:projectId/active-todos" exact component={ActiveTodoListPage} />
                        <Route path="/project/:projectId/past-completed-todos" exact component={PastCompletedTodoListPage} />
                        <Route path="/project/:projectId/todo/:todoId/details" exact component={TodoDetailsPage} />
                        <Route path="/todo/search/:todoTitle" exact component={GlobalSearchResultsListPage} />
                        <Route path="/project/:projectId/todo/:todoId/attachment/:attachmentId/view-attachment-file" exact component={ViewAttachmentFilePage} />
                        <Route path="/all-todos/today" exact component={AllTodayTodoListPage} />
                        <Route path="/all-todos/tomorrow" exact component={AllTomorrowTodoListPage} />
                        <Route path="/all-todos/overdue" exact component={AllOverdueTodoListPage} />

                        {/* Open Routes */}
                        <Route path="/logout" exact component={LogoutPage} />
                        <Route path="/" exact component={LoginPage} />
                        <Route path="" component={ErrorPage404} />
                    </Switch>
                </BrowserRouter>

            </React.Fragment>
        );
    }
}


// Export Components
export default App;