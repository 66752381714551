// Import React, Supporting Libraries
import React from 'react';
import Axios from '../../../Axios/Axios';
import * as utils from '../../../utils/utils';


// Import CSS
// import './VerifyAccount.css';


// Class Component
class VerifyAccount extends React.Component
{

    constructor(props)
    {
        super(props);
        this.state = {
            formUrl: '/guest/register/verify-account',
        };
    }


    componentDidMount() {
        const authToken = utils.validateIsAuthenticated();
        if (authToken != false) {
            this.props.history.replace('/secure/todos');
        }

        if (this.props.match && this.props.match.params && this.props.match.params.token) {
            const postFormData = {
                verificationToken: this.props.match.params.token,
            };

            Axios.post(this.state.formUrl, postFormData)
                .then((response) => {
                    this.props.history.push({
                        pathname: '/login',
                        state: {
                            successMessage: response.data.message,
                        },
                    });
                })
                .catch((error) => {                   
                    console.log(error.response);
                    if (error.response) {
                        this.props.history.push({
                            pathname: '/',
                            state: {
                                errorMessage: error.response.data.message,
                            },
                        });                        
                    } else {
                        this.props.history.push({
                            pathname: '/',
                            state: {
                                errorMessage: 'Your request could not be processed right now, please try again later',
                            },
                        });
                    }
                    // else if (error.request) {
                    //     console.log('[Resgister POST Error Request Caught]', error.request);
                    // }
                    // console.log('[Resgister POST Error Message Caught]', error.message);
                })
                .then(() => {
                    
                });

        } else {
            this.props.history.push({
                pathname: '/',
                state: {
                    errorMessage: 'Invalid account verification token',
                },
            });
        }
        
    }

    render() {
        return (
            <div>
                <h3>EsyTodo</h3>
                <p>Activating account, please wait.............</p>
            </div>
        );
    }
}


// Export Components
export default VerifyAccount;