// Import React, Supporting Libraries
import React from 'react';
import { Link } from 'react-router-dom';


// Import Css Module
import './CommonPageLayout.css';


// Other Components
import HeaderComponent from '../Header/Header';
import FooterComponent from '../Footer/Footer';
import CreateProjectComponent from "../../../containers/auth_only/CreateProject/CreateProject";
import EditProjectComponent from "../../../containers/auth_only/EditProject/EditProject";
import CreateNewGenericTodoComponent from "../../../containers/auth_only/CreateNewGenericTodo/CreateNewGenericTodo";

// Functional Component
const commonPageLayout = (props) => {

    return (

        <React.Fragment>
            <div id="layout-wrapper">
                <HeaderComponent />

                <CreateProjectComponent />
                <EditProjectComponent />
                <CreateNewGenericTodoComponent />

                <div className="main-content">
                    <div className="page-content" style={{marginTop: '1.5rem'}}>
                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">{props.pageTitle}</h4>

                                        {
                                            props.breadcrumbLinks ?
                                                <div className="page-title-right">
                                                    <ol className="breadcrumb m-0">
                                                        {props.breadcrumbLinks}
                                                    </ol>
                                                </div>
                                            : null
                                        }

                                    </div>
                                </div>
                            </div>

                            { props.children }

                        </div>
                    </div>
                    <FooterComponent />
                </div>
            </div>
        </React.Fragment>
    );
}


// Export Components
export default commonPageLayout;