// Import Axios, Supporting Libraries
import Axios from 'axios';


// Custom Axios Component
const AxiosInstance = Axios.create({
    baseURL: process.env.REACT_APP_ESYTODO_BACKEND_BASE_URL,
    headers: {

    }
});


// Export Components
export default AxiosInstance;