// Import React, Supporting Libraries Libraries
import React from "react";
import {NavLink} from "react-router-dom";


// Functional Component
const genericQuickActionButtons = (props) => {

    let todaysTodosButton = null;
    if (props.currentPage != 'allTodayTodoList') {
        todaysTodosButton = <NavLink to="/all-todos/today" className="btn btn-danger btn-block waves-effect waves-light">View Today's Todos</NavLink>;
    }

    let tomorrowTodosButton = null;
    if (props.currentPage != 'allTomorrowTodoList') {
        tomorrowTodosButton = <NavLink to="/all-todos/tomorrow" className="btn btn-primary btn-block waves-effect waves-light">View Tomorrow's Todos</NavLink>;
    }

    let overdueTodosButton = null;
    if (props.currentPage != 'allOverdueTodoList') {
        overdueTodosButton = <NavLink to="/all-todos/overdue" className="btn btn-secondary btn-block waves-effect waves-light">View Overdue Todos</NavLink>;
    }

    return (
        <div style={{color:'white'}}>
            <h5>Quick Actions</h5>
            <hr />
            <a className="btn btn-warning btn-block waves-effect waves-light" data-toggle="modal" data-target="#createNewProjectModal">Create New Project</a>
            <a className="btn btn-info btn-block waves-effect waves-light" data-toggle="modal" data-target="#createNewGenericTodoModal">Create New Todo</a>
            { todaysTodosButton }
            { tomorrowTodosButton }
            { overdueTodosButton }
        </div>
    );
}


// Export Components
export default genericQuickActionButtons;