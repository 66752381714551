// Import React, Supporting Libraries
import React from 'react';
import { Link } from 'react-router-dom';


// Other Components
import CommonPageLayout from '../../../components/auth_only/CommonPageLayout/CommonPageLayout';
import ApiAxios from '../../../vendors/axios/api';
import * as utils from '../../../utils/utils';


// Import Containers
import PersonalDetails from '../../../containers/auth_only/PersonalDetails/PersonalDetails';
import ChangePassword from '../../../containers/auth_only/ChangePassword/ChangePassword';


// Class Component
class MyProfile extends React.Component
{

    componentDidMount()
    {
        const authToken = utils.validateIsAuthenticated(this.props);
        if (authToken != false) {
            ApiAxios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
        } else {
            utils.redirectToLogin(this.props);
        }
    }


    render()
    {

        const breadcrumbLinks = (
            <React.Fragment>
                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                <li className="breadcrumb-item active">My Profile Page</li>
            </React.Fragment>
        );

        return (

            <CommonPageLayout breadcrumbLinks={breadcrumbLinks} pageTitle="My Profile">

                <div className="row justify-content-center">
                    <div className="col-lg-5">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title mb-4">Change Password</h4>

                                <ChangePassword {...this.props} />

                                {/*<form style={{marginTop:'2rem'}}>*/}
                                {/*    <div className="form-group row mb-6">*/}
                                {/*        <label htmlFor="horizontal-firstname-input" className="col-sm-5 col-form-label">New Password</label>*/}
                                {/*        <div className="col-sm-7">*/}
                                {/*            <input type="password" className="form-control" id="horizontal-firstname-input" />*/}
                                {/*        </div>*/}
                                {/*    </div>*/}

                                {/*    <div className="form-group row mb-6">*/}
                                {/*        <label htmlFor="horizontal-password-input" className="col-sm-5 col-form-label">Retype New Password</label>*/}
                                {/*        <div className="col-sm-7">*/}
                                {/*            <input type="password" className="form-control" id="horizontal-password-input" />*/}
                                {/*        </div>*/}
                                {/*    </div>*/}

                                {/*    <div className="form-group row justify-content-end">*/}
                                {/*        <div className="col-sm-7">*/}
                                {/*            <button type="submit" className="btn btn-primary w-md">Update Password</button>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</form>*/}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title mb-4">Personal Details</h4>

                                <PersonalDetails {...this.props} />

                                {/*<form style={{marginTop:'2rem'}}>*/}
                                {/*    <div className="form-group row mb-4">*/}
                                {/*        <label htmlFor="horizontal-firstname-input" className="col-sm-4 col-form-label">First Name</label>*/}
                                {/*        <div className="col-sm-8">*/}
                                {/*            <input type="text" className="form-control" id="horizontal-firstname-input" />*/}
                                {/*        </div>*/}
                                {/*    </div>*/}

                                {/*    <div className="form-group row mb-4">*/}
                                {/*        <label htmlFor="horizontal-firstname-input" className="col-sm-4 col-form-label">Last Name</label>*/}
                                {/*        <div className="col-sm-8">*/}
                                {/*            <input type="text" className="form-control" id="horizontal-firstname-input" />*/}
                                {/*        </div>*/}
                                {/*    </div>*/}

                                {/*    <div className="form-group row justify-content-end">*/}
                                {/*        <div className="col-sm-8">*/}
                                {/*            <button type="submit" className="btn btn-primary w-md">Update Details</button>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</form>*/}
                            </div>
                        </div>
                    </div>
                </div>

            </CommonPageLayout>
        );
    }
}


// Export Components
export default MyProfile;