// Import React, Supporting Libraries Libraries
import React from 'react';
import Axios from '../../../Axios/Axios';
import { toast } from 'react-toastify';
import * as utils from '../../../utils/utils';


// Import Css Module
import classes from './ResetPassword.module.css';


// Import Other Components
import FormGroupInput from '../../../components/Form/FormGroupInput/FormGroupInput';
import SubmitButton from '../../../components/Form/SubmitButton/SubmitButton';


// Class Component
class ResetPassword extends React.Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            formUrl: '/guest/forgot-password/reset-password',
            
            resetPasswordToken: null,

            formData: {
                password: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'password',
                        placeholder: 'New Password for your account',
                        required: true,
                    },
                    value: '',
                    label: 'New Password',
                    validation: {
                        required: true,
                        minLength: 5,
                        maxLength: 20,
                    },
                    invalidMessage: '',
                },
                password_confirmation: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'password',
                        placeholder: 'Retype new password again to confirm it',
                        required: true,
                    },
                    value: '',
                    label: 'Retype Password',
                    validation: {
                        required: true,
                        minLength: 5,
                        maxLength: 20,
                    },
                    invalidMessage: '',
                },
            },

            formHasError: false,
            formSubmitting: false,
        }

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }


    componentDidMount() {
        const authToken = utils.validateIsAuthenticated();
        if (authToken != false) {
            this.props.history.replace('/secure/todos');
        }

        Axios.get('/guest/forgot-password/verify-token?forgotPasswordToken='+this.props.match.params.token)
            .then((response) => {
                console.log(response.data);
                if (response.data.status !== 'success') {
                    this.props.history.push({
                        pathname: '/',
                        state: {
                            errorMessage: response.data.message,
                        },
                    });
                } else {
                    this.setState({
                        resetPasswordToken: this.props.match.params.token,
                    });
                }
            })
            .catch((error) => {
                if (error.response) {
                    this.props.history.push({
                        pathname: '/',
                        state: {
                            errorMessage: error.response.data.message,
                        },
                    });                    
                } else {
                    this.props.history.push({
                        pathname: '/',
                        state: {
                            errorMessage: 'Your request could not be processed right now, please try again later',
                        },
                    });
                }
            })
            .then(() => {
                
            });
    }


    handleInputValueChange = (formEleName, event) => {
        const updatedFormData = this.state.formData;
        const updatedFormEle = updatedFormData[formEleName];
        
        updatedFormEle['value'] = event.target.value;
        updatedFormEle['invalidMessage'] = this.validateInputValue(event.target.value, updatedFormEle['validation']);
        
        let formHasError = false;
        if (updatedFormEle['invalidMessage'] !== '') {
            formHasError = true;
        }

        updatedFormData[formEleName] = updatedFormEle;

        this.setState({
            formData: updatedFormData,
            formHasError: formHasError,
        });
    }


    validateInputValue(inputValue, validationRules) {
        let validationErrorMessage = '';

        if (validationRules['required'] === true) {
            if (inputValue === '') {
                validationErrorMessage = 'This field cannot be empty';
            }
        }

        if (validationErrorMessage === '' && validationRules['validValues']) {
            validationErrorMessage = 'Please set a valid value from predefined list';
            for (let value in validationRules['validValues']) {
                if (value === inputValue) {
                    validationErrorMessage = '';
                }
            }
        }

        if (validationErrorMessage === '' && validationRules['minLength'])
        {
            if (inputValue.length < validationRules['minLength']) {
                validationErrorMessage = 'Please set a value with character length at least: '+validationRules['minLength']+' characters';
            }
        }

        if (validationErrorMessage === '' && validationRules['maxLength'])
        {
            if (inputValue.length > validationRules['maxLength']) {
                validationErrorMessage = 'Please set a value with character length not more than: '+validationRules['maxLength']+' characters';
            }
        }

        if (validationErrorMessage === '' && validationRules['sizeLength'])
        {
            if (inputValue.length !== validationRules['sizeLength']) {
                validationErrorMessage = 'Please set a value with character length exactly: '+validationRules['sizeLength']+' characters';
            }
        }

        return validationErrorMessage;
    }


    showValidationErrorAfterFormSubmit(errorResponsePayload) {

        const updatedFormData = this.state.formData;

        for (let fieldName in this.state.formData) {
            if (errorResponsePayload.data[fieldName] && errorResponsePayload.data[fieldName][0]) {
                const updatedFormEle = updatedFormData[fieldName];
                updatedFormEle['invalidMessage'] = errorResponsePayload.data[fieldName][0];
                updatedFormData[fieldName] = updatedFormEle;
            }
        }

        this.setState({
            formData: updatedFormData,
            formHasError: true,
        });
    }


    handleFormSubmit(event)
    {
        event.preventDefault();

        this.setState({
            formSubmitting: true,
        });

        const postFormData = {
            resetPasswordToken: this.state.resetPasswordToken,
            password: this.state.formData.password.value,
            password_confirmation: this.state.formData.password_confirmation.value,
        };

        Axios.post(this.state.formUrl, postFormData)
            .then((response) => {
                this.props.history.push({
                    pathname: '/login',
                    state: {
                        successMessage: response.data.message,
                    },
                });
            })
            .catch((error) => {
                if (error.response) {
                    toast(error.response.data.message, {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });

                    this.showValidationErrorAfterFormSubmit(error.response.data);
                    
                } else {
                    toast('Your request could not be processed right now, please try again later', {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });
                }
                this.setState({
                    formSubmitting: false,
                });
            })
            .then(() => {
                
            });
    }


    render() {
        let formFields = [];
        for (let eleName in this.state.formData) {
            const element = this.state.formData[eleName];
            formFields.push(
                <FormGroupInput 
                    key={eleName}
                    name={eleName}
                    elementType={element.elementType} 
                    value={element.value}
                    label={element.label}
                    invalidMessage={element.invalidMessage}
                    elementConfig={element.elementConfig}
                    changeHandler={this.handleInputValueChange.bind(this, eleName)}
                    />
            );
        }

        return (
            <div className={'container ' + classes.MyContainer}>
                <div className="row">
                    <div className="col-md-5 offset-md-4">
                        <h3>Update Password</h3>
                        <form onSubmit={this.handleFormSubmit}>
                            <br />

                            {formFields}

                            <SubmitButton formHasError={this.state.formHasError} label="Update Password" isSubmitting={this.state.formSubmitting} />
                            <small className="form-text text-muted">* Marked fields are mandatory.</small>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}


// Export Component
export default ResetPassword