// Import Actions
import * as actionTypes from './actions';

// Setting initial state
const initialState = {
    token: {},
    isAuthenticated: false,

    newTodoGotAdded: false,

    interPageMessage: null,
    interPageMessageType: null,

    newProjectGotCreated: false,
    projectGotUpdated: false,

    projectId: null,
    projectTitle: null,
    projectDescription: null,

    quickEditTodoId: null,
    quickEditTodoTitle: null,
    quickEditTodoDueDate: null,
    quickEditTodoPriority: null,
    quickEditTodoProjectId: null,

    quickMigrateTodoId: null,
};

// Functional Component
const reducer = (curState = initialState, action) => {
    switch(action.type) {
        case actionTypes.AUTH_CHECK:
            let updatedState1 = {
                ...curState,
                token: {},
                isAuthenticated: false,
            };
            const accessToken = localStorage.getItem('access_token');
            const expiresInSecs = localStorage.getItem('expires_in_secs');
            const tokenType = localStorage.getItem('token_type');

            // Using Axios, validate this token

            if (accessToken && expiresInSecs && tokenType) {
                updatedState1 = {
                    ...curState,
                    token: {
                        access_token: accessToken,
                        expires_in_secs: expiresInSecs,
                        token_type: tokenType,
                    },
                    isAuthenticated: true,
                };
            }
            return updatedState1;
        case actionTypes.LOGIN:
            localStorage.setItem('access_token', action.token.access_token);
            localStorage.setItem('expires_in_secs', action.token.expires_in_secs);
            localStorage.setItem('token_type', action.token.type);
            const updatedState = {
                ...curState,
                token: {...action.token},
                isAuthenticated: true,
            };
            return updatedState;
        case actionTypes.LOGOUT:
            localStorage.clear();
            return {
                ...curState,
                token: {},
                isAuthenticated: false,
            };
        case actionTypes.NEW_TODO_GOT_ADDED:
            return {
                ...curState,
                newTodoGotAdded: true,
            };
        case actionTypes.NEW_TODO_GOT_PROCESSED:
            return {
                ...curState,
                newTodoGotAdded: false,
            };
        case actionTypes.INTER_PAGE_MESSAGE_ADDED:
            let message = null;
            let messageType = null;

            if (action.message !== '') {
                message = action.message;
            }

            if (action.messageType.toLowerCase() === 'success' || action.messageType.toLowerCase() === 'error') {
                messageType = action.messageType.toLowerCase();
            }

            if (message !== null && messageType !== null) {
                return {
                    ...curState,
                    interPageMessage: message,
                    interPageMessageType: messageType,
                };
            }
            return {
                ...curState,
            };
        case actionTypes.INTER_PAGE_MESSAGE_PROCESSED:
            return {
                ...curState,
                interPageMessage: null,
                interPageMessageType: null,
            };
        case actionTypes.NEW_PROJECT_GOT_CREATED:
            return {
                ...curState,
                newProjectGotCreated: true,
            };
        case actionTypes.NEW_PROJECT_GOT_PROCESSED:
            return {
                ...curState,
                newProjectGotCreated: false,
                projectGotUpdated: false,

                projectId: null,
                projectTitle: null,
                projectDescription: null,
            };
        case actionTypes.PROJECT_GOT_UPDATED:
            return {
                ...curState,
                projectGotUpdated: true,
            };
        case actionTypes.QUICK_EDIT_PROJECT:
            return {
                ...curState,
                projectId           :  action.id,
                projectTitle        :  action.title,
                projectDescription  :  action.description,
            };
        case actionTypes.QUICK_EDIT_TODO:
            return {
                ...curState,
                quickEditTodoId         :  action.id,
                quickEditTodoTitle      :  action.title,
                quickEditTodoDueDate    :  action.dueDate,
                quickEditTodoPriority   :  action.priority,
                quickEditTodoProjectId  :  action.projectId,
            };
        case actionTypes.QUICK_EDIT_TODO_PROCESSED:
            return {
                ...curState,
                quickEditTodoId         :  null,
                quickEditTodoTitle      :  null,
                quickEditTodoDueDate    :  null,
                quickEditTodoPriority   :  null,
                quickEditTodoProjectId  :  null,
            };
        case actionTypes.QUICK_MIGRATE_TODO_PROJECT:
            return {
                ...curState,
                quickMigrateTodoId: action.id,
            };
        case actionTypes.QUICK_MIGRATE_TODO_PROJECT_PROCESSED:
            return {
                ...curState,
                quickMigrateTodoId: null,
            };
    }

    return curState;
}

// Export Components
export default reducer;