// Import React, Supporting Libraries
import React from 'react';
import { connect } from 'react-redux';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate, } from 'react-day-picker/moment';


// Import Css
import 'react-day-picker/lib/style.css';


// Other Components
import ApiAxios from '../../../vendors/axios/api';
import * as utils from '../../../utils/utils';
import * as actionTypes from '../../../vendors/redux/state/actions';
import FormGroupInput from '../../../components/form/FormGroupInput/FormGroupInput';
import SubmitButton from '../../../components/form/SubmitButton/SubmitButton';


// Class Component
class CreateNewGenericTodo extends React.Component
{

    constructor(props)
    {
        super(props);

        this.state = {

            addTodoForm: {
                fields: {
                    title: {
                        elementType: 'textarea',
                        elementConfig: {
                            type: 'text',
                            placeholder: 'New todo content',
                            required: true,
                            rows: 15,
                            minLength: 2,
                            maxLength: 5000,
                        },
                        value: '',
                        label: 'Todo',
                        customStyle: {
                            resize: 'none',
                        },
                        validation: {
                            required: true,
                            minLength: 2,
                            maxLength: 5000,
                        },
                        invalidMessage: '',
                    },
                    priority: {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {value: '1', displayValue:'Highest'},
                                {value: '2', displayValue:'High'},
                                {value: '3', displayValue:'Medium'},
                                {value: '4', displayValue:'Low'},
                                {value: '5', displayValue:'Lowest'},
                            ]
                        },
                        value: '3',
                        label: 'Priority',
                        validation: {
                            required: true,
                            validValues: ['5', '4', '3', '2', '1'],
                        },
                        invalidMessage: '',
                    },
                    dueDate: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            placeholder: 'Due date',
                            required: true,
                            'data-provide': 'datepicker',
                        },
                        value: utils.getCurrentSystemDate(true),
                        label: 'Due Date',
                        validation: {
                            required: true,
                            minLength: 2,
                            maxLength: 10,
                        },
                        invalidMessage: '',
                    },
                },

                projectId: null,
                projectList: {},
                projectListBootstrapAlertContent: null,

                formHasError: false,
                formSubmitting: false,

                dayPickerSelectedDay: undefined,

                bootstrapAlertContent: null,
            },
        };


        // Regular Functions
        this.validateFormHasErrorBeforeSubmit       =  this.validateFormHasErrorBeforeSubmit.bind(this);

        // Event Handlers
        // this.handleDayPickerDayChange = this.handleDayPickerDayChange.bind(this);
        this.inputValueChangeHandler                =  this.inputValueChangeHandler.bind(this);
        this.dayPickerDayChangeHandler              =  this.dayPickerDayChangeHandler.bind(this);
        this.addTodoFormSubmitHandler               =  this.addTodoFormSubmitHandler.bind(this);
        this.bootstrapAlertCloseButtonClickHandler  =  this.bootstrapAlertCloseButtonClickHandler.bind(this);
        this.projectChangeHandler                   =  this.projectChangeHandler.bind(this);
    }


    componentDidMount()
    {
        const authToken = utils.validateIsAuthenticated(this.props);
        if (authToken != false) {
            ApiAxios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
        } else {
            utils.redirectToLogin(this.props);
            // this.props.history.replace('/login');
        }

        this.getProjectList();
    }


    getProjectList()
    {
        const getParams = {
            pageNo: 1,
            resultPerPage: 50,
        };

        const getUrl = '/secure/project/list';

        this.setState({
            projectListBootstrapAlertContent: null,
        });

        ApiAxios.get(getUrl, {
            params: getParams
        })
            .then((response) => {
                const rawProjectList = response.data.data;
                this.setState({
                    projectList : rawProjectList,
                });
                const defaultProject = rawProjectList[0]['p__id'];
                if (defaultProject) {
                    this.setState({
                        projectId: defaultProject,
                    });
                }
                // this.getDropdownValuesFromList(response.data.data);
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        projectListBootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                    });
                } else {
                    this.setState({
                        projectListBootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                    });
                }
            })
            .then(() => {

            });
    }


    getDropdownValuesFromList(projectList = null)
    {
        const projectListArray = [];

        let loopList = null;
        if (projectList) {
            loopList = projectList;
        } else {
            loopList = this.state.projectList;
        }

        let counter = 0;
        for (const projectKey in loopList)
        {
            const projectEle = loopList[projectKey];

            projectListArray.push(
                <option key={projectEle['p__id']} value={projectEle['p__id']}>{utils.getProjectDisplpayTitle(projectEle['p__title'], 60)}</option>
            );

            counter++;
        }

        return projectListArray;
    }


    inputValueChangeHandler(formEleName, event)
    {
        this.setState({
            addTodoForm: {
                ...utils.inputValueChangedHelper(formEleName, event.target.value, this.state.addTodoForm)
            }
        });
    }


    dayPickerDayChangeHandler(selectedDay, modifiers, dayPickerInput)
    {
        const updatedFormData = this.state.addTodoForm;
        const updatedFormEle = updatedFormData.fields.dueDate;

        updatedFormEle['value'] = formatDate(selectedDay, 'YYYY-MM-DD'); // event.target.value;
        // updatedFormEle['invalidMessage'] = this.validateInputValue(updatedFormEle['value'], updatedFormEle['validation']);

        let formHasError = false;
        // if (updatedFormEle['invalidMessage'] !== '') {
        //     formHasError = true;
        // }

        updatedFormData.fields.dueDate = updatedFormEle;

        this.setState({
            addTodoForm: {
                ...updatedFormData,
                formHasError: formHasError,
                dayPickerSelectedDay: selectedDay,
            },
        });
    }


    validateFormHasErrorBeforeSubmit()
    {
        let formHasError = false;
        for (let eleName in this.state.addTodoForm.fields) {
            const element = this.state.addTodoForm.fields[eleName];

            const validationResponse = utils.inputValueChangedHelper(eleName, element.value, this.state.addTodoForm);
            this.setState({
                addTodoForm: {
                    ...validationResponse
                }
            });
            if (validationResponse.formHasError) {
                formHasError = true;
            }
        }

        return formHasError;
    }


    addTodoFormSubmitHandler(event)
    {
        event.preventDefault();

        if (this.validateFormHasErrorBeforeSubmit()) {
            return false;
        }

        if (!this.state.projectId) {
            alert('Before trying to create a new todo first please select a project in which to add this new todo');
            return false;
        }

        this.setState({
            addTodoForm: {
                ...this.state.addTodoForm,
                formSubmitting: true,
                bootstrapAlertContent: null,
            }
        });

        const postFormData = {
            title     :  this.state.addTodoForm.fields.title.value,
            priority  :  this.state.addTodoForm.fields.priority.value,
            dueDate   :  this.state.addTodoForm.fields.dueDate.value,
            project   :  this.state.projectId,
        };

        ApiAxios.post('/secure/project/todo/create', postFormData)
            .then((response) => {

                this.props.newTodoGotAdded();

                const updatedFormData = this.state.addTodoForm;
                updatedFormData.fields.title.value = '';
                this.setState({
                    addTodoForm: {
                        ...updatedFormData
                    },
                });

                // document.getElementById('addNewTodoModal').modal('hide');
                var newModal = document.getElementById('createNewGenericTodoModal');
                var closeArr = newModal.getElementsByClassName('close');
                closeArr[0].click();

            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        addTodoForm: {
                            ...this.state.addTodoForm,
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                        },
                    });
                } else {
                    this.setState({
                        addTodoForm: {
                            ...this.state.addTodoForm,
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                        },
                    });
                }
            })
            .then(() => {
                this.setState({
                    addTodoForm: {
                        ...this.state.addTodoForm,
                        formSubmitting: false,
                    },
                });
            });
    }


    bootstrapAlertCloseButtonClickHandler(event)
    {
        this.setState({
            todoDetailsForm: {
                ...this.state.todoDetailsForm,
                bootstrapAlertContent: null,
            },
            bootstrapAlertContent: null,
        });
    }


    projectChangeHandler(event)
    {
        this.setState({
            projectId: event.target.value
        });
    }


    render()
    {
        let modalInnerHtml = <form onSubmit={this.addTodoFormSubmitHandler}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Create a New Todo</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        { this.state.addTodoForm.bootstrapAlertContent }

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label htmlFor='migrate'>* Project</label>
                                    <select id='migrate' className="form-control" onChange={this.projectChangeHandler.bind(this)}>
                                        { this.getDropdownValuesFromList() }
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="dueDate">* Due Date</label>
                                    <DayPickerInput
                                        style={{display: 'block'}}
                                        inputProps={{className: 'form-control', readOnly: true,}}
                                        dayPickerProps={{disabledDays: {before: new Date()},}}
                                        hideOnDayClick={true}
                                        onDayChange={this.dayPickerDayChangeHandler}
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        placeholder={`${formatDate(new Date(), 'MMM Do, YYYY')}`}
                                        selectedDays={this.state.addTodoForm.dayPickerSelectedDay}
                                        value={`${formatDate(this.state.addTodoForm.dayPickerSelectedDay, 'MMM Do, YYYY')}`} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <FormGroupInput
                                    name='priority'
                                    elementType={this.state.addTodoForm.fields.priority.elementType}
                                    value={this.state.addTodoForm.fields.priority.value}
                                    label={this.state.addTodoForm.fields.priority.label}
                                    invalidMessage={this.state.addTodoForm.fields.priority.invalidMessage}
                                    elementConfig={this.state.addTodoForm.fields.priority.elementConfig}
                                    changeHandler={this.inputValueChangeHandler.bind(this, 'priority')} />
                            </div>
                        </div>
                        <FormGroupInput
                            name='title'
                            elementType={this.state.addTodoForm.fields.title.elementType}
                            customStyle={this.state.addTodoForm.fields.title.customStyle}
                            value={this.state.addTodoForm.fields.title.value}
                            label={this.state.addTodoForm.fields.title.label}
                            invalidMessage={this.state.addTodoForm.fields.title.invalidMessage}
                            elementConfig={this.state.addTodoForm.fields.title.elementConfig}
                            changeHandler={this.inputValueChangeHandler.bind(this, 'title')}
                        />
                    </div>
                    <div className="modal-footer">
                        <div className="row justify-content-end" style={{width:'100%'}}>
                            <div className="col-sm-2" style={{marginBottom:'1rem'}}>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            </div>
                            <div className="col-sm-4">
                                <SubmitButton
                                    label="Add Todo"
                                    formHasError={this.state.formHasError}
                                    isSubmitting={this.state.formSubmitting} />
                            </div>
                        </div>
                    </div>
                </div>
            </form>;

        if (!this.state.projectList) {
            modalInnerHtml =
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Create a New Todo</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            Please create a project before creating any todos.
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>;
        }

        const modal = <div className="modal fade" data-show="true" id="createNewGenericTodoModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                { modalInnerHtml }
            </div>
        </div>;

        return modal;
    }
}


// Redux State Management
const mapStateToProps = (curState) => {
    return {

    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        newTodoGotAdded: () => {
            return dispatch({type: actionTypes.NEW_TODO_GOT_ADDED})
        },
        addInterPageMessage: (message, messageType) => {
            return dispatch({type: actionTypes.INTER_PAGE_MESSAGE_ADDED, message: message, messageType: messageType});
        },
    };
};


// Export Component
export default connect(mapStateToProps, mapDispatchToProps)(CreateNewGenericTodo);