// Import React, Supporting Libraries
import React from 'react';


// Other Components
import WebAxios from '../../vendors/axios/web';


// Class Component
class ViewAttachmentFile extends React.Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            fileContent: null,
            fileUrl: null,
        };
    }


    componentDidMount()
    {
        const attachmentId = this.props.match.params.attachmentId;
        const todoId = this.props.match.params.todoId;

        const getParams = {
            todo: todoId,
            attachment: attachmentId,
            project: this.props.match.params.projectId,
        };

        const otherConfigs = {
            responseType: 'blob',
        };

        // Send Axios request to get content of this file
        WebAxios.get('/secure/project/todo/attachment/view-file', {
            params: getParams,
            ...otherConfigs,
        })
            .then((response) => {
                const blobConfig = {
                    type: response.headers['content-type'],
                    encoding: 'UTF-8',
                };

                const file = new Blob([response.data], blobConfig);
                const fileURL = URL.createObjectURL(file);

                window.open(fileURL, '_self');

                this.setState({
                    fileContent: response.data,
                    fileUrl: fileURL,
                });
            })
            .catch((error) => {
                this.props.history.replace('/404');
            })
            .then(() => {

            });
    }

    render()
    {
        let fileContent = <h1>404 File Not Found</h1>;
        if (this.state.fileContent != null) {
            fileContent = <img src={this.state.fileContent} />;
        }

        // const data = this.state.fileContent;
        // const url = window.URL.createObjectURL(data);

        // working
        // return <img src={this.state.fileUrl} />;

        return null;
    }
}


// Export Components
export default ViewAttachmentFile;