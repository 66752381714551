// Import React, Supporting Libraries
import React from 'react';
import { Link } from 'react-router-dom';


// Functional Component
const commonPageLayout = (props) => {

    let socialMediaLinkSection = null;
    if (props.socialMediaLinkTitle && props.socialMediaLinkTitle.length > 0) {
        socialMediaLinkSection = (
            <div className="mt-4 text-center">
                <h5 className="font-size-14 mb-3">{props.socialMediaLinkTitle}</h5>
                <ul className="list-inline">
                    <li className="list-inline-item">
                        <a href="javascript::void()" className="social-list-item bg-primary text-white border-primary">
                            <i className="mdi mdi-facebook"></i>
                        </a>
                    </li>
                    <li className="list-inline-item">
                        <a href="javascript::void()" className="social-list-item bg-info text-white border-info">
                            <i className="mdi mdi-twitter"></i>
                        </a>
                    </li>
                    <li className="list-inline-item">
                        <a href="javascript::void()" className="social-list-item bg-danger text-white border-danger">
                            <i className="mdi mdi-google"></i>
                        </a>
                    </li>
                </ul>
            </div>
        );
    }

    // Social Media Logins usually only work for production ready sites with proper documentation, links to policies etc
    // so dropping this feature for this internal/test project
    socialMediaLinkSection = null;


    return (

        <React.Fragment>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark"><i className="fas fa-home h2"></i></Link>
                {/*<a href="index.html" className="text-dark"><i className="fas fa-home h2"></i></a>*/}
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="card overflow-hidden">
                                <div className="bg-soft-primary">
                                    <div className="row">
                                        <div className="col-7">
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">{props.mainTitle}</h5>
                                                <p>{props.subTitle}</p>
                                            </div>
                                        </div>
                                        <div className="col-5 align-self-end">
                                            <img src="/assets/images/profile-img.png" alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body pt-0">
                                    <div>
                                        <Link to="/">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                    <span className="avatar-title rounded-circle bg-light">
                                                        <img src="/assets/images/logo.svg" alt="" className="rounded-circle" height="34" />
                                                    </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2">
                                        <form className="form-horizontal" onSubmit={props.onFormSubmit}>

                                            {/*<div className="form-group">*/}
                                            {/*    <label htmlFor="username">Username</label>*/}
                                            {/*    <input type="text" className="form-control" id="username" placeholder="Enter username" />*/}
                                            {/*</div>*/}

                                            {/*<div className="form-group">*/}
                                            {/*    <label htmlFor="userpassword">Password</label>*/}
                                            {/*    <input type="password" className="form-control" id="userpassword" placeholder="Enter password" />*/}
                                            {/*</div>*/}

                                            {/*<div className="custom-control custom-checkbox">*/}
                                            {/*    <input type="checkbox" className="custom-control-input" id="customControlInline" />*/}
                                            {/*    <label className="custom-control-label" htmlFor="customControlInline">Remember me</label>*/}
                                            {/*</div>*/}

                                            {/*<div className="mt-3">*/}
                                            {/*    <button className="btn btn-primary btn-block waves-effect waves-light" type="submit">Log In</button>*/}
                                            {/*</div>*/}

                                            {props.children}


                                            { socialMediaLinkSection }


                                            {
                                                props.showForgotPasswordSection === true ?
                                                    <div className="mt-4 text-center">
                                                        <Link to="/forgot-password" className="text-muted">
                                                            <i className="mdi mdi-lock mr-1"></i> Forgot your password?
                                                        </Link>
                                                    </div>
                                                : null
                                            }


                                            {
                                                props.showTermsOfUseSection === true ?
                                                    <div className="mt-4 text-center">
                                                        <p className="mb-0">By registering you agree to the EsyTodo <a href="#" className="text-primary">Terms of Use</a></p>
                                                    </div>
                                                    : null
                                            }

                                        </form>
                                    </div>

                                </div>
                            </div>

                            <div className="mt-5 text-center">
                                <div>
                                    <p>{props.footerLinkLabel}<Link to={props.footerLink} className="font-weight-medium text-primary">{props.footerLinkContent}</Link></p>
                                    <p>© { new Date().getFullYear() } EsyTodo. Crafted with <i className="mdi mdi-heart text-danger"></i> by Aditya Sharma</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};


// Export Components
export default commonPageLayout;