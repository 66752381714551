// Import React, Supporting Libraries
import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Axios from './Axios/Axios';
import { connect } from 'react-redux';
import * as actionTypes from './redux/state/actions';


// Import CSS Modules
import 'react-toastify/dist/ReactToastify.css';


// Import Pages
import HomePage from './pages/Home';
import RegisterPage from './pages/guest/Register/Register';
import VerifyAccountPage from './pages/guest/VerifyAccount/VerifyAccount';
import LoginPage from './pages/guest/Login/Login';
import ForgotPasswordPage from './pages/guest/ForgotPassword/ForgotPassword';
import ResetPasswordPage from './pages/guest/ResetPassword/ResetPassword';

import TodoListPage from './pages/secured/TodoList/TodoList';
import PersonalDetailsPage from './pages/secured/MyProfile/PersonalDetails/PersonalDetails';
import ChangePasswordPage from './pages/secured/MyProfile/ChangePassword/ChangePassword';
import LogoutPage from './pages/Logout';
import TodoDetailsPage from './pages/secured/TodoDetails/TodoDetails';
import PastCompletedTodoListPage from './pages/secured/PastCompletedTodoList/PastCompletedTodoList';
import GlobalSearchResultsListPage from './pages/secured/GlobalSearchResultsList/GlobalSearchResultsList';
import ViewAttachmentFilePage from './pages/secured/ViewAttachmentFile/ViewAttachmentFile';


// Import Components
import MainNavHeader from './components/Navigation/MainNavHeader/MainNavHeader';


// Other Components
import * as utils from './utils/utils';


// Class Component
class App extends React.Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            isAuthenticated: false,
        };
    }


    componentDidMount()
    {
        // const authToken = utils.validateIsAuthenticated();
        // if (authToken != false) {
        //     Axios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
        // } else {
        //     utils.clearAuth(null, null, false);
        // }

        // Import / Append JS files at the end of BODY tag of the page
        utils.appendScriptToBodySync('https://code.jquery.com/jquery-3.5.1.slim.min.js');
        utils.appendScriptToBodySync('https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js');
        utils.appendScriptToBodySync('https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/js/bootstrap.min.js');
    }

    render()
    {
        let guestOnlyRoutes = (
            <React.Fragment>
                <Route path="/login" exact component={LoginPage} />
                <Route path="/reset-password/:token" exact component={ResetPasswordPage} />
                <Route path="/forgot-password" exact component={ForgotPasswordPage} />
                <Route path="/register/verify-account/:token" exact component={VerifyAccountPage} />
                <Route path="/register" exact component={RegisterPage} />
            </React.Fragment>
        );

        let secureOnlyRoutes = (
            <React.Fragment>
                <Route path="/secure/todos/:id/details" exact component={TodoDetailsPage} />
                <Route path="/secure/todos" exact component={TodoListPage} />
                <Route path="/secure/past-completed-todos" exact component={PastCompletedTodoListPage} />
                
                <Route path="/secure/my-profile/personal-details" exact component={PersonalDetailsPage} />
                <Route path="/secure/my-profile/change-password" exact component={ChangePasswordPage} />
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <link rel="icon" href={process.env.PUBLIC_URL+'/favicon.ico'} />
                <link rel="stylesheet" href='https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css' integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk" crossOrigin="anonymous" />

                <BrowserRouter>
                    <Switch>
                        {/* Guest Only Routes */}
                        <Route path="/login" exact component={LoginPage} />
                        <Route path="/reset-password/:token" exact component={ResetPasswordPage} />
                        <Route path="/forgot-password" exact component={ForgotPasswordPage} />
                        <Route path="/register/verify-account/:token" exact component={VerifyAccountPage} />
                        <Route path="/register" exact component={RegisterPage} />


                        {/* Secured Routes */}
                        <Route path="/secure/todos/search/:title" exact component={GlobalSearchResultsListPage} />
                        <Route path="/secure/todos/:id/details" exact component={TodoDetailsPage} />
                        <Route path="/secure/todos/:todoId/attachment/:attachmentId/view-attachment-file" exact component={ViewAttachmentFilePage} />
                        <Route path="/secure/todos" exact component={TodoListPage} />
                        <Route path="/secure/past-completed-todos" exact component={PastCompletedTodoListPage} />

                        <Route path="/secure/my-profile/personal-details" exact component={PersonalDetailsPage} />
                        <Route path="/secure/my-profile/change-password" exact component={ChangePasswordPage} />


                        {/* Open Routes */}
                        <Route path="/logout" exact component={LogoutPage} />
                        <Route path="/" exact component={LoginPage} />
                    </Switch>
                    <ToastContainer />
                </BrowserRouter>
            </React.Fragment>
        );
    }
}


// Redux State Management
const mapStateToProps = (curState) => {
    return {
        token: curState.token,
        isAuthenticated: curState.isAuthenticated,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        addAuthToken: (token) => {
            return dispatch({type: actionTypes.LOGIN, token: token})
        },
        getAuthToken: () => {
            return dispatch({type: actionTypes.AUTH_CHECK})
        },
        clearAuthToken: () => {
            return dispatch({type: actionTypes.LOGOUT})
        }
    };
};


// Export Components
export default connect(mapStateToProps, mapDispatchToProps)(App);