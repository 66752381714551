// Import React, Supporting Libraries
import React from 'react';
import { connect } from 'react-redux';


// Other Components
import ApiAxios from '../../../vendors/axios/api';
import * as utils from '../../../utils/utils';
import * as actionTypes from '../../../vendors/redux/state/actions';


// Import Functional Components
import GuestOnlyCommonPageLayout from '../../../components/guest_only/CommonPageLayout/CommonPageLayout';
import SubmitButton from '../../../components/form/SubmitButton/SubmitButton';


// Class Component
class Register extends React.Component
{

    constructor(props) {
        super(props);

        this.state = {
            registerForm: {
                url: '/guest/register',

                fields: {
                    firstName: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            placeholder: 'Your first name',
                            required: true,
                            minLength: 2,
                            maxLength: 30,
                        },
                        value: '',
                        label: 'First Name',
                        validation: {
                            required: true,
                            minLength: 2,
                            maxLength: 30,
                        },
                        invalidMessage: '',
                    },
                    lastName: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            placeholder: 'Your last name',
                            required: true,
                            minLength: 2,
                            maxLength: 30,
                        },
                        value: '',
                        label: 'Last Name',
                        validation: {
                            required: true,
                            minLength: 2,
                            maxLength: 30,
                        },
                        invalidMessage: '',
                    },
                    email: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'email',
                            placeholder: 'Your existing email address',
                            required: true,
                            minLength: 5,
                            maxLength: 50,
                        },
                        value: '',
                        label: 'Email Address',
                        validation: {
                            required: true,
                            minLength: 5,
                            maxLength: 50,
                        },
                        invalidMessage: '',
                    },
                    password: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'password',
                            placeholder: 'Password for your new EsyTodo account',
                            required: true,
                            minLength: 5,
                            maxLength: 20,
                        },
                        isPasswordField: true,
                        value: '',
                        label: 'Password',
                        validation: {
                            required: true,
                            minLength: 5,
                            maxLength: 20,
                        },
                        invalidMessage: '',
                    },
                },

                formHasError: false,
                formSubmitting: false,

                bootstrapAlertContent: null,
            },
        };

        // Regular Functions
        this.validateFormHasErrorBeforeSubmit       =  this.validateFormHasErrorBeforeSubmit.bind(this);

        // Event Handlers
        this.inputValueChangeHandler                =  this.inputValueChangeHandler.bind(this);
        this.registerFormSubmitHandler              =  this.registerFormSubmitHandler.bind(this);
        this.bootstrapAlertCloseButtonClickHandler  =  this.bootstrapAlertCloseButtonClickHandler.bind(this);
        this.passwordShowButtonClickHandler         =  this.passwordShowButtonClickHandler.bind(this);
    }


    componentDidMount() {
        const authToken = utils.validateIsAuthenticated();
        if (authToken != false) {
            this.props.history.replace('/secure/todos');
        }
    }


    inputValueChangeHandler(formEleName, event) {
        this.setState({
            registerForm: {
                ...utils.inputValueChangedHelper(formEleName, event.target.value, this.state.registerForm)
            }
        });
    }


    validateFormHasErrorBeforeSubmit() {
        let formHasError = false;
        for (let eleName in this.state.registerForm.fields) {
            const element = this.state.registerForm.fields[eleName];

            const validationResponse = utils.inputValueChangedHelper(eleName, element.value, this.state.registerForm);
            this.setState({
                registerForm: {
                    ...validationResponse
                }
            });
            if (validationResponse.formHasError) {
                formHasError = true;
            }
        }

        return formHasError;
    }


    registerFormSubmitHandler(event) {
        event.preventDefault();

        if (this.validateFormHasErrorBeforeSubmit()) {
            return false;
        }

        this.setState({
            registerForm: {
                ...this.state.registerForm,
                formSubmitting: true,
            }
        });

        const postFormData = {
            firstName: this.state.registerForm.fields.firstName.value,
            lastName: this.state.registerForm.fields.lastName.value,
            email: this.state.registerForm.fields.email.value,
            password: this.state.registerForm.fields.password.value,
        };

        ApiAxios.post(this.state.registerForm.url, postFormData)
            .then((response) => {
                this.props.addInterPageMessage(response.data.message, 'success');
                this.props.history.push({
                    pathname: '/login',
                    state: {},
                });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({
                        registerForm: {
                            ...this.state.registerForm,
                            ...utils.showValidationErrorAfterFormSubmit(error.response.data, this.state.registerForm),
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                        }
                    });
                } else {
                    this.setState({
                        registerForm: {
                            ...this.state.registerForm,
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                        }
                    });
                }
                this.setState({
                    registerForm: {
                        ...this.state.registerForm,
                        formHasError: true,
                    }
                });
            })
            .then(() => {
                this.setState({
                    registerForm: {
                        ...this.state.registerForm,
                        formSubmitting: false,
                    }
                });
            });
    }


    bootstrapAlertCloseButtonClickHandler(event) {
        this.setState({
            registerForm: {
                ...this.state.registerForm,
                bootstrapAlertContent: null,
            },
        });
    }


    passwordShowButtonClickHandler(formEleName, event)
    {
        const registerForm = this.state.registerForm;
        if (this.state.registerForm.fields[formEleName].elementConfig.type == 'password') {
            registerForm.fields[formEleName].elementConfig.type = 'text';
        } else {
            registerForm.fields[formEleName].elementConfig.type = 'password';
        }
        this.setState({
            registerForm: {
                ...registerForm,
            }
        })
    }


    render()
    {

        return(
            <GuestOnlyCommonPageLayout onFormSubmit={this.registerFormSubmitHandler} mainTitle="Register For Free !" subTitle="Create your free EsyTodo account now." showTermsOfUseSection socialMediaLinkTitle="Sign Up with" footerLinkLabel="Already have an account ? " footerLink="/login" footerLinkContent="Sign In" >

                { this.state.registerForm.bootstrapAlertContent }

                { utils.renderFormGroupComponentHelper(this.state.registerForm.fields, this.inputValueChangeHandler, this.passwordShowButtonClickHandler) }

                <div className="mt-4">
                    <SubmitButton
                        label="Register"
                        formHasError={this.state.registerForm.formHasError}
                        isSubmitting={this.state.registerForm.formSubmitting} />
                </div>
            </GuestOnlyCommonPageLayout>
        );
    }
}


// Redux State Management
const mapStateToProps = (curState) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addInterPageMessage: (message, messageType) => {
            return dispatch({type: actionTypes.INTER_PAGE_MESSAGE_ADDED, message: message, messageType: messageType});
        },
    };
};


// Export Components
export default connect(mapStateToProps, mapDispatchToProps)(Register);