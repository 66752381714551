// Import React, Supporting Libraries
import React from 'react';
import { connect } from 'react-redux';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate, } from 'react-day-picker/moment';


// Import Css
import 'react-day-picker/lib/style.css';


// Other Components
import ApiAxios from '../../../vendors/axios/api';
import * as utils from '../../../utils/utils';
import * as actionTypes from '../../../vendors/redux/state/actions';
import FormGroupInput from '../../../components/form/FormGroupInput/FormGroupInput';
import SubmitButton from '../../../components/form/SubmitButton/SubmitButton';


// Class Component
class QuickEditTodo extends React.Component
{

    constructor(props)
    {
        super(props);

        this.state = {

            editTodoForm: {
                url: '/secure/project/todo/edit',

                fields: {
                    title: {
                        elementType: 'textarea',
                        elementConfig: {
                            type: 'text',
                            placeholder: 'Update todo content',
                            required: true,
                            rows: 15,
                            minLength: 2,
                            maxLength: 5000,
                        },
                        value: '',
                        label: 'Todo',
                        customStyle: {
                            resize: 'none',
                        },
                        validation: {
                            required: true,
                            minLength: 2,
                            maxLength: 5000,
                        },
                        invalidMessage: '',
                    },
                    priority: {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {value: '1', displayValue:'Highest'},
                                {value: '2', displayValue:'High'},
                                {value: '3', displayValue:'Medium'},
                                {value: '4', displayValue:'Low'},
                                {value: '5', displayValue:'Lowest'},
                            ]
                        },
                        value: '3',
                        label: 'Priority',
                        validation: {
                            required: true,
                            validValues: ['5', '4', '3', '2', '1'],
                        },
                        invalidMessage: '',
                    },
                    dueDate: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            placeholder: 'Due date',
                            required: true,
                            minLength: 2,
                            maxLength: 10,
                            'data-provide': 'datepicker',
                        },
                        value: utils.getCurrentSystemDate(true),
                        label: 'Due Date',
                        validation: {
                            required: true,
                            minLength: 2,
                            maxLength: 10,
                        },
                        invalidMessage: '',
                    },
                },

                formHasError: false,
                formSubmitting: false,

                dayPickerSelectedDay: undefined,

                bootstrapAlertContent: null,
            },
        };


        // Regular Functions
        this.getTodoDetails                         =  this.getTodoDetails.bind(this);
        this.validateFormHasErrorBeforeSubmit       =  this.validateFormHasErrorBeforeSubmit.bind(this);

        // Event Handlers
        // this.handleDayPickerDayChange = this.handleDayPickerDayChange.bind(this);
        this.inputValueChangeHandler                =  this.inputValueChangeHandler.bind(this);
        this.dayPickerDayChangeHandler              =  this.dayPickerDayChangeHandler.bind(this);
        this.editTodoFormSubmitHandler              =  this.editTodoFormSubmitHandler.bind(this);
        this.bootstrapAlertCloseButtonClickHandler  =  this.bootstrapAlertCloseButtonClickHandler.bind(this);
    }


    componentDidMount()
    {
        const authToken = utils.validateIsAuthenticated(this.props);
        if (authToken != false) {
            ApiAxios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
        } else {
            utils.redirectToLogin(this.props);
        }

        // this.getTodoDetails();
    }


    componentDidUpdate(prevProps, prevState, snapshot)
    {
        // prevProps.newTodoGotAdded !== this.props.newTodoGotAdded
        if (this.props.quickEditTodoId != prevProps.quickEditTodoId && this.props.quickEditTodoId && this.props.quickEditTodoProjectId) {
            this.getTodoDetails();
        }
    }


    getTodoDetails()
    {
        const todoDetailsURL = '/secure/project/todo/details';
        const getParams = {
            todo: this.props.quickEditTodoId,
            project: this.props.quickEditTodoProjectId,
        };

        this.setState({
            editTodoForm: {
                ...this.state.editTodoForm,
                bootstrapAlertContent: null,
            },
        });

        ApiAxios.get(todoDetailsURL, {
            params: getParams
        })
            .then((response) => {

                const dueDateArray = response.data.data.t__due_date.split(" ");

                const updatedTodoDetailsFormData = this.state.editTodoForm;

                updatedTodoDetailsFormData.fields.title.value     =  response.data.data.t__title;
                updatedTodoDetailsFormData.fields.priority.value  =  response.data.data.t__priority;
                updatedTodoDetailsFormData.fields.dueDate.value   =  dueDateArray[0];

                this.setState({
                    editTodoForm: {
                        ...updatedTodoDetailsFormData,
                        dayPickerSelectedDay: new Date(parseDate(dueDateArray[0], 'YYYY-MM-DD')),
                    },
                });

            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        editTodoForm: {
                            ...this.state.editTodoForm,
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                        },
                    });
                } else {
                    this.setState({
                        editTodoForm: {
                            ...this.state.editTodoForm,
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                        },
                    });
                }
            })
            .then(() => {

            });
    }


    inputValueChangeHandler(formEleName, event)
    {
        this.setState({
            editTodoForm: {
                ...utils.inputValueChangedHelper(formEleName, event.target.value, this.state.editTodoForm)
            }
        });
    }


    dayPickerDayChangeHandler(selectedDay, modifiers, dayPickerInput)
    {
        const updatedFormData = this.state.editTodoForm;
        const updatedFormEle = updatedFormData.fields.dueDate;
        
        updatedFormEle['value'] = formatDate(selectedDay, 'YYYY-MM-DD'); // event.target.value;
        // updatedFormEle['invalidMessage'] = this.validateInputValue(updatedFormEle['value'], updatedFormEle['validation']);
        
        let formHasError = false;
        // if (updatedFormEle['invalidMessage'] !== '') {
        //     formHasError = true;
        // }

        updatedFormData.fields.dueDate = updatedFormEle;
        
        this.setState({
            editTodoForm: {
                ...updatedFormData,
                formHasError: formHasError,
                dayPickerSelectedDay: selectedDay,
            },
        });
    }


    validateFormHasErrorBeforeSubmit()
    {
        let formHasError = false;
        for (let eleName in this.state.editTodoForm.fields) {
            const element = this.state.editTodoForm.fields[eleName];

            const validationResponse = utils.inputValueChangedHelper(eleName, element.value, this.state.editTodoForm);
            this.setState({
                editTodoForm: {
                    ...validationResponse
                }
            });
            if (validationResponse.formHasError) {
                formHasError = true;
            }
        }

        return formHasError;
    }


    editTodoFormSubmitHandler(event)
    {
        event.preventDefault();

        if (this.validateFormHasErrorBeforeSubmit()) {
            return false;
        }

        this.setState({
            editTodoForm: {
                ...this.state.editTodoForm,
                formSubmitting: true,
                bootstrapAlertContent: null,
            }
        });

        const postFormData = {
            todo      :  this.props.quickEditTodoId,
            title     :  this.state.editTodoForm.fields.title.value,
            priority  :  this.state.editTodoForm.fields.priority.value,
            dueDate   :  this.state.editTodoForm.fields.dueDate.value,
            project   :  this.props.quickEditTodoProjectId,
        };

        ApiAxios.post(this.state.editTodoForm.url, postFormData)
            .then((response) => {

                this.props.quickEditTodoUpdated();
                window.$('#quickEditTodoModal').modal('hide');
                this.props.todoQuickUpdatedEventHandler(this.props.quickEditTodoId);

                // const updatedFormData = this.state.editTodoForm;
                // updatedFormData.fields.title.value = '';
                // this.setState({
                //     editTodoForm: {
                //         ...updatedFormData
                //     },
                // });

                // var newModal = document.getElementById('addNewTodoModal');
                // var closeArr = newModal.getElementsByClassName('close');
                // closeArr[0].click();

            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        editTodoForm: {
                            ...this.state.editTodoForm,
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                        },
                    });
                } else {
                    this.setState({
                        editTodoForm: {
                            ...this.state.editTodoForm,
                            bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                        },
                    });
                }
            })
            .then(() => {
                this.setState({
                    editTodoForm: {
                        ...this.state.editTodoForm,
                        formSubmitting: false,
                    },
                });
            });
    }


    bootstrapAlertCloseButtonClickHandler(event)
    {
        this.setState({
            todoDetailsForm: {
                ...this.state.todoDetailsForm,
                bootstrapAlertContent: null,
            },
            bootstrapAlertContent: null,
        });
    }


    render()
    {
        return (
            // Modal
            <div className="modal fade" data-show="true" id="quickEditTodoModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <form onSubmit={this.editTodoFormSubmitHandler}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Quick Edit Todo</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                { this.state.editTodoForm.bootstrapAlertContent }

                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="dueDate">* Due Date</label>
                                            <DayPickerInput
                                                style={{display: 'block'}}
                                                inputProps={{className: 'form-control', readOnly: true,}}
                                                dayPickerProps={{disabledDays: {before: new Date()},}}
                                                hideOnDayClick={true}
                                                onDayChange={this.dayPickerDayChangeHandler}
                                                formatDate={formatDate}
                                                parseDate={parseDate}
                                                placeholder={`${formatDate(new Date(), 'MMM Do, YYYY')}`}
                                                selectedDays={this.state.editTodoForm.dayPickerSelectedDay}
                                                value={`${formatDate(this.state.editTodoForm.dayPickerSelectedDay, 'MMM Do, YYYY')}`} />
                                        </div>
                                        {/* <FormGroupInput
                                            name='dueDate'
                                            elementType={this.state.formData.newTodoDueDate.elementType}
                                            value={this.state.formData.newTodoDueDate.value}
                                            label={this.state.formData.newTodoDueDate.label}
                                            invalidMessage={this.state.formData.newTodoDueDate.invalidMessage}
                                            elementConfig={this.state.formData.newTodoDueDate.elementConfig}
                                            changeHandler={this.handleInputValueChange.bind(this, 'newTodoDueDate')} /> */}
                                    </div>
                                    <div className="col-sm-6">
                                        <FormGroupInput
                                            name='priority'
                                            elementType={this.state.editTodoForm.fields.priority.elementType}
                                            value={this.state.editTodoForm.fields.priority.value}
                                            label={this.state.editTodoForm.fields.priority.label}
                                            invalidMessage={this.state.editTodoForm.fields.priority.invalidMessage}
                                            elementConfig={this.state.editTodoForm.fields.priority.elementConfig}
                                            changeHandler={this.inputValueChangeHandler.bind(this, 'priority')} />
                                    </div>
                                </div>
                                <FormGroupInput
                                    name='title'
                                    elementType={this.state.editTodoForm.fields.title.elementType}
                                    customStyle={this.state.editTodoForm.fields.title.customStyle}
                                    value={this.state.editTodoForm.fields.title.value}
                                    label={this.state.editTodoForm.fields.title.label}
                                    invalidMessage={this.state.editTodoForm.fields.title.invalidMessage}
                                    elementConfig={this.state.editTodoForm.fields.title.elementConfig}
                                    changeHandler={this.inputValueChangeHandler.bind(this, 'title')}
                                    />
                            </div>
                            <div className="modal-footer">
                                <div className="row justify-content-end" style={{width:'100%'}}>
                                    <div className="col-sm-2" style={{marginBottom:'1rem'}}>
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                    </div>
                                    <div className="col-sm-4">
                                        <SubmitButton
                                            label="Update Todo"
                                            formHasError={this.state.formHasError}
                                            isSubmitting={this.state.formSubmitting} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}


// Redux State Management
const mapStateToProps = (curState) => {
    return {
        quickEditTodoId         :  curState.quickEditTodoId,
        quickEditTodoTitle      :  curState.quickEditTodoTitle,
        quickEditTodoDueDate    :  curState.quickEditTodoDueDate,
        quickEditTodoPriority   :  curState.quickEditTodoPriority,
        quickEditTodoProjectId  : curState.quickEditTodoProjectId,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        quickEditTodoUpdated: () => {
            return dispatch({type: actionTypes.QUICK_EDIT_TODO_PROCESSED})
        },
    };
};


// Export Component
export default connect(mapStateToProps, mapDispatchToProps)(QuickEditTodo);