// Import React, Supporting Libraries
import React from 'react';
import { connect } from 'react-redux';


// Other Components
import CommonPageLayout from '../../../components/auth_only/CommonPageLayout/CommonPageLayout';
import ApiAxios from '../../../vendors/axios/api';
import * as utils from '../../../utils/utils';
import * as actionTypes from '../../../vendors/redux/state/actions';
import TodoFilters from '../../../components/auth_only/TodoFilters/TodoFilters';
import TodoList from '../../../components/auth_only/TodoList/TodoList';
import {NavLink} from "react-router-dom";


// Class Component
class GlobalSearchResultsList extends React.Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            globalSearchApiUrl: '/secure/project/todo/global-search',

            globalSearchString: '',

            bootstrapAlertContent: null,

            globalSearchTodoList: {},

            globalSearchTodoListFilters: {
                showCompletedTodos: true,
                filterDate: 'today', // upcoming,noDueDate
                filterPriority: 3, // 1,2,3,4,5
                filterViewAll: true,
            },

            globalSearchTodoListPagination: {
                resultsPerPage: 12,
                totalCount: 0,
                noOfPages: 1,
                currentPage: 1,
            },
        }

        // Regular Functions
        this.getTodoList                            =  this.getTodoList.bind(this);

        // Event Handlers
        this.showCompletedTodosChangeHandler        =  this.showCompletedTodosChangeHandler.bind(this);
        this.filterViewAllChangeHandler             =  this.filterViewAllChangeHandler.bind(this);
        this.filterPriorityClickHandler             =  this.filterPriorityClickHandler.bind(this);
        this.paginatorClickHandler                  =  this.paginatorClickHandler.bind(this);
        this.resultsPerPageChangeHandler            =  this.resultsPerPageChangeHandler.bind(this);
        this.isCompletedCheckboxChangeHandler       =  this.isCompletedCheckboxChangeHandler.bind(this);
        this.bootstrapAlertCloseButtonClickHandler  =  this.bootstrapAlertCloseButtonClickHandler.bind(this);
    }


    componentDidMount()
    {
        const authToken = utils.validateIsAuthenticated(this.props);
        if (authToken != false) {
            ApiAxios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
        } else {
            utils.redirectToLogin(this.props);
        }

        this.setState({
            globalSearchString: this.props.match.params.todoTitle,
        });

        this.getTodoList(this.props.match.params.todoTitle, null, null);
    }


    getTodoList(inSearchString = null, todoListFilters = null, todoListPagination = null)
    {
        if (!inSearchString) {
            inSearchString = this.state.globalSearchString;
        }

        if (!todoListFilters) {
            todoListFilters = this.state.globalSearchTodoListFilters;
        }

        if (!todoListPagination) {
            todoListPagination = this.state.globalSearchTodoListPagination;
        }


        let errorMessage = '';
        if (inSearchString.length < 3) {
            errorMessage = 'Please ensure search string is more than 2 chars in length';
        }
        if (inSearchString.length <= 0) {
            errorMessage = 'Cannot perform search on blank value';
        }
        if (inSearchString.length > 15) {
            errorMessage = 'Please ensure search string is less than 16 chars in length';
        }

        if (errorMessage != '') {
            this.setState({
                bootstrapAlertContent: errorMessage,
            });
            return false;
        }

        // Prepare Request Params
        let getParams = {
            titleSearch: inSearchString,
            pageNo: todoListPagination.currentPage,
            resultPerPage: todoListPagination.resultsPerPage,
            project: this.props.match.params.projectId,
        };
        if (!todoListFilters.filterViewAll) {
            getParams = {
                ...getParams,
                filterPriority: todoListFilters.filterPriority,
            };
        }
        if (todoListFilters.showCompletedTodos) {
            getParams = {
                ...getParams,
                filterIncludeCompleted: 1,
            };
        }

        ApiAxios.get(this.state.globalSearchApiUrl, {
            params: getParams,
        })
            .then((response) => {
                const globalSearchTodoList = response.data.data;
                this.setState({
                    globalSearchTodoList: globalSearchTodoList,
                    globalSearchTodoListPagination: {
                        ...this.state.globalSearchTodoListPagination,
                        totalCount: response.data.pagination.totalCount ? response.data.pagination.totalCount : 0,
                        noOfPages: response.data.pagination.noOfPages ? response.data.pagination.noOfPages : 1,
                    }
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        bootstrapAlertContent: error.response.data.message,
                    });
                } else {
                    this.setState({
                        bootstrapAlertContent: 'Your request could not be processed right now, please try again later',
                    });
                }
            })
            .then(() => {

            });
    }


    bootstrapAlertCloseButtonClickHandler(event)
    {
        this.setState({
            bootstrapAlertContent: null,
        });
    }


    showCompletedTodosChangeHandler(event)
    {
        const todoListFilters = this.state.globalSearchTodoListFilters;
        todoListFilters.showCompletedTodos = event.target.checked;

        this.setState({
            globalSearchTodoListFilters: todoListFilters,
        });

        this.getTodoList(null, todoListFilters, null);
    }


    filterViewAllChangeHandler(event)
    {
        const todoListFilters = this.state.globalSearchTodoListFilters;
        todoListFilters['filterViewAll'] = event.target.checked;

        this.setState({
            globalSearchTodoListFilters: {
                ...todoListFilters,
            },
        });

        this.getTodoList(null, todoListFilters, null);
    }


    filterPriorityClickHandler(inValue)
    {
        const todoListFilters = this.state.globalSearchTodoListFilters;
        todoListFilters['filterPriority'] = inValue;

        const todoListPagination = this.state.globalSearchTodoListPagination;
        todoListPagination.currentPage = 1;

        this.setState({
            globalSearchTodoListFilters: {
                ...todoListFilters,
            },
        });

        this.getTodoList(null, todoListFilters, todoListPagination);
    }


    paginatorClickHandler(isNextClick = false)
    {
        const todoListPagination = this.state.globalSearchTodoListPagination;

        if (isNextClick) {
            if (todoListPagination.currentPage === todoListPagination.noOfPages) {
                return false;
            }
            todoListPagination.currentPage++;
        } else {
            if (todoListPagination.currentPage === 1) {
                return false;
            }
            todoListPagination.currentPage--;
        }

        this.setState({
            globalSearchTodoListPagination: {
                ...todoListPagination,
            },
        });

        this.getTodoList(null, null, todoListPagination);
    }


    resultsPerPageChangeHandler(newValue)
    {
        const todoListPagination = this.state.globalSearchTodoListPagination;
        todoListPagination.resultsPerPage = newValue;
        todoListPagination.currentPage = 1;

        this.setState({
            globalSearchTodoListPagination: todoListPagination,
        });

        this.getTodoList(null, null, todoListPagination);
    }


    isCompletedCheckboxChangeHandler(todoId, event)
    {
        const postData = {
            todo: todoId,
            completed: event.target.checked ? 1 : 0,
            project: this.props.match.params.projectId,
        };

        this.setState({
            bootstrapAlertContent: null,
        });

        ApiAxios.post('/secure/project/todo/update/completed', postData)
            .then((response) => {
                this.getTodoList();
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    this.setState({
                        bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, error.response.data.message, true),
                    });
                } else {
                    this.setState({
                        bootstrapAlertContent: utils.showBootstrapAlert(this.bootstrapAlertCloseButtonClickHandler, 'Your request could not be processed right now, please try again later', true),
                    });
                }
            })
            .then(() => {

            });
    }


    generatePaginationForGlobalSearchTodoList() {

        const currentPageNo = this.state.globalSearchTodoListPagination.currentPage;
        const noOfPages = this.state.globalSearchTodoListPagination.noOfPages;

        let previousButtonClass = 'page-item';
        let previousButtonDisabled = false;
        if (this.state.globalSearchTodoListPagination.currentPage == 1) {
            previousButtonClass = previousButtonClass + ' disabled';
            previousButtonDisabled = true;
        }

        let nextButtonClass = 'page-item';
        let nextButtonDisabled = false;
        if (currentPageNo == noOfPages) {
            nextButtonClass = nextButtonClass + ' disabled';
            nextButtonDisabled = true;
        }

        const pageItemsArray = [];
        
        for (let i = 1; i <= noOfPages; i++) {
            let pageItemClassName = 'page-item';
            let pageItemDisabled = false;

            let pageItemElement = null;
            if (i == currentPageNo) {
                pageItemClassName = pageItemClassName + ' active';
                pageItemDisabled = true;
                // pageItemElement = <li className={pageItemClassName} aria-current="page"><span className="page-link">{i}<span class="sr-only">(current)</span></span></li>
                pageItemElement = <li className={pageItemClassName} aria-current="page"><span className="page-link">{i}<span class="sr-only">(current)</span></span></li>
            } else {
                // pageItemElement = <li className={pageItemClassName}><a className="page-link" href="#" aria-disabled={pageItemDisabled} onClick={this.handleGlobalSearchTodoListPageButtonClick.bind(this, i)}>{i}</a></li>
                pageItemElement = <li className={pageItemClassName}><button className="page-link" onClick={this.handleGlobalSearchTodoListPageButtonClick.bind(this, i)}>{i}</button></li>;
            }

            pageItemsArray.push(pageItemElement);
        }

        return (
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                    <li className={previousButtonClass}>
                        <button className="page-link" tabindex="-1" aria-disabled={previousButtonDisabled} onClick={this.handleGlobalSearchTodoListPageButtonClick.bind(this, (currentPageNo - 1))}>Previous</button>
                    </li>
                    
                    {pageItemsArray}

                    <li className={nextButtonClass}>
                        <button className="page-link" aria-disabled={nextButtonDisabled} onClick={this.handleGlobalSearchTodoListPageButtonClick.bind(this, (currentPageNo + 1))}>Next</button>
                    </li>
                </ul>
            </nav>
        );
    }


    render()
    {

        const breadcrumbLinks = (
            <React.Fragment>
                <li className="breadcrumb-item">Dashboard</li>
                <li className="breadcrumb-item active">Search Results</li>
            </React.Fragment>
        );

        return (
            <CommonPageLayout breadcrumbLinks={breadcrumbLinks} pageTitle="Search Results">
                <div className="row">
                    <div className="col-12">
                        <div className="email-leftbar card">
                            <div style={{marginBottom:'3rem'}}>
                                <h5>Project</h5>
                                <hr />
                                Default Project
                            </div>

                            <div style={{marginBottom:'3rem', color:'white'}}>
                                <h5>Quick Actions</h5>
                                <hr />
                                <a className="btn btn-primary btn-block waves-effect waves-light" data-toggle="modal" data-target="#editProjectModal">View / Manage Project Details</a>
                                <a className="btn btn-warning btn-block waves-effect waves-light" data-toggle="modal" data-target="#addNewTodoModal">Add New Todo</a>
                                <NavLink to={"/project/"+this.props.match.params.projectId+"/active-todos"} className="btn btn-danger btn-block waves-effect waves-light">View Active Todo List</NavLink>
                            </div>

                            <TodoFilters
                                showCompletedTodosCheckbox
                                showViewAllCheckbox
                                showPriorityFilter
                                filters={this.state.globalSearchTodoListFilters}
                                showCompletedTodosChangeHandler={this.showCompletedTodosChangeHandler}
                                filterViewAllChangeHandler={this.filterViewAllChangeHandler}
                                filterPriorityClickHandler={this.filterPriorityClickHandler} />
                        </div>


                        <div className="row" style={{paddingLeft:'1rem'}}>
                            <TodoList
                                listTitle="Search Results"
                                todoListPagination={this.state.globalSearchTodoListPagination}
                                todoList={this.state.globalSearchTodoList}
                                bootstrapAlertContent={this.state.bootstrapAlertContent}
                                paginatorClickHandler={this.paginatorClickHandler}
                                resultsPerPageChangeHandler={this.resultsPerPageChangeHandler}
                                isCompletedCheckboxChangeHandler={this.isCompletedCheckboxChangeHandler} />
                        </div>
                    </div>
                </div>
            </CommonPageLayout>
        );
    }
}


// Redux State Management
const mapStateToProps = (curState) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addInterPageMessage: (message, messageType) => {
            return dispatch({type: actionTypes.INTER_PAGE_MESSAGE_ADDED, message: message, messageType: messageType});
        },
    };
};


// Export Component
export default GlobalSearchResultsList;