// Import React, Supporting Libraries Libraries
import React from 'react';
import { NavLink } from 'react-router-dom';
import Axios from '../../../Axios/Axios';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as actionTypes from '../../../redux/state/actions';
import * as utils from '../../../utils/utils';


// Import Css Module
import classes from './GlobalSearchResultsList.module.css';


// Import Other Components
import MainNavHeader from '../../../components/Navigation/MainNavHeader/MainNavHeader';


// Class Component
class GlobalSearchResultsList extends React.Component
{

    constructor(props)
    {
        super(props);

        const today = new Date();
        const dateString = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

        this.state = {
            globalSearchApiUrl: '/secure/todo/global-search',

            globalSearchString: '',

            globalSearchTodoListFilters: {
                showCompletedTodos: false,
                filterDate: 'today', // upcoming,noDueDate
                filterPriority: 3, // 1,2,3,4,5
                filterViewAll: false,
            },

            globalSearchTodoList: {
            },

            globalSearchTodoListPagination: {
                resultsPerPage: 10,
                totalCount: 0,
                noOfPages: 1,
                currentPage: 1,
            },
        }
    }


    componentDidMount()
    {
        this.setState({
            globalSearchString: this.props.match.params.title,
        });

        this.getTodoList(this.props.match.params.title);
    }


    getTodoList(inSearchString = null)
    {
        if (!inSearchString) {
            inSearchString = this.state.globalSearchString;
        }
        let errorMessage = '';
        if (inSearchString.length < 3) {
            errorMessage = 'Please ensure search string is more than 2 chars in length';
        }
        if (inSearchString.length <= 0) {
            errorMessage = 'Cannot perform search on blank value';
        }
        if (inSearchString.length > 15) {
            errorMessage = 'Please ensure search string is less than 16 chars in length';
        }
        if (errorMessage != '') {
            toast(errorMessage, {
                autoClose: 8000,
                hideProgressBar: true,
                type: toast.TYPE.ERROR,
                position: toast.POSITION.BOTTOM_RIGHT,
                pauseOnHover: true,
                pauseOnFocusLoss: true,
            });

            return false;
        }

        let getParams = {
            titleSearch: inSearchString,
            pageNo: this.state.globalSearchTodoListPagination.currentPage,
            resultPerPage: this.state.globalSearchTodoListPagination.resultsPerPage,
        };

        Axios.get(this.state.globalSearchApiUrl, {
            params: getParams,
        })
            .then((response) => {
                const globalSearchTodoList = response.data.data;
                this.setState({
                    globalSearchTodoList: globalSearchTodoList,
                    globalSearchTodoListPagination: {
                        ...this.state.globalSearchTodoListPagination,
                        totalCount: response.data.pagination.totalCount ? response.data.pagination.totalCount : 0,
                        noOfPages: response.data.pagination.noOfPages ? response.data.pagination.noOfPages : 1,
                    }
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }

                    toast(error.response.data.message, {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });
                } else {
                    toast('Your request could not be processed right now, please try again later', {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });
                }
            })
            .then(() => {

            });
    }


    handleCompletedCheckboxChange(todoId, event) {
        const postData = {
            todo: todoId,
            completed: event.target.checked ? 1 : 0,
        };
        Axios.post('/secure/todo/update/completed', postData)
            .then((response) => {
                toast(response.data.message, {
                    autoClose: 8000,
                    hideProgressBar: true,
                    type: toast.TYPE.SUCCESS,
                    position: toast.POSITION.BOTTOM_RIGHT,
                    pauseOnHover: true,
                    pauseOnFocusLoss: true,
                });

                this.getTodoList(this.state.globalSearchString, this.state.todoListFilters);
            })
            .catch((error) => {
                if (error.response) {
                    if (utils.authTokenErrorMessages.includes(error.response.data.message)) {
                        utils.clearAuth(error.response.data.message, this.props, true);
                    }
                    toast(error.response.data.message, {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });
                    
                } else {
                    toast('Your request could not be processed right now, please try again later', {
                        autoClose: 8000,
                        hideProgressBar: true,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnHover: true,
                        pauseOnFocusLoss: true,
                    });
                }
            })
            .then(() => {
                
            });
    }


    handleGlobalSearchTodoListResultsPerPageChange = (event) => {
        const globalSearchTodoListPagination = this.state.globalSearchTodoListPagination;
        globalSearchTodoListPagination.resultsPerPage = event.target.value;
        globalSearchTodoListPagination.currentPage = 1;

        this.setState({
            globalSearchTodoListPagination: globalSearchTodoListPagination,
        });
        this.getTodoList(this.state.globalSearchString, this.state.globalSearchTodoListFilters, globalSearchTodoListPagination);
    }


    handleGlobalSearchTodoListPageButtonClick = (newPageNo, event) => {
        const globalSearchTodoListPagination = this.state.globalSearchTodoListPagination;
        globalSearchTodoListPagination.currentPage = newPageNo;
        
        this.setState({
            globalSearchTodoListPagination: globalSearchTodoListPagination,
        });
        this.getTodoList(this.state.globalSearchString, this.state.todoListFilters, globalSearchTodoListPagination);
    }


    generatePaginationForGlobalSearchTodoList() {

        const currentPageNo = this.state.globalSearchTodoListPagination.currentPage;
        const noOfPages = this.state.globalSearchTodoListPagination.noOfPages;

        let previousButtonClass = 'page-item';
        let previousButtonDisabled = false;
        if (this.state.globalSearchTodoListPagination.currentPage == 1) {
            previousButtonClass = previousButtonClass + ' disabled';
            previousButtonDisabled = true;
        }

        let nextButtonClass = 'page-item';
        let nextButtonDisabled = false;
        if (currentPageNo == noOfPages) {
            nextButtonClass = nextButtonClass + ' disabled';
            nextButtonDisabled = true;
        }

        const pageItemsArray = [];
        
        for (let i = 1; i <= noOfPages; i++) {
            let pageItemClassName = 'page-item';
            let pageItemDisabled = false;

            let pageItemElement = null;
            if (i == currentPageNo) {
                pageItemClassName = pageItemClassName + ' active';
                pageItemDisabled = true;
                // pageItemElement = <li className={pageItemClassName} aria-current="page"><span className="page-link">{i}<span class="sr-only">(current)</span></span></li>
                pageItemElement = <li className={pageItemClassName} aria-current="page"><span className="page-link">{i}<span class="sr-only">(current)</span></span></li>
            } else {
                // pageItemElement = <li className={pageItemClassName}><a className="page-link" href="#" aria-disabled={pageItemDisabled} onClick={this.handleGlobalSearchTodoListPageButtonClick.bind(this, i)}>{i}</a></li>
                pageItemElement = <li className={pageItemClassName}><button className="page-link" onClick={this.handleGlobalSearchTodoListPageButtonClick.bind(this, i)}>{i}</button></li>;
            }

            pageItemsArray.push(pageItemElement);
        }

        return (
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                    <li className={previousButtonClass}>
                        <button className="page-link" tabindex="-1" aria-disabled={previousButtonDisabled} onClick={this.handleGlobalSearchTodoListPageButtonClick.bind(this, (currentPageNo - 1))}>Previous</button>
                    </li>
                    
                    {pageItemsArray}

                    <li className={nextButtonClass}>
                        <button className="page-link" aria-disabled={nextButtonDisabled} onClick={this.handleGlobalSearchTodoListPageButtonClick.bind(this, (currentPageNo + 1))}>Next</button>
                    </li>
                </ul>
            </nav>
        );
    }


    render()
    {
        const today = new Date();
        const dateString = utils.formatDateToHumanReadableFormat(today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate());

        const todoList = [];
        for (let todoKey in this.state.globalSearchTodoList) {
            const ele = this.state.globalSearchTodoList[todoKey];
            let checked = false
            let label = <NavLink className="" to={'/secure/todos/'+ele.t__id+'/details'}>{utils.getTodoDisplpayTitle(ele.t__title)}</NavLink>
            let dueDateLabel = null; // t__due_date: "2020-05-31 23:59:59"
            let completedAtLabel = <React.Fragment><label style={{float: 'right'}}>-- ---- ----</label></React.Fragment>;
            if (ele.t__completed_at !== null) {
                checked = true;
                label = <NavLink className="" to={'/secure/todos/'+ele.t__id+'/details'}><s>{utils.getTodoDisplpayTitle(ele.t__title)}</s></NavLink>

                completedAtLabel = <React.Fragment><label style={{float: 'right'}}>{utils.returnJustDate(ele.t__completed_at)}</label></React.Fragment>; // t__due_date: "2020-05-31 23:59:59"
            }
            if (ele.t__due_date !== null) {
                dueDateLabel = <React.Fragment><label style={{float: 'right'}}>{utils.returnJustDate(ele.t__due_date)}</label></React.Fragment>
            }
            todoList.push(

                <div className="col mb-3" key={ele.t__id}>
                    <div className="card h-100">
                        <div className="card-body">
                            <div className="form-check">
                                <input 
                                    id={'chk'+ele.t__id}
                                    type="checkbox" 
                                    className="form-check-input" 
                                    checked={checked}
                                    onChange={this.handleCompletedCheckboxChange.bind(this, ele.t__id)} />
                                <label for={'chk'+ele.t__id}>Is Completed</label>
                            </div>
                            <div>
                                <label>Due On</label> {dueDateLabel}
                            </div>
                            <div>
                                <label>Completed On</label> {completedAtLabel}
                            </div>
                            <hr />
                            {label}
                        </div>
                    </div>
                </div>
                
            );
        }

        return (
            <React.Fragment>
                <MainNavHeader />


                <div className={'container-fluid ' + classes.MyContainer}>
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1">
                            <h3 style={{display: 'inline', marginRight: '1rem'}}>Search Results</h3>Today: {dateString}
                            <hr />
                            {
                                this.state.globalSearchTodoListPagination.totalCount > 0 ?
                                    <div className="" style={{marginTop: '0.5rem', marginBottom: '1rem'}}>
                                        <label for="inputState" className="" style={{display: 'inline', marginRight: '1rem'}}>Display Results Per Page</label>
                                        <select className="form-control" style={{display: 'inline', width:'5rem', marginRight: '1rem'}} value={this.state.globalSearchTodoListPagination.resultsPerPage} onChange={this.handleGlobalSearchTodoListResultsPerPageChange}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="200">200</option>
                                            </select>
                                            <label>Total Results: {this.state.globalSearchTodoListPagination.totalCount}</label>
                                    </div>
                                : 'No todos to display'
                            }

                            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4">
                                {todoList}
                            </div>

                            { 
                                this.state.globalSearchTodoListPagination.totalCount > 0 ?
                                    this.generatePaginationForGlobalSearchTodoList() 
                                : null
                            }
                        </div>
                    </div>
                </div>




            </React.Fragment>
        );
    }
}


// Export Component
export default GlobalSearchResultsList;