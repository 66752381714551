import Axios from '../Axios/Axios';


export const authTokenErrorMessages = [
    'Oops!!! Looks like your access token has expired, please try logging in again before accessing this route.',
    'Oops!!! Looks like you are using an invalid access token, please try logging in again before accessing this route.',
];


// Synchronous function
async function syncCall (token) {
    const getParams = {
        token: token,
    }

    const response = await Axios.get('/validate-auth-token', {
        params: getParams
    });
        // .then((response) => {
        //     console.log('got response from server');
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log('got response from server');
        //     console.log(error);
        //     if (error.response) {

        //     } else {

        //     }
        // })
        // .then(() => {
                
        // });
    return response;
}


export const validateIsAuthenticated = () => {
    const accessToken = localStorage.getItem('access_token');
    const expiresInSecs = localStorage.getItem('expires_in_secs');
    const tokenType = localStorage.getItem('token_type');
    if (accessToken && expiresInSecs && tokenType) {
        // Now verify if this is still a valid access token or not
        // const data = syncCall(accessToken);
        // return data;

        // const response = syncCall(accessToken);
        // console.log('got a response from server');
        // console.log(response);
        // console.log('access token validated');
        return accessToken;
    } else {
        localStorage.clear();
    }

    return false;
}


export const clearAuth = (errorMessage, props, performRedirect = true) => {
    const accessToken = localStorage.getItem('access_token');
    localStorage.clear();
    if (accessToken) {
        Axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
        Axios.post('/secure/my-profile/logout')
            .then((response) => {

            })
            .catch((error) => {

            })
            .then(() => {
                if (performRedirect) {
                    props.history.replace({
                        pathname: '/',
                        state: {
                            errorMessage: errorMessage,
                        },
                    });
                }
            });
    }
}


export const returnJustDate = (inDateTime) => {
    const dateTimeArray = inDateTime.split(" ");
    return formatDateToHumanReadableFormat(dateTimeArray[0]);
}


export const formatDateToHumanReadableFormat = (inDate) => {
    const dateArray = inDate.split("-");
    const yearValue = parseInt(dateArray[0]);
    const monthValue = parseInt(dateArray[1]);
    const dayValue = parseInt(dateArray[2]);

    const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    return dayValue+' '+monthNames[monthValue-1]+', '+yearValue;
}


export const getTodoDisplpayTitle = (inFullTitle, maxLength = 100) => {
    let modifiedTitle = inFullTitle;
    if (inFullTitle.length > maxLength) {
        modifiedTitle = inFullTitle.substring(0, maxLength)+' .....';
    }
    return modifiedTitle;
}


export const appendScriptToBodySync = (pathToScript) => {
    const script = document.createElement('script');
    script.src = pathToScript;
    script.async = false;
    document.body.appendChild(script);
}