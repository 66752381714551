// Import React, Supporting Libraries Libraries
import React from 'react'
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actionTypes from '../../../redux/state/actions';
import * as utils from '../../../utils/utils';
import { toast } from 'react-toastify';


// Import Other Components
import AddTodoContainer from '../../../containers/AddTodo/AddTodo';


// Functional Component
class MainNavHeader extends React.Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            globalSearchString: '',
            globalSearchErrorString: '',
        };
    }


    componentDidMount()
    {
        /*
            This code logic simply repopulates search value in the search field when on search-results page
        */
        const urlArray = window.location.href.split('/');
        const secondLastUrlComponent = urlArray[urlArray.length-2];
        if (secondLastUrlComponent === 'search') {
            this.setState({
                globalSearchString: decodeURI(urlArray[urlArray.length-1]),
            })
        }
    }


    handleGlobalSearchStringChange = (event) => {
        const globalSearchString = event.target.value;

        let errorMessage = '';
        if (globalSearchString.length < 3) {
            errorMessage = 'Please ensure search string is more than 2 chars in length';
        }
        if (globalSearchString.length <= 0) {
            errorMessage = 'Cannot perform search on blank value';
        }
        if (globalSearchString.length > 15) {
            errorMessage = 'Please ensure search string is less than 16 chars in length';
        }

        this.setState({
            globalSearchString: globalSearchString,
            globalSearchErrorString: errorMessage,
        });
    }


    handleGlobalSearchFormSubmit = (event) => {
        event.preventDefault();

        const globalSearchString = this.state.globalSearchString;

        let errorMessage = '';
        if (globalSearchString.length < 3) {
            errorMessage = 'Please ensure search string is more than 2 chars in length';
        }
        if (globalSearchString.length <= 0) {
            errorMessage = 'Cannot perform search on blank value';
        }
        if (globalSearchString.length > 15) {
            errorMessage = 'Please ensure search string is less than 16 chars in length';
        }

        this.setState({
            globalSearchString: globalSearchString,
            globalSearchErrorString: errorMessage,
        });

        if (errorMessage != '') {
            toast(errorMessage, {
                autoClose: 8000,
                hideProgressBar: true,
                type: toast.TYPE.ERROR,
                position: toast.POSITION.BOTTOM_RIGHT,
                pauseOnHover: true,
                pauseOnFocusLoss: true,
            });
    
            return false;
        }

        window.location.href = '/secure/todos/search/'+globalSearchString;
    }


    render()
    {
        const guestPages = [
            { lable:'Register Page', aLink:'Register', navLink:'/register' },
            // { lable:'Register | Verify Account Page', aLink:'Register | Verify Account', navLink:'/register/verify-account' },
            { lable:'Login Page', aLink:'Login', navLink:'/login' },
            { lable:'Forgot Password Page', aLink:'Forgot Password', navLink:'/forgot-password' },
            // { lable:'Forgot Password | Verify Token Page', aLink:'Forgot Password | Verify Token', navLink:'/forgot-password/verify-token' },
            // { lable:'Forgot Password | Reset Password Page', aLink:'Forgot Password | Reset Password', navLink:'/forgot-password/reset-password' },
        ];
        const guestLinks = [];
        for (const guestPage of guestPages)
        {
            // guestLinks.push(<a className="dropdown-item" href="javascript:void(0)" key={guestPage['lable']} onClick={props.handleNavBarPageMenuItemClickEvent} data-value={guestPage['lable']}>{guestPage['aLink']}</a>)
            guestLinks.push(<NavLink exact className="dropdown-item" key={guestPage['lable']} to={guestPage['navLink']}>{guestPage['aLink']}</NavLink>);
        }
    
        const securePages = [
            { lable:'Todos', aLink:'Todos', navLink:'/secure/todos' },
            { lable:'Past Completed Todos', aLink:'Past Completed Todos', navLink:'/secure/past-completed-todos' },
            { lable:'My Profile - Personal Details', aLink:'My Profile - Personal Details', navLink:'/secure/my-profile/personal-details' },
            { lable:'My Profile - Change Password', aLink:'My Profile - Change Password', navLink:'/secure/my-profile/change-password' },
            { lable:'Logout', aLink:'Logout', navLink:'/logout' },
        ];
        const secureLinks = [];
        for (const securePage of securePages)
        {
            secureLinks.push(<NavLink exact className="dropdown-item" key={securePage['lable']} to={securePage['navLink']}>{securePage['aLink']}</NavLink>);
        }
    
        let finalNavLinks = (
            <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Guest Pages
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    {guestLinks}
                </div>
            </li>
        );
    
        if (utils.validateIsAuthenticated() != false) {
            finalNavLinks = (
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Secure Pages
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        {secureLinks}
                    </div>
                </li>
            );
        }
    
        let returnValue = null;
        if (utils.validateIsAuthenticated() != false) {
            returnValue = (
                <React.Fragment>
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        {/* <a className="navbar-brand" href="javascript:void(0)" onClick={props.handleNavBarPageMenuItemClickEvent} data-value='Home Page'>EsyTodo</a> */}
                        <NavLink className="navbar-brand" to="/">EsyTodo</NavLink>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
    
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">                    
    
                                {/* <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Secure Pages
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <a className="dropdown-item" href="#">Action</a>
                                        <a className="dropdown-item" href="#">Another action</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="#">Something else here</a>
                                    </div>
                                </li> */}
                            </ul>
                            
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item" style={{marginRight: '1rem'}}>
                                    <div className="dropdown">
                                        <button className="btn btn-outline-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Todos
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <NavLink exact className="dropdown-item" to='/secure/todos'>Present Todos</NavLink>
                                            <NavLink exact className="dropdown-item" to='/secure/past-completed-todos'>Past Completed Todos</NavLink>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item my-2 my-lg-0" style={{marginRight: '1rem'}}>
                                    <button className="btn btn-danger" data-toggle="modal" data-target="#addNewTodoModal">Add Todo</button>
                                </li>
                                <li className="nav-item">
                                    <div className="dropdown">
                                        <button className="btn btn-outline-info dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            My Profile
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <NavLink exact className="dropdown-item" to='/secure/my-profile/personal-details'>Personal Details</NavLink>
                                            <NavLink exact className="dropdown-item" to='/secure/my-profile/change-password'>Change Password</NavLink>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <ul className="navbar-nav my-2 my-lg-0">
                                <li className="nav-item">
                                    <NavLink exact to="/logout" className="btn btn-warning" style={{marginRight: '1rem'}}>Logout</NavLink>
                                </li>
                            </ul>
                            <form className="form-inline my-2 my-lg-0" onSubmit={this.handleGlobalSearchFormSubmit}>
                                <input className="form-control mr-sm-2" 
                                    style={this.state.globalSearchErrorString != '' ? {color: 'red'} : {color: 'black'}} 
                                    type="search" placeholder="Global Todo Search" 
                                    required={true}
                                    aria-label="Search" 
                                    value={this.state.globalSearchString} 
                                    onChange={this.handleGlobalSearchStringChange}
                                    minLength="3" 
                                    maxLength="15" />
                                <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                            </form>
                        </div>
                    </nav>
                    <AddTodoContainer />
                </React.Fragment> 
            );
        }
    
        return returnValue;
    }
}


// Redux State Management
const mapStateToProps = (curState) => {
    return {
        token: curState.token,
        isAuthenticated: curState.isAuthenticated,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        getAuthToken: () => {
            return dispatch({type: actionTypes.AUTH_CHECK})
        },
    };
};


// Export Component
export default connect(mapStateToProps, mapDispatchToProps)(MainNavHeader);