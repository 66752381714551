// Import React, Supporting Libraries Libraries
import React from 'react';
import Axios from '../Axios/Axios';
import { connect } from 'react-redux';
import * as actionTypes from '../redux/state/actions';


// Class Component
class Logout extends React.Component
{
    componentDidMount()
    {
        this.props.getAuthToken();
        if (this.props.token.access_token) {
            Axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.props.token.access_token;
        } else {
            const accessToken = localStorage.getItem('access_token');
            Axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
        }
        Axios.post('/secure/my-profile/logout');

        this.props.clearAuthToken();
        this.props.history.push({
            pathname: '/',
            state: {
                successMessage: "You have successfully logged out",
            },
        });
    }


    render()
    {
        return '';
    }
}


// Redux State Management
const mapStateToProps = (curState) => {
    return {
        token: curState.token,
        isAuthenticated: curState.isAuthenticated,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        getAuthToken: () => {
            return dispatch({type: actionTypes.AUTH_CHECK})
        },
        clearAuthToken: () => {
            return dispatch({type: actionTypes.LOGOUT})
        }
    };
};


// Export Component
export default connect(mapStateToProps, mapDispatchToProps)(Logout);