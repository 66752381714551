import React from 'react';

const submitButton = (props) => {

    let submitButtonElement = <button type="submit" className="btn btn-primary btn-block waves-effect waves-light" disabled={props.formHasError}>{props.label}</button>

    if (props.isSubmitting) {
        submitButtonElement = (
            <button type="button" className="btn btn-primary btn-block waves-effect waves-light" disabled>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                &nbsp;Submitting...
            </button>
        );
    }

    return submitButtonElement;
}

export default submitButton;